import {
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  MenuItem,
  Select,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
} from '@mui/material'
import genericStyle from '../../common/utils/generic.module.css'
import styles from '../user-profile/Editor.module.css'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { emptyUserDTO, UserDTO, UserQuery } from '../../modules/users/models/User'
import { useTranslation } from 'react-i18next'
import { getUserContainer } from '../../container/user-module'
import {
  IUserService,
  LOGGED_USER_SERVICE_KEY,
  PROFESSIONALTYPE_SERVICE_KEY,
  USER_SERVICE_KEY,
} from '../../modules/users'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import { BoolQueryParam, Query, QueryParam } from '../../common/api/Query'
import { Related } from '../../modules/users/models/RelatedUser'
import { Roles } from '../../modules/users/enums/Roles'
import { ICircleService } from '../../modules/users/services/CircleService'
import { CIRCLE_SERVICE_KEY as CIRCLE_SERVICE_KEY_2 } from '../../modules/users/container'
import { ROUTE_CIRCLES, ROUTE_NEWS_WALL } from '../../routes/routes-constants'
import { useNavigate } from 'react-router-dom'
import { CitizenDataRes } from '../../modules/users/models/CitizenData'
import { formatBirthDate } from '../../common/utils/date'
import { CircleRegisterHome } from './CircleRegisterHome'
import patientIcon from '../../assets/superior_menu/ico-paciente-blue.svg'
import professionalIcon from '../../assets/superior_menu/ico-profesional-blue.svg'
import familyIcon from '../../assets/superior_menu/ico-familiar-blue.svg'
import { ProfessionalType } from '../../modules/users/models/ProfessionalType'
import { ProfessionalTypesService } from '../../modules/users/services/ProfessionalTypeService'
import { useStyles } from './StepStyle'
import { getAuditContainer } from '../../container/audit-module'
import { VISIT_AUDIT_SERVICE_KEY } from '../../modules/audit'
import { VisitAuditService } from '../../modules/audit/services/VisitAuditService'
import { emptyVisitAuditDTO } from '../../modules/audit/models/VisitAuditDTO'
import { useGlobalRenderHeaderContext } from '../../common/utils/GlobalRenderHeader'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'
import { getUserCircleContainer } from '../../container/user-circle-module'
import { IUserCircleService, USER_CIRCLE_SERVICE_KEY } from '../../modules/user-circle'
import { getCircleContainer } from '../../container/circle-module'
import { Circle, CircleQuery } from '../../modules/circle/models/Circle'
import { CIRCLE_SERVICE_KEY } from '../../modules/circle'
import { CircleService } from '../../modules/circle/services/CircleService'
import { UserCircleQuery } from '../../modules/user-circle/models/UserCircle'
import {
  emptyUserCircleDTO,
  fromModel,
  UserCircleDTO,
} from '../../modules/user-circle/models/UserCircleDTO'

const steps = ['Datos del circulo', 'Añade un familiar', 'Selecciona el círculo y la especialidad']

const userContainer = getUserContainer()
const userService = userContainer.get<IUserService>(USER_SERVICE_KEY)
const loggedUserService = userContainer.get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const circleActiveService = getUserContainer().get<ICircleService>(CIRCLE_SERVICE_KEY_2)
const professionalTypeService = userContainer.get<ProfessionalTypesService>(
  PROFESSIONALTYPE_SERVICE_KEY
)
const circleService = getCircleContainer().get<CircleService>(CIRCLE_SERVICE_KEY)

const userCircleService = getUserCircleContainer().get<IUserCircleService>(USER_CIRCLE_SERVICE_KEY)
const visitAuditService = getAuditContainer().get<VisitAuditService>(VISIT_AUDIT_SERVICE_KEY)

type PathologyInterface = {
  key: string
  value: string
}

export function Register() {
  const classes = useStyles()

  const loggedUser = loggedUserService.get()
  const { t } = useTranslation()
  const queryString = window.location.search
  const params = new URLSearchParams(queryString)
  const [activeStep, setActiveStep] = React.useState(0)
  const [skipped, setSkipped] = React.useState(new Set<number>())
  const [userToAdd, setUserToAdd] = useState<UserDTO>(emptyUserDTO())
  const [familiarToAdd, setFamiliarToAdd] = useState<UserDTO>(emptyUserDTO())
  const [pathologies, setPathologies] = useState<PathologyInterface[]>([])
  const [selectedPathology, setSelectedPathology] = useState<string>('0000')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [context, setContext] = useState<string>('')
  const [app, setApp] = useState<string>('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [center, setCenter] = useState<string>('')
  const [citizenData, setCitizenData] = useState<CitizenDataRes>()
  const [tutorCitizenData, setTutorCitizenData] = useState<CitizenDataRes>()
  const [nssTutor, setNssTutor] = useState<string>('')
  const [cipaError, setCipaError] = useState<boolean>(false)
  const [cipaErrorText, setCipaErrorText] = useState<string>('')
  const [addCircle, setAddCircle] = useState<boolean>(false)
  const [professionalSpecialities, setProfessionalSpecialities] = useState<ProfessionalType[]>([])
  const [professionalType, setProfessionalType] = useState<ProfessionalType>()
  const [currentCirclePathology, setCurrentCirclePathology] = useState<string>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedProfessionalType, setSelectedProfessionalType] = useState<ProfessionalType>()
  const [circle, setCircle] = useState<Circle[]>([])
  const [circleToAddPathologies, setCircleToAddPathologies] = useState<Circle[]>([])
  const [document, setDocument] = useState<string>()
  const visitAuditR = useRef(emptyVisitAuditDTO())
  const [isSkip, setIsSkip] = useState<boolean>(false)
  const [disableSkip, setDisableSkip] = useState<boolean>(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { render, setRender } = useGlobalRenderHeaderContext()
  const [familiarError, setFamiliarError] = useState<boolean>(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (params.get('cipa')) {
      userToAdd.cip = params.get('cipa') ?? ''
    }
    if (params.get('contexto')) {
      // TODO Set pathologies based on the context received
      setContext(params.get('contexto') ?? '')
    }
    if (params.get('centro')) {
      setCenter(params.get('centro') ?? '')
    }
    if (params.get('app')) {
      // TODO only authorized apps can trigger this call
      setApp(params.get('app') ?? '')
    }

    if (userToAdd.cip) {
      userService
        .getFilteredList(
          new Query({
            query: [
              {
                name: 'isCircle',
                value: new BoolQueryParam(true),
              },
              {
                name: 'cip',
                value: userToAdd.cip,
              },
            ],
          })
        )
        .subscribe((circle) => {
          if (circle.count === 1) {
            userCircleService
              .getByUserIDWithRelaters(circle.items[0].id)
              .subscribe((userCircles) => {
                if (!loggedUser?.id || !userCircles) return
                userCircles.forEach((userCircle) => {
                  const existRelater = [
                    ...userCircle.familiars,
                    ...userCircle.careers,
                    ...userCircle.profSms,
                    ...userCircle.profExt,
                  ].some((user) => user.id === loggedUser?.id)
                  if (existRelater) {
                    circleActiveService.setActive(circle.items[0])
                    navigate(ROUTE_NEWS_WALL)
                  }
                })
              })
          }
        })
    }

    circleService
      .getFilteredList(
        new Query({
          sort: [{ field: 'id' }],
        })
      )
      .subscribe((res) => {
        const pl = res.items.map((p) => ({ key: p.name, value: p.id || '' }))
        setPathologies(pl)
      })
  }, [])

  useEffect(() => {
    if (!app) return
    getCitizenData(userToAdd.cip, 'cim')
  }, [app])

  useEffect(() => {
    userService.getByUsername(userToAdd.cip).subscribe((u) => {
      if (!u?.id) return
      userCircleService.getUserCircleByUserIDWithCircle(u.id).subscribe((res) => {
        if (!res) return
        setCircleToAddPathologies(
          res.map((userCircleWithCircle) => {
            return userCircleWithCircle.circle
          })
        )
      })
    })
  }, [userToAdd])

  useEffect(() => {
    professionalTypeService
      .getAll(
        new Query({
          sort: [{ field: 'name' }],
          pager: { offset: 0, limit: -1 },
        })
      )
      .subscribe((res) => {
        // esfera_715 -- el profesionalSMS que tiene acceso a esta pantalla no puede añadir plan activa
        // en caso de que en el futuro este requerimiento cambie eliminar filter e igualar listAux = res
        const filterPlanActiva = res.filter(({ name }) => name !== 'Plan Activa')
        const listAux: ProfessionalType[] = filterPlanActiva
        setProfessionalSpecialities(listAux)
      })
  }, [])

  const isStepOptional = (step: number) => step === 1

  const isStepSkipped = (step: number) => skipped.has(step)

  const handleNext = () => {
    let newSkipped = skipped
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped(newSkipped)
  }

  useEffect(() => {
    activeStep === steps.length && saveData()
  }, [activeStep])

  const createUser = (
    circle: UserDTO,
    name: string,
    isCircle: boolean,
    roles: Roles[],
    citizenData?: CitizenDataRes
  ): UserDTO =>
    Object.assign(
      { ...circle },
      {
        username: circle.cip.toUpperCase(),
        isCircle,
        name,
        dni: citizenData?.Body.CitizenConsResponse.DataCitizen.Citizen.Nif,
        birthDate:
          formatBirthDate(
            citizenData?.Body.CitizenConsResponse.DataCitizen.Citizen.BirthDate ?? '01/01/1900'
          ) || null,
        gender: citizenData?.Body.CitizenConsResponse.DataCitizen.Citizen.Genre === 'M' ? 1 : 2,
        phone:
          citizenData?.Body.CitizenConsResponse.DataCitizen.Citizen.ContactData.SmsPhone ??
          citizenData?.Body.CitizenConsResponse.DataCitizen.Citizen.ContactData.Phone ??
          '',
        email: citizenData?.Body.CitizenConsResponse.DataCitizen.Citizen.ContactData.Email ?? '',
        roles,
        acceptChat: true,
        acceptVideoCalls: true,
      }
    )

  const saveData = () => {
    if (!loggedUser?.id || !citizenData) return

    const newUser = createUser(userToAdd, userToAdd.name, true, [Roles.Patient], citizenData)

    let newFamiliar = createUser(emptyUserDTO(), '', false, [])

    if (!isSkip) {
      newFamiliar = createUser(
        familiarToAdd,
        familiarToAdd.name,
        false,
        [Roles.FamilyOrTutor],
        tutorCitizenData ?? undefined
      )
    }

    const userList = newFamiliar.cip ? [newUser, newFamiliar] : [newUser]
    const cips = userList.map((u) => u.cip)

    userService
      .getFilteredList(
        new Query<UserQuery>({
          query: [new QueryParam<UserQuery>('cips', cips)],
          pager: { offset: 0, limit: -1 },
          sort: [{ field: 'name' }],
        })
      )
      .subscribe(async (res) => {
        const user = res.items.find((u) => u.username === newUser.cip)
        const familiar = res.items.find((u) => u.username === newFamiliar.cip)

        const userPromises = []

        if (!user) {
          // el usuario no existe lo creo.
          userPromises.push(userService.addDTO(newUser).toPromise())
          userToAdd.id = newUser.id
        } else {
          userToAdd.id = user.id
        }

        if (!familiar && newFamiliar.cip) {
          // el familiar no existe lo creo.
          userPromises.push(userService.addDTO(newFamiliar).toPromise())
          familiarToAdd.id = newFamiliar.id
        }
        if (familiar) {
          familiarToAdd.id = familiar.id
        }

        // esperamos que los usuario se creen en caso de ser necesario

        await Promise.all(userPromises)

        // compruebo si existe el user Circle en caso de no existir lo creo.
        const circleSelectedID = circle.find((c) => c.name === currentCirclePathology)?.id
        if (!circleSelectedID) {
          return
        }

        userCircleService
          .getFilteredList(
            new Query<UserCircleQuery>({
              query: [
                new QueryParam<UserCircleQuery>('userID', userToAdd.id),
                new QueryParam<UserCircleQuery>('circleID', circleSelectedID),
              ],
              pager: { offset: 0, limit: -1 },
            })
          )
          .subscribe((res) => {
            // en caso de no existir creamos el user circle
            if (res.items.length === 0) {
              const newUserCircle: UserCircleDTO = emptyUserCircleDTO()
              // eslint-disable-next-line no-sequences,@typescript-eslint/no-unused-expressions
              newUserCircle.circleID = circleSelectedID
              newUserCircle.userID = userToAdd.id
              if (newFamiliar.cip) {
                newUserCircle.familiarsID = [familiarToAdd.id]
              }
              newUserCircle.profSmsID = [loggedUser.id]
              userCircleService.add(newUserCircle).subscribe((res) => {})
            }
            // en caso de ya existir añdimos al familiar y al profesional
            else {
              const newUserCircle = res.items[0]
              if (!newUserCircle.familiarsID.includes(familiarToAdd.id)) {
                familiar?.cip && newUserCircle.familiarsID.push(familiarToAdd.id)
              }
              if (!newUserCircle.profSmsID.includes(loggedUser.id)) {
                newUserCircle.profSmsID.push(loggedUser.id)
              }

              userCircleService.update(fromModel(newUserCircle)).subscribe((res) => {})
            }
          })
      })
  }

  const getCitizenData = (id: string, type: string) => {
    userService
      .getCitizenData({
        id,
        typeData: type,
      })
      .subscribe((res) => {
        if (res === undefined) {
          setCipaError(true)
          setCipaErrorText(t('citizenSearchUnavailable'))
        } else {
          if (res?.Body.CitizenConsResponse.Header.ResponseCode !== 'S') {
            setCipaError(true)
            setCipaErrorText(t('citizenSearchUnvalid'))
          } else {
            let name = ''
            let lastname = ''

            if (res?.Body.CitizenConsResponse.DataCitizen.Citizen.Name) {
              name = res.Body.CitizenConsResponse.DataCitizen.Citizen.Name
              if (res?.Body.CitizenConsResponse.DataCitizen.Citizen.Surname1) {
                lastname = res.Body.CitizenConsResponse.DataCitizen.Citizen.Surname1
                if (res?.Body.CitizenConsResponse.DataCitizen.Citizen.SurName2) {
                  lastname =
                    lastname + ' ' + res.Body.CitizenConsResponse.DataCitizen.Citizen.SurName2
                }
              }
            }
            if (res?.Body.CitizenConsResponse.DataCitizen.Citizen.NssBeneficiary) {
              setNssTutor(res.Body.CitizenConsResponse.DataCitizen.Citizen.NssBeneficiary)
            }
            setCitizenData(res)
            setUserToAdd((prevState) => {
              return {
                ...prevState,
                cip: res.Body.CitizenConsResponse.DataCitizen.Citizen.Cim,
                username: res.Body.CitizenConsResponse.DataCitizen.Citizen.Cim,
                name,
                lastname,
                dni: res.Body.CitizenConsResponse.DataCitizen.Citizen.Nif,
                birthDate:
                  formatBirthDate(res.Body.CitizenConsResponse.DataCitizen.Citizen.BirthDate) ?? '',
              }
            })
            setCipaError(false)
          }
        }
      })
  }

  const getTutorCitizenData = (id: string, type: string) => {
    userService
      .getCitizenData({
        id: id.toUpperCase(),
        typeData: type,
      })
      .subscribe((res) => {
        if (!res) {
          setFamiliarError(true)
          setFamiliarToAdd(emptyUserDTO())
          return
        }
        if (res?.Body.CitizenConsResponse.DataCitizen.Citizen.Name === '') {
          setFamiliarError(true)
          setFamiliarToAdd(emptyUserDTO())
          return
        }
        setFamiliarError(false)
        let cip = ''
        let name = ''
        let lastname = ''
        if (res?.Body.CitizenConsResponse.DataCitizen.Citizen.Cim) {
          cip = res.Body.CitizenConsResponse.DataCitizen.Citizen.Cim
        }
        if (res?.Body.CitizenConsResponse.DataCitizen.Citizen.Name) {
          name = res.Body.CitizenConsResponse.DataCitizen.Citizen.Name
          if (res?.Body.CitizenConsResponse.DataCitizen.Citizen.Surname1) {
            lastname = res.Body.CitizenConsResponse.DataCitizen.Citizen.Surname1
            if (res?.Body.CitizenConsResponse.DataCitizen.Citizen.SurName2) {
              lastname = lastname + ' ' + res.Body.CitizenConsResponse.DataCitizen.Citizen.SurName2
            }
          }
        }
        setFamiliarToAdd((prevState) => {
          return {
            ...prevState,
            cip,
            name,
            username: cip,
            lastname,
            dni: res.Body.CitizenConsResponse.DataCitizen.Citizen.Nif,
            birthDate:
              formatBirthDate(res.Body.CitizenConsResponse.DataCitizen.Citizen.BirthDate) ?? '',
          }
        })
        setTutorCitizenData(res)
      })
  }

  useEffect(() => {
    if (
      !nssTutor ||
      nssTutor === citizenData?.Body.CitizenConsResponse.DataCitizen.Citizen.NssHolder
    )
      return
    getTutorCitizenData(nssTutor, 'nssTitular')
    setDisableSkip(true)
  }, [citizenData, isSkip])

  useEffect(() => {
    if (!professionalType) return
    circleService
      .getFilteredList(
        new Query<CircleQuery>({
          query: [new QueryParam<CircleQuery>('specialtyID', professionalType?.name)],
        })
      )
      .subscribe((res) => {
        if (!res) return
        setCircle(res.items)
      })
  }, [professionalType, currentCirclePathology])

  useEffect(() => {
    if (currentCirclePathology)
      circleService
        .getFilteredList(
          new Query<CircleQuery>({
            query: [
              {
                name: 'name',
                value: currentCirclePathology,
              },
            ],
          })
        )
        .subscribe((res) => {
          setSelectedPathology(res.items[0].id)
        })
  }, [currentCirclePathology])

  const handleBack = () => {
    setIsSkip(false)
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error('No puedes saltar un paso no opcional')
    }
    setIsSkip(true)
    // TODO empty userData
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped((prevSkipped) => new Set(prevSkipped.values()).add(activeStep))
  }

  const handleInputCircle = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setDocument(e.target.value)
  }

  const handleSearchWithCip = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    user: UserDTO,
    setState: (user: UserDTO) => void
  ) => {
    setState(Object.assign({ ...user }, { cip: e.target.value }))
  }

  const handleSelect = (e: string) => {
    setCurrentCirclePathology('')
    professionalTypeService.getByName(e).subscribe((res) => {
      if (res) {
        const aux: ProfessionalType = res
        setSelectedProfessionalType(aux)
        setProfessionalType(aux)
      }
    })
  }

  const handleSelectCircle = (e: string) => {
    setCurrentCirclePathology(e)
  }

  const handleAccessParticipant = () => {
    userService
      .getFilteredList(
        new Query<UserQuery>({
          query: [
            {
              name: 'cip',
              value: userToAdd.cip,
            },
          ],
        })
      )
      .subscribe((u) => {
        if (!u?.items[0].isCircle) {
          alert('El paciente no esta dado de alta')
          return
        }

        if (!loggedUser) return
        const relatedUser: Related = {
          id: loggedUser?.id,
          kind: 1,
        }

        userService.addRelated(relatedUser, u.id).subscribe((res) => {
          circleActiveService.setActive(u.items[0])
          navigate(ROUTE_NEWS_WALL)
        })
      })
  }

  const handleVisit = () => {
    userService
      .getFilteredList(
        new Query<UserQuery>({
          query: [
            {
              name: 'cip',
              value: userToAdd.cip,
            },
          ],
        })
      )
      .subscribe((u) => {
        if (!u?.items[0].isCircle) {
          alert('El paciente no esta dado de alta')
          return
        }

        setRender(true)
        circleActiveService.setActive(u.items[0])
        // circleService.getActiveObservable().subscribe((c) => setCircle(c))
        // setSelectedMenu(ROUTE_CIRCLES)
        navigate(ROUTE_NEWS_WALL)
        window.location.reload()
        visitAuditR.current = Object.assign(
          { ...visitAuditR.current },
          { circleID: u.id, professionalID: loggedUser?.id, date: new Date() }
        )
        visitAuditService.add(visitAuditR.current).subscribe((res) => {})
      })
  }

  const handleAddCircle = () => {
    setAddCircle(true)
  }

  const handleDelete = (type: string) => {
    if (type === 'professionalType') {
      if (professionalType && professionalType.name) {
        setProfessionalType(undefined)
        setCurrentCirclePathology(undefined)
      }
    }

    if (type === 'circle') {
      setCurrentCirclePathology(undefined)
    }
    console.info('You clicked the delete icon.')
  }

  return (
    <>
      {!addCircle ? (
        <CircleRegisterHome
          circleToAdd={userToAdd}
          handleInputCircle={handleInputCircle}
          setCircleToAdd={setUserToAdd}
          selectedPathology={selectedPathology}
          setSelectedPathology={setSelectedPathology}
          pathologies={pathologies}
          cipaError={cipaError}
          cipaErrorText={cipaErrorText}
          getCitizenData={getCitizenData}
          handleAddCircle={handleAddCircle}
          handleVisit={handleVisit}
          userToAdd={userToAdd}
          circleToAddPathologies={circleToAddPathologies}
          handleAccessParticipant={handleAccessParticipant}
          handleSearchWithCip={handleSearchWithCip}
        />
      ) : (
        <>
          <CurrentNavHeaderEsfera title={'Creación del círculo del paciente '} />
          <Box style={{ position: 'relative' }} className={genericStyle.pageContainer}>
            {/* <Box className={styles.headerContainer}>
                <Header label={t('circleRegister')} />
              </Box> */}
            <Stepper
              classes={{ root: classes.stepper }}
              connector={<span className={styles.connector} />}
              activeStep={activeStep}
            >
              {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {}
                const labelProps: {
                  optional?: React.ReactNode
                } = {}
                if (isStepSkipped(index)) {
                  stepProps.completed = false
                }
                return (
                  <Step
                    style={{ padding: 0, width: window.innerWidth > 1551 ? 200 : 0 }}
                    key={label}
                    {...stepProps}
                  >
                    <StepLabel
                      classes={{
                        alternativeLabel: classes.alternativeLabel,
                        labelContainer: classes.labelContainer,
                        iconContainer: classes.icon,
                      }}
                      StepIconProps={{
                        classes: {
                          root: classes.step,
                          completed: classes.completed,
                          active: classes.active,
                        },
                      }}
                      className={styles.stepLabel}
                      {...labelProps}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                )
              })}
            </Stepper>
            {activeStep === steps.length ? (
              <Box mb={3} className={styles.textFieldBox} display={'flex'}>
                <p className={styles.label}>{t('registerCompleted')}</p>
              </Box>
            ) : (
              <>
                {activeStep === 0 ? (
                  <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Card style={{ margin: '30px', padding: '15px' }}>
                      <CardContent style={{ width: '100%' }}>
                        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <p
                            style={{
                              fontFamily: 'Poppins, sans-serif',
                              fontWeight: 'bold',
                              fontSize: '1rem',
                              marginLeft: 0,
                            }}
                          >
                            Paciente
                          </p>
                          <img src={patientIcon} />
                        </Box>
                        <Divider
                          light
                          style={{ backgroundColor: '#68b3e0', marginTop: 8, height: 4 }}
                        />
                        <Box
                          style={{
                            display: 'flex',
                            marginTop: '20px',
                            justifyContent: 'space-between',
                            width: '25vw',
                          }}
                        >
                          <p className={styles.label}>{t('CIP')}</p>
                          <TextField
                            className={styles.textFieldSteps}
                            id={'cip'}
                            key={'cip'}
                            name="cip"
                            value={userToAdd.cip}
                            type="text"
                            required={true}
                            variant={'outlined'}
                            size={'small'}
                            disabled={true}
                          />
                        </Box>
                        <Box
                          style={{
                            display: 'flex',
                            marginTop: '20px',
                            justifyContent: 'space-between',
                            width: '25vw',
                          }}
                        >
                          <p className={styles.label}>{t('name')}</p>
                          <TextField
                            className={styles.textFieldSteps}
                            id={'name'}
                            key={'name'}
                            name="name"
                            value={userToAdd.name}
                            type="text"
                            required={true}
                            variant={'outlined'}
                            size={'small'}
                            disabled
                          />
                        </Box>
                      </CardContent>
                    </Card>
                    <Card style={{ margin: '40px', padding: '15px' }}>
                      <CardContent style={{ width: '100%' }}>
                        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <p
                            style={{
                              fontFamily: 'Poppins, sans-serif',
                              fontWeight: 'bold',
                              fontSize: '1rem',
                              marginLeft: 0,
                            }}
                          >
                            Profesional SMS
                          </p>
                          <img src={professionalIcon} />
                        </Box>
                        <Divider
                          light
                          style={{ backgroundColor: '#68b3e0', marginTop: 8, height: 4 }}
                        />
                        <Box
                          style={{
                            display: 'flex',
                            marginTop: '20px',
                            justifyContent: 'space-between',
                            width: '25vw',
                          }}
                        >
                          <p className={styles.label}>{t('DNI')}</p>
                          <TextField
                            className={styles.textFieldSteps}
                            id={'dni'}
                            key={'dni'}
                            name="dni"
                            value={loggedUser?.dni}
                            type="text"
                            required={true}
                            variant={'outlined'}
                            size={'small'}
                            disabled={true}
                          />
                        </Box>
                        <Box
                          style={{
                            display: 'flex',
                            marginTop: '20px',
                            justifyContent: 'space-between',
                            width: '25vw',
                          }}
                        >
                          <p className={styles.label}>{t('name')}</p>
                          <TextField
                            className={styles.textFieldSteps}
                            id={'name'}
                            key={'name'}
                            name="name"
                            value={loggedUser?.name}
                            type="text"
                            required={true}
                            variant={'outlined'}
                            size={'small'}
                            disabled={true}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Box>
                ) : null}
                {activeStep === 1 ? (
                  <Box
                    bgcolor="white"
                    m="1rem"
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <Box className={styles.textFieldCIPSearch}>
                      <p className={styles.label}>{t('Búsqueda por documento')}</p>
                      <Box className={styles.cipContainer}>
                        <TextField
                          className={styles.cipField}
                          value={document}
                          type="text"
                          onChange={(e) => {
                            handleInputCircle(e)
                          }}
                          required={true}
                          variant={'outlined'}
                          size={'small'}
                        />
                        <Box className={styles.searchButton}>
                          <AppButton
                            theme={ButtonTheme.NewPrimary}
                            handler={() => {
                              familiarToAdd.cip = ''
                              familiarToAdd.dni = ''
                              if (!document) return
                              if (document.substring(0, 4) === 'CARM') {
                                getTutorCitizenData(document, 'cim')
                                familiarToAdd.cip = document
                              } else {
                                getTutorCitizenData(document, 'nif')
                                familiarToAdd.dni = document
                              }
                            }}
                            type={'button'}
                            label={t('search')}
                          />
                        </Box>
                      </Box>
                      <p>Se puede buscar por CIP o DNI</p>
                    </Box>
                    {familiarError ? (
                      <p style={{ color: 'red' }}>{t('Documento no válido.')}</p>
                    ) : null}
                    <Box
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                      <Card
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          margin: '40px',
                          padding: '15px',
                        }}
                      >
                        <CardContent style={{ width: '100%' }}>
                          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <p
                              style={{
                                fontFamily: 'Poppins, sans-serif',
                                fontWeight: 'bold',
                                fontSize: '1rem',
                                marginLeft: 0,
                              }}
                            >
                              Familiar/Tutor
                            </p>
                            <img src={familyIcon} />
                          </Box>
                          <Divider
                            light
                            style={{ backgroundColor: '#68b3e0', marginTop: 8, height: 4 }}
                          />
                          <Box
                            style={{
                              display: 'flex',
                              marginTop: '20px',
                              justifyContent: 'space-between',
                              width: '30vw',
                            }}
                          >
                            <p className={styles.labelCard}>{t('Documento')}</p>
                            <TextField
                              className={styles.textFieldSteps}
                              id={'dni'}
                              fullWidth={true}
                              key={'dni'}
                              name="dni"
                              value={familiarToAdd.dni ? familiarToAdd.dni : familiarToAdd.cip}
                              type="text"
                              required={true}
                              variant={'outlined'}
                              size={'small'}
                              disabled={true}
                            />
                          </Box>
                          <Box
                            style={{
                              display: 'flex',
                              marginTop: '20px',
                              justifyContent: 'space-between',
                              width: '30vw',
                            }}
                          >
                            <p className={styles.labelCard}>{t('name')}</p>
                            <TextField
                              className={styles.textFieldSteps}
                              id={'name'}
                              fullWidth={true}
                              key={'name'}
                              name="name"
                              value={familiarToAdd.name + ' ' + familiarToAdd.lastname}
                              type="text"
                              required={true}
                              variant={'outlined'}
                              size={'small'}
                              disabled={true}
                            />
                          </Box>
                        </CardContent>
                      </Card>
                    </Box>
                  </Box>
                ) : null}
                {activeStep === 2 ? (
                  <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {circleToAddPathologies.length > 0 ? (
                      <>
                        <Card
                          style={{
                            margin: '40px',
                            padding: '15px',
                            width: '500px',
                            height: '300px',
                          }}
                        >
                          <CardContent style={{ width: '100%' }}>
                            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <p
                                style={{
                                  fontFamily: 'Poppins, sans-serif',
                                  fontWeight: 'bold',
                                  fontSize: window.innerWidth > 1551 ? '1.2rem' : '0.9rem',
                                  marginLeft: 0,
                                }}
                              >
                                Círculos a los que pertenece
                              </p>
                            </Box>
                            <Divider
                              light
                              style={{
                                backgroundColor: '#68b3e0',
                                marginTop: 8,
                                height: 4,
                              }}
                            />
                            <Box style={{ display: 'flex', marginTop: '20px', flexWrap: 'wrap' }}>
                              {circleToAddPathologies.map((circle) => (
                                // eslint-disable-next-line react/jsx-key
                                <Chip
                                  style={{ backgroundColor: '#eae9f0', margin: '0 0 10px 8px' }}
                                  label={circle.name}
                                />
                              ))}
                            </Box>
                          </CardContent>
                        </Card>
                      </>
                    ) : (
                      ''
                    )}
                    <Card
                      style={{
                        margin: '40px',
                        padding: '15px',
                        width: '800px',
                        height: '300px',
                      }}
                    >
                      <CardContent
                        style={{
                          width: '100%',
                          display: 'flex',
                          gap: '1em',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Box
                          style={{
                            width: '100%',
                          }}
                        >
                          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <p
                              style={{
                                fontFamily: 'Poppins, sans-serif',
                                fontWeight: 'bold',
                                fontSize: window.innerWidth > 1551 ? '1.2rem' : '0.9rem',
                                marginLeft: 0,
                              }}
                            >
                              Añadir especialidad
                            </p>
                          </Box>
                          <Divider
                            light
                            style={{ backgroundColor: '#68b3e0', marginTop: 8, height: 4 }}
                          />
                          <Box style={{ display: 'flex', marginTop: '30px' }}>
                            <Select
                              fullWidth
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={(professionalType && professionalType.name) ?? ''}
                              onChange={(e) => handleSelect(e.target.value)}
                            >
                              {Object.values(professionalSpecialities).map((k) => (
                                <MenuItem key={k.id} value={k.name}>
                                  {k.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </Box>
                          <Box
                            style={{
                              padding: '15px 0px',
                            }}
                          >
                            {professionalType && professionalType.name && (
                              <Chip
                                style={{ backgroundColor: '#eae9f0', marginRight: 8 }}
                                label={professionalType.name}
                                onDelete={() => handleDelete('professionalType')}
                              />
                            )}
                            {currentCirclePathology && (
                              <Chip
                                style={{ backgroundColor: '#eae9f0', marginRight: 8 }}
                                label={currentCirclePathology}
                                onDelete={() => handleDelete('circle')}
                              />
                            )}
                          </Box>
                        </Box>

                        <Box style={{ width: '100%' }}>
                          {professionalType && circle.length > 0 ? (
                            <Box style={{ width: '100%' }}>
                              <Box
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <p
                                  style={{
                                    fontFamily: 'Poppins, sans-serif',
                                    fontWeight: 'bold',
                                    fontSize: window.innerWidth > 1551 ? '1.2rem' : '0.9rem',
                                    marginLeft: 0,
                                  }}
                                >
                                  Añadir círculo
                                </p>
                              </Box>
                              <Divider
                                light
                                style={{
                                  backgroundColor: '#68b3e0',
                                  marginTop: 8,
                                  height: 4,
                                }}
                              />
                              <Box style={{ display: 'flex', marginTop: '30px' }}>
                                <Select
                                  fullWidth
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={currentCirclePathology ?? ''}
                                  onChange={(e) => handleSelectCircle(e.target.value)}
                                >
                                  {Object.values(circle).map((k) => (
                                    <MenuItem key={k.id} value={k.name}>
                                      {k.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </Box>
                            </Box>
                          ) : (
                            ''
                          )}
                        </Box>
                      </CardContent>
                    </Card>
                  </Box>
                ) : null}
                <Box mb={3} display={'flex'} justifyContent={'space-between'}>
                  {activeStep === 0 ? (
                    <Box className={styles.buttonContainer} display="flex" />
                  ) : (
                    <Box className={styles.buttonContainer} display="flex">
                      <AppButton
                        theme={ButtonTheme.NewSecondary}
                        type={'button'}
                        label={t('back')}
                        handler={handleBack}
                      />
                    </Box>
                  )}
                  <Box className={styles.buttonContainer} display="flex">
                    {isStepOptional(activeStep) &&
                      (disableSkip ? (
                        <Tooltip
                          arrow
                          placement="bottom-end"
                          title={'Este paso es obligatorio para los pacientes menores de edad'}
                        >
                          <Box>
                            <AppButton
                              disabled={disableSkip}
                              theme={ButtonTheme.NewSecondary}
                              type={'button'}
                              label={t('skip')}
                              handler={handleSkip}
                            />
                          </Box>
                        </Tooltip>
                      ) : (
                        <AppButton
                          disabled={disableSkip}
                          theme={ButtonTheme.NewSecondary}
                          type={'button'}
                          label={t('skip')}
                          handler={handleSkip}
                        />
                      ))}
                    {activeStep === 0 ? (
                      <AppButton
                        theme={ButtonTheme.NewSecondary}
                        type={'button'}
                        label={'Cancelar'}
                        handler={() => navigate(ROUTE_CIRCLES)}
                      />
                    ) : null}
                    <AppButton
                      theme={ButtonTheme.NewPrimary}
                      type={'button'}
                      label={
                        activeStep === 0
                          ? t('Siguiente')
                          : activeStep === steps.length - 1
                            ? t('Añadir y finalizar')
                            : t('Añadir y continuar')
                      }
                      handler={handleNext}
                      disabled={
                        (activeStep === 2 && !currentCirclePathology) ||
                        (activeStep === 1 && familiarError)
                      }
                    />
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </>
      )}
    </>
  )
}
