import { Box, FormControlLabel, FormLabel, Radio } from '@mui/material'
import styles from './EpilepticCrisis.module.css'
import { UserEpilepticalConfig } from '../../../../modules/epileptical/models/UserEpilepticalConfig'
import { ItemList } from '../../../../common/models/ItemList'
import { useTranslation } from 'react-i18next'

export type CrisisTypeProps = {
  epilepticConfig: ItemList<UserEpilepticalConfig>
}

export function CrisisType(props: CrisisTypeProps) {
  const { t } = useTranslation()
  return (
    <>
      <Box style={{ display: 'flex', flexDirection: 'column', margin: '8px' }}>
        <FormLabel style={{ fontFamily: 'Poppins' }} component="legend">
          {t('low')}
        </FormLabel>
        {props.epilepticConfig &&
          props.epilepticConfig.items.map(
            (item, index) =>
              item.crisisType === 1 && (
                <>
                  <FormControlLabel
                    key={index}
                    value={item.color}
                    control={<Radio />}
                    label={
                      <Box
                        aria-required="true"
                        style={{ backgroundColor: `${item.color}` }}
                        className={styles.boxLevel}
                      />
                    }
                  />
                </>
              )
          )}
      </Box>
      <Box style={{ display: 'flex', flexDirection: 'column', margin: '8px' }}>
        <FormLabel style={{ fontFamily: 'Poppins' }} component="legend">
          {t('medium')}
        </FormLabel>
        {props.epilepticConfig &&
          props.epilepticConfig.items.map(
            (item, index) =>
              item.crisisType === 2 && (
                <>
                  <FormControlLabel
                    value={item.color}
                    control={<Radio />}
                    label={
                      <Box
                        aria-required="true"
                        style={{ backgroundColor: `${item.color}` }}
                        className={styles.boxLevel}
                      />
                    }
                  />
                </>
              )
          )}
      </Box>
      <Box style={{ display: 'flex', flexDirection: 'column', margin: '8px' }}>
        <FormLabel style={{ fontFamily: 'Poppins' }} component="legend">
          {t('strong')}
        </FormLabel>
        {props.epilepticConfig &&
          props.epilepticConfig.items.map(
            (item, index) =>
              item.crisisType === 3 && (
                <>
                  <FormControlLabel
                    key={index}
                    value={item.color}
                    control={<Radio />}
                    label={
                      <Box
                        aria-required="true"
                        style={{ backgroundColor: `${item.color}` }}
                        className={styles.boxLevel}
                      />
                    }
                  />
                </>
              )
          )}
      </Box>
    </>
  )
}
