import { Container } from '../../common/container/Container'

export type FileContainerConfig = {
  moduleFullUrl: string
}

export type FilesProps = {
  parentContainer: Container
  config: FileContainerConfig
}

export const FILES_MODULE = Symbol('FILES_MODULE')

export const FILE_API_KEY = Symbol('FILE_API_KEY')

export const FILE_SERVICE_KEY = Symbol('FILE_SERVICE_KEY')
