import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styles from './MonthView.module.css'
import React from 'react'
import { addCero } from '../../common/utils/strings'
import {
  AllCalendarEventDTO,
  CrisisDetailsForCalendarDTO,
} from '../../modules/calendar/models/AllCalendarEventDTO'
import { EventCategory, eventCategoryTypes } from '../../modules/calendar/enums/EventCategory'
import { Zone, zoneTypes } from '../../modules/epileptical/enums/Zone'
import { Duration, durationTypes } from '../../modules/epileptical/enums/Duration'
import { Awareness, awarenessTypes } from '../../modules/epileptical/enums/Awareness'
import { Movement, movementTypes } from '../../modules/epileptical/enums/Movement'
import { Vision, visionTypes } from '../../modules/epileptical/enums/Vision'
import { SkinColor, skinColorTypes } from '../../modules/epileptical/enums/SkinColor'
import { Symptom, symptomTypes } from '../../modules/epileptical/enums/Symptom'
import { Mouth, mouthTypes } from '../../modules/epileptical/enums/Mouth'
import { AfterDuration, afterDurationTypes } from '../../modules/epileptical/enums/AfterDuration'
import style from '../patient-calendar-config/Modal.module.css'
import { Mood, moodTypes } from 'modules/epileptical/enums/Mood'

type CalendarTooltipProps = {
  events?: AllCalendarEventDTO[]
}

export const CalendarTooltip = (props: CalendarTooltipProps) => {
  const { t } = useTranslation()

  const getEpilepticalMoodStyle = (crisisDetailsEpilepticaMood: Mood) => {
    const styles = {
      [Mood.never]: style.dayLabelg,
      [Mood.good]: style.dayLabelg,
      [Mood.regular]: style.dayLabely,
      [Mood.bad]: style.dayLabelr,
    }

    return {
      style: styles[crisisDetailsEpilepticaMood] || moodTypes()[Mood.never],
      text: moodTypes()[crisisDetailsEpilepticaMood] || moodTypes()[Mood.never],
    }
  }

  const isDuringCrisisDetailsExists = (details: CrisisDetailsForCalendarDTO) => {
    return (
      details.zone !== 0 ||
      details.duration !== 0 ||
      details.awareness !== 0 ||
      details.movement !== 0 ||
      details.vision !== 0 ||
      details.skinColor !== 0 ||
      details.symptom !== 0 ||
      details.mouth !== 0
    )
  }

  const hasAfterCrisisDetailsExists = (details: CrisisDetailsForCalendarDTO) => {
    return details.afterDuration || details.afterSymptom
  }

  const drawEvent = () => {
    return (
      <>
        {props.events?.map((ev) => (
          <Box key={ev.id} className={styles.generalTooltip}>
            <Box className={styles.tooltipTitleRow}>
              <Box className={styles.tooltipTitle}>
                {ev.title === 'train' ? t(ev.title) : ev.title}
              </Box>
            </Box>
            <hr className={styles.horizontalLine} />
            <Box>
              <Box className={styles.toolTipRow}>
                <Box className={styles.toolTipBold}>{t('type')} :</Box>
                <Box className={styles.toolTipText}>
                  {ev.title === 'train' ? t(ev.title) : eventCategoryTypes()[ev.eventCategory]}
                </Box>
              </Box>
              <Box className={styles.toolTipRow}>
                <Box className={styles.toolTipBold}>{t('timeTable')} :</Box>
                <Box className={styles.toolTipText}>
                  {t('timeTableFrom')} {addCero(new Date(ev.startDate).getHours())}:
                  {addCero(new Date(ev.startDate).getMinutes())}h {t('timeTableTo')}{' '}
                  {addCero(new Date(ev.finishDate).getHours())}:
                  {addCero(new Date(ev.finishDate).getMinutes())}h
                </Box>
              </Box>

              {ev.eventCategory === 10 && (
                <Box>
                  <Box className={styles.toolTipRow}>
                    <Box className={styles.toolTipBold}>{t('howYourDay')}</Box>
                    <Box className={styles.toolTipText}>
                      <label
                        className={
                          getEpilepticalMoodStyle(ev.crisisDetails.epilepticalMood as Mood).style
                        }
                      >
                        {getEpilepticalMoodStyle(ev.crisisDetails.epilepticalMood as Mood).text}
                      </label>
                    </Box>
                  </Box>
                  <Box className={styles.toolTipRow}>
                    <Box className={styles.toolTipBold}>{t('medication')}: </Box>
                    <Box className={styles.toolTipText}>{ev.crisisDetails.medication}</Box>
                  </Box>

                  {ev.crisisDetails.observations && (
                    <Box className={styles.toolTipRow}>
                      <Box className={styles.toolTipBold}>{t('observations')}: </Box>
                      <Box className={styles.toolTipText}>{ev.crisisDetails.observations}</Box>
                    </Box>
                  )}
                  {ev.crisisDetails.color && (
                    <Box
                      className={styles.toolTipRow}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Box className={styles.toolTipBold}>{t('medium')}: </Box>
                      <Box
                        style={{
                          width: '60px',
                          height: '20px',
                          backgroundColor: ev.crisisDetails.color,
                        }}
                      ></Box>
                    </Box>
                  )}
                  <Box>
                    <Box className={styles.toolTipRow}>
                      <Box className={styles.toolTipBold}>{t('beforeTheCrisis')}</Box>
                    </Box>
                    <Box>
                      <Box className={styles.toolTipText}>
                        {ev.crisisDetails.comment ? ev.crisisDetails.comment : t('noComments')}
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    {isDuringCrisisDetailsExists(ev.crisisDetails) && (
                      <Box className={styles.toolTipRow}>
                        <Box className={styles.toolTipBold}>{t('duringTheCrisis')}</Box>
                      </Box>
                    )}
                    {ev.crisisDetails.zone !== 0 && (
                      <Box className={styles.toolTipRow}>
                        <Box className={styles.toolTipText}>{t('zone')}: </Box>
                        <Box className={styles.toolTipText}>
                          {zoneTypes()[ev.crisisDetails.zone as Zone]}
                        </Box>
                      </Box>
                    )}
                    {ev.crisisDetails.duration !== 0 && (
                      <Box className={styles.toolTipRow}>
                        <Box className={styles.toolTipText}>{t('eventDuration')}: </Box>
                        <Box className={styles.toolTipText}>
                          {durationTypes()[ev.crisisDetails.duration as Duration]}
                        </Box>
                      </Box>
                    )}
                    {ev.crisisDetails.awareness !== 0 && (
                      <Box className={styles.toolTipRow}>
                        <Box className={styles.toolTipText}>{t('awareness')}: </Box>
                        <Box className={styles.toolTipText}>
                          {awarenessTypes()[ev.crisisDetails.awareness as Awareness]}
                        </Box>
                      </Box>
                    )}
                    {ev.crisisDetails.movement !== 0 && (
                      <Box className={styles.toolTipRow}>
                        <Box className={styles.toolTipText}>{t('movement')}: </Box>
                        <Box className={styles.toolTipText}>
                          {movementTypes()[ev.crisisDetails.movement as Movement]}
                        </Box>
                      </Box>
                    )}
                    {ev.crisisDetails.vision !== 0 && (
                      <Box className={styles.toolTipRow}>
                        <Box className={styles.toolTipText}>{t('vision')}: </Box>
                        <Box className={styles.toolTipText}>
                          {visionTypes()[ev.crisisDetails.vision as Vision]}
                        </Box>
                      </Box>
                    )}
                    {ev.crisisDetails.skinColor !== 0 && (
                      <Box className={styles.toolTipRow}>
                        <Box className={styles.toolTipText}>{t('skinColor')}: </Box>
                        <Box className={styles.toolTipText}>
                          {skinColorTypes()[ev.crisisDetails.skinColor as SkinColor]}
                        </Box>
                      </Box>
                    )}
                    {ev.crisisDetails.symptom !== 0 && (
                      <Box className={styles.toolTipRow}>
                        <Box className={styles.toolTipText}>{t('symptom')}: </Box>
                        <Box className={styles.toolTipText}>
                          {symptomTypes()[ev.crisisDetails.symptom as Symptom]}
                        </Box>
                      </Box>
                    )}
                    {ev.crisisDetails.mouth !== 0 && (
                      <Box className={styles.toolTipRow}>
                        <Box className={styles.toolTipText}>{t('mouth')}: </Box>
                        <Box className={styles.toolTipText}>
                          {mouthTypes()[ev.crisisDetails.mouth as Mouth]}
                        </Box>
                      </Box>
                    )}
                    {hasAfterCrisisDetailsExists(ev.crisisDetails) && (
                      <Box className={styles.toolTipRow}>
                        <Box className={styles.toolTipBold}>{t('afterTheCrisis')}: </Box>
                      </Box>
                    )}
                    {ev.crisisDetails.afterDuration !== 0 && (
                      <Box className={styles.toolTipRow}>
                        <Box className={styles.toolTipText}>{t('eventDuration')}: </Box>
                        <Box className={styles.toolTipText}>
                          {afterDurationTypes()[ev.crisisDetails.afterDuration as AfterDuration]}
                        </Box>
                      </Box>
                    )}
                    <Box className={styles.toolTipRow}>
                      <Box className={styles.toolTipText}>
                        {ev.crisisDetails.afterSymptom?.join(', ')}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
              {ev.idIcon === 'Poo' && (
                <Box className={styles.toolTipRow}>
                  <Box className={styles.toolTipBold}>{t('numberDeposition') + ' :'}</Box>
                  <Box className={styles.toolTipText}>{ev.value}</Box>
                </Box>
              )}
              {/(Pain)$/.test(ev.idIcon) && (
                <Box className={styles.toolTipRow}>
                  <Box className={styles.toolTipBold}>{t('pain') + ' :'}</Box>
                  <Box className={styles.toolTipText}>{t(ev.idIcon)}</Box>
                </Box>
              )}
              {/(Bleeding)$/.test(ev.idIcon) && (
                <Box className={styles.toolTipRow}>
                  <Box className={styles.toolTipBold}>{t('bleeding') + ' :'}</Box>
                  <Box className={styles.toolTipText}>{t(ev.idIcon)}</Box>
                </Box>
              )}

              {ev.eventCategory === 15 && (
                <Box className={styles.toolTipRow}>
                  <Box className={styles.toolTipBold}>{t('place')} :</Box>
                  <Box className={styles.toolTipText}>{ev.place}</Box>
                </Box>
              )}
              {(ev.eventCategory === 12 || ev.eventCategory === 13 || ev.eventCategory === 14) && (
                <Box>
                  <Box className={styles.toolTipRow}>
                    <Box className={styles.toolTipBold}>{t('state')} :</Box>
                    <Box className={styles.toolTipText}>
                      {t(eventCategoryTypes()[ev.eventCategory as EventCategory])}
                    </Box>
                  </Box>
                  <Box className={styles.toolTipRow}>
                    <Box className={styles.toolTipBold}>{t('place')} :</Box>
                    <Box className={styles.toolTipText}>{ev.place}</Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        ))}
      </>
    )
  }

  return (
    <Box className={styles.tooltipContainer}>
      <div className={styles.tooltipContent}> {drawEvent()}</div>
    </Box>
  )
}
