export function reduceString(text: string, textLimit: number) {
  const finalChars = '...'

  return text.length > textLimit
    ? text.slice(0, textLimit - 1 - finalChars.length).concat(finalChars)
    : text
}

export function camelize(str: string) {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    return index === 0 ? match.toLowerCase() : match.toUpperCase()
  })
}

export function capitalize(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function getFirstWord(str: string) {
  const spaceIndex = str.indexOf(' ')
  return spaceIndex === -1 ? str : str.substring(0, spaceIndex)
}

export function isValidEmail(email: string) {
  const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
  return emailPattern.test(email)
}

export function isValidTel(tel: string) {
  const telPattern = /^((00|\+)\d{2})?\d{9}$/
  return telPattern.test(tel)
}

export function isValidPassword(password: string) {
  const passwordPattern =
    // eslint-disable-next-line no-useless-escape
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[~!@#\$%\^&\*=+|,.¿?\-\_\(\)\;\:\'\"<>]).{8,}$/
  return passwordPattern.test(password)
}

export function base64ArrayBuffer(arrayBuffer: ArrayBuffer): string {
  const binary = new Uint8Array(arrayBuffer)
  let base64 = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'

  for (let i = 0; i < binary.length; i += 3) {
    const a = binary[i]
    const b = binary[i + 1]
    const c = binary[i + 2]
    const enc1 = a >> 2
    const enc2 = ((a & 3) << 4) | (b >> 4)
    const enc3 = ((b & 15) << 2) | (c >> 6)
    const enc4 = c & 63

    base64 +=
      characters.charAt(enc1) +
      characters.charAt(enc2) +
      characters.charAt(enc3) +
      characters.charAt(enc4)
  }

  return base64
}

export function addCero(n: number): string {
  let value = '0'
  if (n.toString().length === 1) {
    value += n
  } else {
    value = n.toString()
  }
  return value
}

export function isValidZipCode(zipCode: string) {
  const zipCodePattern = /^\d{5}$/
  return zipCodePattern.test(zipCode)
}
