import { Query } from '../../../common/api/Query'
import { Circle } from '../../circle/models/Circle'
import {
  User,
  UserDTO,
  fromModel as fromModel2,
  toModel as toModel2,
} from '../../users/models/User'
import { CircleDTO, fromModel, toModel } from '../../circle/models/CircleDTO'
import { UserCircleWithCircleAndUserDTO } from './UserCircleWithCircleAndUserDTO'

export type UserCircleWithCircleAndUserProps = {
  id: string
  circle: CircleDTO
  user: UserDTO
  familiarsID: string[]
  profSmsID: string[]
  profExtID: string[]
  careersID: string[]
}

export class UserCircleWithCircleAndUser {
  private readonly _id: string
  private _circle: Circle
  private _user: User
  private _familiarsID: string[]
  private _profSmsID: string[]
  private _profExtID: string[]
  private _careersID: string[]

  constructor(p: UserCircleWithCircleAndUserProps) {
    this._id = p.id
    this._circle = toModel(p.circle)
    this._user = toModel2(p.user)
    this._familiarsID = p.familiarsID
    this._profSmsID = p.profSmsID
    this._profExtID = p.profExtID
    this._careersID = p.careersID
  }

  get id(): string {
    return this._id
  }

  get circle(): Circle {
    return this._circle
  }

  set circle(value: Circle) {
    this._circle = value
  }

  get user(): User {
    return this._user
  }

  set user(value: User) {
    this._user = value
  }

  get familiarsID(): string[] {
    return this._familiarsID
  }

  set familiarsID(value: string[]) {
    this._familiarsID = value
  }

  get profSmsID(): string[] {
    return this._profSmsID
  }

  set profSmsID(value: string[]) {
    this._profSmsID = value
  }

  get profExtID(): string[] {
    return this._profExtID
  }

  get profExtIDs(): string[] {
    if (!Array.isArray(this._profExtID)) {
      // If it's not an array transform it into an array using the char ',' as separator
      // ensure that the string is not empty
      if (this._profExtID) {
        // ensure that the string is a string not of type never
        // @ts-expect-error this is a workaround to avoid the error
        this._profExtID = this._profExtID.split(',')
      } else {
        this._profExtID = []
      }
    }

    return this._profExtID
  }

  get careersID(): string[] {
    return this._careersID
  }

  set careersID(value: string[]) {
    this._careersID = value
  }

  toDTO(): UserCircleWithCircleAndUserDTO {
    return {
      id: this.id,
      circle: fromModel(this.circle),
      user: fromModel2(this.user),
      familiarsID: this.familiarsID,
      profSmsID: this.profSmsID,
      profExtID: this.profExtID,
      careersID: this.careersID,
    }
  }
}

export class UserCircleQuery extends Query<UserCircleWithCircleAndUser> {
  userID?: string
  roleID?: string[]
}
