import {
  Alert,
  Box,
  Card,
  CardContent,
  Checkbox,
  Chip,
  Divider,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ProfessionalType } from '../../../modules/users/models/ProfessionalType'

import stylesConfig from './ConfigForm.module.css'
import { LoggedUser } from '../../../modules/users/models/LoggedUser'
import { CronItemMockup } from '../../../components/form-card/CronItemMockup'
import { FormDTO } from '../../../modules/forms/models/FormDTO'
import { FormType } from '../../../modules/forms/enums/FormType'
import { CircleDTO } from '../../../modules/circle/models/CircleDTO'
import { Circle } from '../../../modules/circle/models/Circle'

type ConfigFormSectionProps = {
  isRecurrence: boolean
  form: FormDTO
  handleRecurrence: (b: boolean) => void
  pathologies: Circle[]
  propsId: string | undefined
  professionalType: ProfessionalType | undefined
  professionalSpecialities: ProfessionalType[]
  currentCirclePathology: string[] | undefined
  loggedUser: LoggedUser | undefined
  circle: CircleDTO[]
  handleSelectPathology: (e: string) => void
  handleDeletePathology: (type: string, p: string) => void
  handleSelectCircle: (event: SelectChangeEvent<string[]>) => void
  handleChangeRecurrence: (s: string, sd: Date, fd: Date) => void
  handlerMonthRecurrence?: (mr: number) => void
  recoveredCron?: (
    cron: string,
    mc: number,
    startDate: Date,
    finishDate: Date,
    weekDay: string[]
  ) => void
  preview?: boolean
  cronOldState?: string
  weekDayRecovered?: string[]
  isFormEdit?: boolean
  isCreator: boolean
}

export function ConfigFormSection({
  isRecurrence,
  handleRecurrence,
  propsId,
  professionalType,
  professionalSpecialities,
  handleSelectPathology,
  handleDeletePathology,
  handleChangeRecurrence,
  currentCirclePathology,
  loggedUser,
  circle,
  handleSelectCircle,
  pathologies,
  form,
  handlerMonthRecurrence,
  recoveredCron,
  preview,
  cronOldState,
  weekDayRecovered,
  isFormEdit,
  isCreator,
}: ConfigFormSectionProps) {
  const isContentManager = loggedUser?.roles?.some((role) => role.startsWith('manager'))
  const isExternProfessional = loggedUser?.roles?.some((role) => role === 'externProfessional')
  const isProfessional = loggedUser?.roles.some((r) => r === 'professionalSMS')
  const { t } = useTranslation()
  return (
    <Box mb={3} display="flex">
      <Card className={stylesConfig.cardContainer}>
        {(form.isTemplate === FormType.Form || isFormEdit) && (
          <Box className={stylesConfig.boxRecurrence}>
            <>
              <Box display="flex">
                <Checkbox
                  style={{ width: '25px', height: '25px', marginRight: '10px' }}
                  checked={isRecurrence}
                  name={'recurrence'}
                  onChange={() => handleRecurrence(!isRecurrence)}
                  disabled={!isCreator}
                />
              </Box>
              <p>{t('periodicity')}</p>
            </>
          </Box>
        )}
        {isRecurrence && (
          <Box mb={3}>
            <CronItemMockup
              handleChange={handleChangeRecurrence}
              id={propsId}
              form={form}
              isRecurrence={isRecurrence}
              handlerMonthRecurrence={handlerMonthRecurrence}
              recoveredCron={recoveredCron}
              preview={preview}
              cronOldState={cronOldState}
              weekDayRecovered={weekDayRecovered}
              isCreator={isCreator}
            />
          </Box>
        )}
        {!isExternProfessional && (
          <CardContent style={{ width: '100%', display: 'flex', padding: 0 }}>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '400px',
                margin: 10,
                marginRight: 50,
              }}
            >
              <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p
                  style={{
                    fontFamily: 'Poppins, sans-serif',
                    fontWeight: 'bold',
                    fontSize: window.innerWidth > 1220 ? '1.2rem' : '0.9rem',
                    marginLeft: 0,
                  }}
                >
                  {t('selectSpecialty')}
                </p>
              </Box>
              <Divider style={{ backgroundColor: '#68b3e0', marginTop: 8, height: 4 }} />
              <Box style={{ display: 'flex', marginTop: '30px' }}>
                <Select
                  MenuProps={{
                    MenuListProps: {
                      sx: {
                        'li.MuiButtonBase-root': {
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          fontFamily: 'Poppins',
                          paddingLeft: '10px',
                        },
                      },
                    },
                  }}
                  SelectDisplayProps={{
                    style: {
                      paddingLeft: '60px',
                      fontFamily: 'Poppins',
                    },
                  }}
                  fullWidth
                  variant="outlined"
                  style={{ backgroundColor: '#f5f2f2' }}
                  inputProps={{ 'aria-label': 'Without label' }}
                  disabled={!isCreator || !isProfessional}
                  value={
                    !isProfessional
                      ? professionalSpecialities.length > 0
                        ? professionalSpecialities[0].name
                        : ''
                      : (professionalType?.name ?? '')
                  }
                  onChange={(e) => handleSelectPathology(e.target.value)}
                >
                  {professionalSpecialities.length > 0 &&
                    professionalSpecialities.map((k) => (
                      <MenuItem key={k.id} value={k.name}>
                        {k.name}
                      </MenuItem>
                    ))}
                </Select>
              </Box>
              {!isContentManager && professionalSpecialities.length === 0 && (
                <Alert
                  style={{ color: 'red', marginTop: '10px', fontFamily: 'Poppins' }}
                  severity="error"
                >
                  {t('selectSpecialtyError')}
                </Alert>
              )}
            </Box>

            <Box style={{ width: '400px', margin: 10, marginRight: 50 }}>
              {professionalType && circle.length > 0 ? (
                <Box style={{ width: '100%' }}>
                  <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p
                      style={{
                        fontFamily: 'Poppins, sans-serif',
                        fontWeight: 'bold',
                        fontSize: window.innerWidth > 1220 ? '1.2rem' : '0.9rem',
                        marginLeft: 0,
                      }}
                    >
                      {t('selectCircle')}
                    </p>
                  </Box>
                  <Divider style={{ backgroundColor: '#68b3e0', marginTop: 8, height: 4 }} />
                  <Box style={{ display: 'flex', marginTop: '30px' }}>
                    <Select
                      MenuProps={{
                        MenuListProps: {
                          sx: {
                            'li.MuiButtonBase-root': {
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                              fontFamily: 'Poppins',
                              paddingLeft: '10px',
                            },
                          },
                        },
                      }}
                      SelectDisplayProps={{
                        style: {
                          paddingLeft: '20px',
                          fontFamily: 'Poppins',
                        },
                      }}
                      fullWidth
                      multiple
                      variant="outlined"
                      disabled={!isCreator}
                      style={{ backgroundColor: '#f5f2f2' }}
                      value={currentCirclePathology ?? []}
                      onChange={handleSelectCircle}
                    >
                      {Object.values(circle).map((k) => (
                        <MenuItem key={k.id} value={k.name}>
                          {k.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Box>
              ) : (
                ''
              )}
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                marginTop: '80px',
                marginLeft: '20px',
              }}
            >
              {currentCirclePathology &&
                currentCirclePathology?.length > 0 &&
                currentCirclePathology.map((p, index) => (
                  <Chip
                    key={p + index}
                    sx={{
                      '& .MuiChip-deleteIcon': {
                        color: '#fff',
                      },
                    }}
                    style={{
                      backgroundColor: index % 2 ? '#0163a5' : '#68b4e1',
                      marginRight: 8,
                      marginBottom: 10,
                      borderRadius: '20px',
                    }}
                    label={
                      <span style={{ fontFamily: 'Poppins', color: '#fff', fontWeight: 'bold' }}>
                        {p}
                      </span>
                    }
                    onDelete={() => handleDeletePathology('circle', p)}
                  />
                ))}
            </Box>
          </CardContent>
        )}
      </Card>
    </Box>
  )
}
