import React, { useEffect, useState } from 'react'
import { AppButton, ButtonTheme } from '../../../components/app-button/AppButton'
import { useNavigate } from 'react-router-dom'
import { ROUTE_CALENDAR_FORM, ROUTE_PATIENT_DATA } from '../../../routes/routes-constants'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { YearView } from '../../calendar/YearView'
import { LoadingSpinner } from '../../../components/loading-spinner/LoadingSpinner'
import { Query, QueryParam } from '../../../common/api/Query'
import { Event } from '../../../modules/calendar/models/Event'
import { EventService } from '../../../modules/calendar/services/EventService'
import { EVENT_SERVICE_KEY } from '../../../modules/calendar'
import { getCalendarContainer } from '../../../container/calendar-module'

import { EventCategory } from '../../../modules/calendar/enums/EventCategory'
import styles from '../../generic/GenericTable.module.css'
import genericStyle from '../../../common/utils/generic.module.css'
import { downloadFile } from '../../../common/utils/file'
import { dateToDateString } from '../../../common/utils/date'
import { getVaccinesContainer } from '../../../container/vaccine-module'
import { VaccinesService } from '../../../modules/vaccines/services/VaccinesService'
import { VACCINES_SERVICE_KEY } from '../../../modules/vaccines'
import { Vaccine, VaccinesQuery } from 'modules/vaccines/models/Vaccines'
import { VACUSAN_SERVICE_KEY } from '../../../modules/vaccination'
import { getVaccinationContainer } from '../../../container/vacusan-module'
import { VacusanService } from '../../../modules/vaccination/services/VacusanService'
import { forkJoin, Observable } from 'rxjs'
import { ItemList } from '../../../common/models/ItemList'
import { Vacusan } from '../../../modules/vaccination/models/Vacusan'
import { emptyEventDTO, toModel } from 'modules/calendar/models/EventDTO'
import { getUserCircleContainer } from '../../../container/user-circle-module'
import {
  IUserCircleActiveService,
  USER_CIRCLE_ACTIVE_SERVICE_KEY,
} from '../../../modules/user-circle'

const calendarContainer = getCalendarContainer()
const eventService = calendarContainer.get<EventService>(EVENT_SERVICE_KEY)
const vaccinesContainer = getVaccinesContainer()
const vaccinesService = vaccinesContainer.get<VaccinesService>(VACCINES_SERVICE_KEY)
const vaccinationContainer = getVaccinationContainer()
const vaccinationService = vaccinationContainer.get<VacusanService>(VACUSAN_SERVICE_KEY)
const UserCircleActiveService = getUserCircleContainer().get<IUserCircleActiveService>(
  USER_CIRCLE_ACTIVE_SERVICE_KEY
)
export function VaccinationSchedule() {
  const { t } = useTranslation()
  const userCircle = UserCircleActiveService.getActiveFullUserCircle()
  const [date, setDate] = useState<Date>(new Date('1/1/2099'))
  const [totalEvents, setTotalEvents] = useState<Event[]>([])
  const [newVaccinesEvent, setNewVaccinesEvent] = useState<Event[]>([])
  const [totalVaccines, setTotalVaccines] = useState<ItemList<Vaccine>[]>()
  const [vaccinesVacusan, setVaccinesVacusan] = useState<Vacusan>()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [vaccinesNDescription, setVaccinesNDescription] = useState<Map<string, string>>(new Map())

  const getVaccines = (descriptions: string[]): Observable<ItemList<Vaccine>[]> =>
    forkJoin(
      descriptions.map((d) =>
        vaccinesService.getFilteredList(
          new Query({ query: [new QueryParam<VaccinesQuery>('name', d)] })
        )
      ) as unknown
    ) as Observable<ItemList<Vaccine>[]>

  useEffect(() => {
    setTotalEvents([...totalEvents, ...newVaccinesEvent])
    setIsLoading(false)
  }, [newVaccinesEvent])

  useEffect(() => {
    if (totalVaccines !== undefined && vaccinesVacusan !== undefined && totalEvents !== undefined) {
      const today = new Date()
      const newMap = new Map()

      totalVaccines.forEach((r) => {
        for (const item of r.items) {
          const event = totalEvents.find((e) => e.description === item.name)

          if (!event) {
            continue
          }

          const vaccineVacusan = vaccinesVacusan.Vaccinations.Vaccination?.find(
            (v) =>
              v.VaccineCode === item.tvac.toString() &&
              v.VaccineDate === event.startDate.toLocaleDateString()
          )

          const index = vaccinesVacusan.Vaccinations.Vaccination?.findIndex(
            (v) =>
              v.VaccineCode === item.tvac.toString() &&
              v.VaccineDate === event.startDate.toLocaleDateString()
          )

          let state = event.startDate <= today ? 'inoculated' : 'notInoculated'

          if (vaccineVacusan) {
            state = new Date(vaccineVacusan.VaccineDate) <= today ? 'inoculated' : 'notInoculated'
          }

          vaccinesVacusan.Vaccinations.Vaccination?.splice(index, 1)
          const value = item.description ? item.description + '  ' : ''

          newMap.set(event.description, value + `${t('state')}: ` + t(state))
        }
      })

      const newVaccines: Event[] = []
      vaccinesVacusan?.Vaccinations?.Vaccination?.forEach((v) => {
        const dateParts = v.VaccineDate.split('/')
        const vaccineDate = new Date(
          Number(dateParts[2]),
          Number(dateParts[1]) - 1,
          Number(dateParts[0])
        )

        const state = vaccineDate <= today ? 'inoculated' : 'notInoculated'
        newMap.set(v.VaccineName, `${t('state')}: ` + t(state))

        const eventDTO = emptyEventDTO(vaccineDate)
        eventDTO.description = v.VaccineName

        newVaccines.push(toModel(eventDTO))
      })

      setNewVaccinesEvent(newVaccines)
      setVaccinesNDescription(new Map(newMap))
    }
  }, [totalVaccines])

  useEffect(() => {
    if (userCircle?.user.cip) {
      vaccinationService.getByCIPA(userCircle.user.cip).subscribe((res) => {
        setVaccinesVacusan(res)
      })
    }

    eventService
      .getFilteredList(
        new Query({
          query: [
            new QueryParam<Event>('eventCategory', EventCategory.Vaccines),
            new QueryParam('StartDate', new Date('1/1/2000').toISOString()),
            new QueryParam('FinishDate', new Date('1/1/2099').toISOString()),
          ],
        }),
        userCircle?.user.id ?? ''
      )
      .subscribe((el) => {
        setTotalEvents([...el.items])

        getVaccines(el.items.map((e) => e.description)).subscribe((res) => {
          setTotalVaccines(res)
        })
      })
  }, [])

  const goBack = () => navigate(ROUTE_PATIENT_DATA)

  const downloadSchedule = () => {
    if (!userCircle?.user.id) {
      return
    }
    eventService
      .createPDF(
        new Query({
          query: [new QueryParam<Event>('eventCategory', EventCategory.Vaccines)],
        }),
        userCircle.user.id
      )
      .subscribe((res) => {
        const name = `${t('vaccines').toLowerCase()}-${dateToDateString(new Date())}`
        downloadFile(name, 'application/pdf', res)
      })
  }

  const editEvent = (event: Event) =>
    navigate(`${ROUTE_CALENDAR_FORM}/${event.id}`, { state: { date } })

  function removeEvent(event: Event) {
    if (!event?.id) {
      return
    }
    eventService.delete(event.id).subscribe(() => {
      setDate(new Date(date))
    })
  }

  return (
    <Box className={genericStyle.pageContainer}>
      <Box>
        <Box mb={3} display="flex" justifyContent="space-between">
          <AppButton
            theme={ButtonTheme.NewSecondary}
            type={'button'}
            label={t('back')}
            handler={goBack}
          />
          {totalEvents.length > 0 && (
            <AppButton
              theme={ButtonTheme.NewPrimary}
              type={'button'}
              label={t('download')}
              handler={downloadSchedule}
            />
          )}
        </Box>
        {!isLoading ? (
          totalEvents.length ? (
            <YearView
              handlerEdit={editEvent}
              handlerRemove={removeEvent}
              selectedDate={date}
              events={totalEvents}
              handlerTooltip={vaccinesNDescription}
            />
          ) : (
            <h1 key="no-calendar" className={styles.noElements}>
              {t('noCalendar')}
            </h1>
          )
        ) : (
          <LoadingSpinner />
        )}
      </Box>
    </Box>
  )
}
