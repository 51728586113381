import { Container } from '../../common/container/Container'

export type VaccinesContainerConfig = {
  moduleFullUrl: string
}

export type VaccinesProps = {
  parentContainer: Container
  config: VaccinesContainerConfig
}

export const VACCINES_MODULE = Symbol('VACCINES_MODULE')
export const VACCINES_API_KEY = Symbol('VACCINES_API_KEY')
export const VACCINES_SERVICE_KEY = Symbol('VACCINES_SERVICE_KEY')
