import React, { useState } from 'react'
import { Box } from '@mui/material'
import style from './Login.module.css'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { makeStyles } from '@mui/styles'
import { getUserContainer } from './container/user-module'
import { IUserService, USER_SERVICE_KEY } from './modules/users'
import { Theme } from '@mui/material/styles'

const userContainer = getUserContainer()
const userService = userContainer.get<IUserService>(USER_SERVICE_KEY)

type NewPassword = {
  id: string
  password: string
  newPassword: string
  token: string
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 2, 2, 2),
  },
}))

type ChangePasswordProps = {
  id: string
}

export default function ChangePassword(props: ChangePasswordProps) {
  const classes = useStyles()

  const [password, setPassword] = useState<string>('')
  const [passwordConfirm, setPasswordConfirm] = useState<string>('')
  const [hasError, setHasError] = useState(false)
  const [message, setMessage] = useState(false)
  const [passwordMessage, setPasswordMessage] = useState('')

  const handlerRecover = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    doRecover()
  }

  const doRecover = () => {
    setPasswordMessage('')
    setHasError(false)

    if (
      !password.match(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[~!@#$%^&*=+|,.¿?\-_();:'"<>[\]]).{8,}$/
      )
    ) {
      setPasswordMessage(
        'La contraseña debe tener al menos 8 caracteres e incluir una mayuscula, un número y un símbolo'
      )
      return
    }

    if (!password || password !== passwordConfirm) {
      setHasError(true)
      return
    }

    const newPassword: NewPassword = {
      id: '',
      password: '',
      newPassword: password,
      token: props.id,
    }

    userService.changePassword(newPassword).subscribe(() => {
      setHasError(false)
      setMessage(true)
      setInterval(() => {
        handleReload()
      }, 3000)
    })
  }

  const handleReload = () => {
    const newUrl = window.location.href.split('?')[0]
    // Update the URL without the query parameters
    window.history.replaceState(null, '', newUrl)
    // Reload the page
    window.location.reload()
  }

  return (
    <>
      <Box className={style.loginContainer} display={'flex'}>
        <Box className={style.login}>
          <h2>Introduzca su nueva contraseña</h2>
          {hasError && <Typography color="error">{'La contraseña debe coincidir'}</Typography>}
          {passwordMessage && <Typography color="error">{passwordMessage}</Typography>}
          {message && (
            <Typography color="primary">
              {'Su contraseña se ha cambiado correctamente, redirigiendo...'}
            </Typography>
          )}
          <form className={style.loginFields} noValidate onSubmit={handlerRecover}>
            <span style={{ marginTop: '10px' }} className="input input--fumi">
              <input
                className="input__field input__field--fumi"
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="off"
                required
              />
              <label className="input__label input__label--fumi">
                <i className="fa fa-fw fa-lock icon icon--fumi" title="Contraseña" />
                <span className="input__label-content input__label-content--fumi">
                  Nueva contraseña
                </span>
              </label>
            </span>
            <span style={{ marginTop: '10px' }} className="input input--fumi">
              <input
                className="input__field input__field--fumi"
                type="password"
                id="passwordConfirm"
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
                autoComplete="off"
                required
              />
              <label className="input__label input__label--fumi">
                <i className="fa fa-fw fa-lock icon icon--fumi" title="Confirmar contraseña" />
                <span className="input__label-content input__label-content--fumi">
                  Confirmar nueva contraseña
                </span>
              </label>
            </span>
            <Box display={'flex'} sx={{ flexDirection: 'row' }} padding={2}>
              <Button
                type="button"
                variant="contained"
                color="secondary"
                className={classes.submit}
                onClick={handleReload}
                style={{ marginRight: '10px' }}
              >
                <p style={{ fontWeight: 'bolder', color: 'white' }}>Volver</p>
              </Button>
              <Button type="submit" variant="contained" color="primary" className={classes.submit}>
                <p style={{ fontWeight: 'bolder', color: 'white' }}>Enviar</p>
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  )
}
