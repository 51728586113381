import { Box, MenuItem, Select, InputLabel, FormControl, Alert } from '@mui/material'
import { Header } from '../header/Header'
import style from './CustomModal.module.css'
import { useTranslation } from 'react-i18next'
import { AppButton, ButtonTheme } from '../app-button/AppButton'
import close from '../../assets/table_icons/ico-eliminar.svg'
import React, { useState } from 'react'
import { TextFieldItem } from '../form-card/TextFieldItem'

type modalProps = {
  title: string
  warningText?: string
  create?: boolean
  name?: string
  description?: string
  professional?: string
  allProfessional?: object[]
  handleCreateVariable?: (name: string, description: string) => void
  handleCreateVariable2?: (name: string, description: string, professional: string) => void
  handleClose: () => void
  handleSave: () => void
  noButton?: boolean
}

export const CustomModal = (props: modalProps): JSX.Element => {
  const { t } = useTranslation()
  const [name, setName] = useState<string>(props.name ? props.name : '')
  const [description, setDescription] = useState<string>(props.description ? props.description : '')
  const [seccionPadre, setSeccionPadre] = useState<string>(
    props.professional ? props.professional : ''
  )
  const [error, setError] = useState<boolean>(false)
  const [error2, setError2] = useState<boolean>(false)
  const changeName = (event: any) => {
    setName(event.target.value)
  }

  const changeDescription = (event: any) => {
    setDescription(event.target.value)
  }

  const changeSeccionPadre = (event: any) => {
    setSeccionPadre(event.target.value)
  }

  const handleSave = () => {
    if (props.handleCreateVariable) {
      if (name === '' || description === '') {
        setError2(true)
        return
      }
      props.handleCreateVariable(name, description)
    }
    if (props.handleCreateVariable2) {
      if (seccionPadre === '' || name === '' || description === '') {
        setError(true)
        return
      }
      props.handleCreateVariable2(name, description, seccionPadre)
    }
    props.handleSave()
  }

  return (
    <div>
      <div className={style.agoraContainer}>
        <Box className={style.containerInside}>
          <Header label={props.title} icon={close} onClick={props.handleClose} />
          <form>
            <Box mb={3}>
              <p style={{ textAlign: 'center' }}>{props.warningText}</p>
            </Box>
            {props.create ? (
              <Box>
                <form style={{ display: 'flex', flexDirection: 'column' }}>
                  <TextFieldItem
                    field={'name'}
                    value={name}
                    label={'name'}
                    type={'text'}
                    handleChange={changeName}
                    rows={undefined}
                    required={true}
                  />
                  <br />
                  <TextFieldItem
                    field={'description'}
                    value={description}
                    label={'description'}
                    type={'text'}
                    handleChange={changeDescription}
                    rows={4}
                    required={true}
                  />
                </form>
                {error2 ? (
                  <Alert style={{ marginTop: 8, marginLeft: 8 }} severity="warning">
                    Todos los campos son obligatorios
                  </Alert>
                ) : null}
              </Box>
            ) : (
              <></>
            )}
            {props.allProfessional ? (
              props.allProfessional.length !== 0 ? (
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <FormControl variant="filled" style={{ minWidth: 300, marginTop: 15 }}>
                    <InputLabel id="seccionPadre">{t('fatherSection')} *</InputLabel>
                    <Select
                      style={{ fontFamily: 'Poppins, sans-serif' }}
                      labelId="seccionPadre"
                      id="selectSeccionPadre"
                      label={`${t('fatherSection')}*`}
                      value={seccionPadre}
                      onChange={changeSeccionPadre}
                    >
                      {props.allProfessional.map((element: any, index: number) => {
                        return (
                          <MenuItem
                            key={element.id}
                            style={{ fontFamily: 'Poppins, sans-serif' }}
                            value={element.id}
                          >
                            {element.name}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                  {error ? (
                    <Alert style={{ marginTop: 8, marginLeft: 8 }} severity="warning">
                      Todos los campos son obligatorios
                    </Alert>
                  ) : null}
                </Box>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
            {props.noButton ? (
              <></>
            ) : (
              <Box mt={1} mb={1} className={style.buttons}>
                <Box style={{ marginRight: 4 }}>
                  <AppButton
                    theme={ButtonTheme.NewSecondary}
                    type={'button'}
                    label={t('cancel')}
                    handler={props.handleClose}
                  />
                </Box>
                <AppButton
                  theme={ButtonTheme.NewPrimary}
                  type={'button'}
                  label={t('submit')}
                  handler={handleSave}
                />
              </Box>
            )}
          </form>
        </Box>
      </div>
    </div>
  )
}
