import { Query } from '../../../common/api/Query'
import { FormField } from './FormField'
import { FormType } from '../enums/FormType'
import { FormPathology } from './FormPathology'
import { CheckboxAssignType } from '../enums/CheckboxAssignType'

export type FormProps = {
  id?: string
  title: string
  description: string
  formFields: FormField[]
  repeating: string
  startCron: Date
  finishCron: Date
  fieldWithoutAnswer: string
  created: Date
  isTemplate: FormType
  creator: string
  formPathologies: FormPathology[]
  specialities: string
  circles: string
  monthRecurrence: number
  checkBoxesSelected?: CheckboxAssignType
  subscribers?: string
}

export class Form {
  private readonly _id: string | undefined

  private _title: string
  private _description: string
  private _formFields: FormField[]
  private _repeating: string
  private _startCron: Date
  private _finishCron: Date
  private _fieldWithoutAnswer: string
  private _created: Date
  private _isTemplate: FormType
  private _creator: string
  private _specialities: string
  private _circles: string
  private _checkBoxesSelected?: string
  private _monthRecurrence: number
  private _subscribers?: string

  constructor(p: {
    fieldWithoutAnswer: string
    creator: string
    created: Date
    isTemplate: FormType
    repeating: string
    description: string
    finishCron: Date
    formFields: FormField[]
    id: string | undefined
    title: string
    startCron: Date
    specialities: string
    circles: string
    checkBoxesSelected?: string
    monthRecurrence: number
    subscribers?: string
  }) {
    this._id = p.id
    this._title = p.title
    this._description = p.description
    this._formFields = p.formFields
    this._repeating = p.repeating
    this._startCron = new Date(p.startCron)
    this._finishCron = new Date(p.finishCron)
    this._fieldWithoutAnswer = p.fieldWithoutAnswer
    this._created = p.created
    this._isTemplate = p.isTemplate
    this._creator = p.creator
    this._specialities = p.specialities
    this._circles = p.circles
    this._checkBoxesSelected = p.checkBoxesSelected
    this._monthRecurrence = p.monthRecurrence
    this._subscribers = p.subscribers
  }

  get id(): string | undefined {
    return this._id
  }

  get title(): string {
    return this._title
  }

  set title(value: string) {
    this._title = value
  }

  get created(): Date {
    return this._created
  }

  get description(): string {
    return this._description
  }

  set description(value: string) {
    this._description = value
  }

  get repeating(): string {
    return this._repeating
  }

  set repeating(value: string) {
    this._repeating = value
  }

  get startCron(): Date {
    return this._startCron
  }

  set startCron(value: Date) {
    this._startCron = value
  }

  get finishCron(): Date {
    return this._finishCron
  }

  set finishCron(value: Date) {
    this._finishCron = value
  }

  get isTemplate(): FormType {
    return this._isTemplate
  }

  get fieldWithoutAnswer(): string {
    return this._fieldWithoutAnswer
  }

  set fieldWithoutAnswer(value: string) {
    this._fieldWithoutAnswer = value
  }

  get formFields(): FormField[] {
    return [...this._formFields]
  }

  get creator(): string {
    return this._creator
  }

  set creator(value: string) {
    this._creator = value
  }

  get circles(): string {
    return this._circles
  }

  set circles(value: string) {
    this._circles = value
  }

  get specialities(): string {
    return this._specialities
  }

  set specialities(value: string) {
    this._specialities = value
  }

  get monthRecurrence(): number {
    return this._monthRecurrence
  }

  set monthRecurrence(value: number) {
    this._monthRecurrence = value
  }

  get checkBoxesSelected(): string | undefined {
    if (this._checkBoxesSelected === undefined) return undefined
    return this._checkBoxesSelected
  }

  set checkBoxesSelected(value: string | undefined) {
    this._checkBoxesSelected = value
  }

  get subscribers(): string | undefined {
    return this._subscribers
  }

  set subscribers(value: string | undefined) {
    this._subscribers = value
  }

  addFormField(value: FormField) {
    this._formFields.push(value)
  }

  removeFormField(id: string) {
    this._formFields = this._formFields.filter((ff) => ff.id !== id)
  }
}

export class FormQuery extends Query<Form> {
  ids?: string[]
  title?: string
  userID?: string
  creator?: string
  allFields?: string
  isTemplate?: FormType
  specialities?: string
  others?: string

  fillWithCreatorName?: string
}
