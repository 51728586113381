import { Skeleton, Box } from '@mui/material'
import React from 'react'
import skeletonStyle from './TableSkeleton.module.css'

interface TableSkeletonProps {
  searcher?: boolean
}

export const TableSkeleton = ({ searcher }: TableSkeletonProps) => {
  return (
    <Box className={skeletonStyle.container}>
      {searcher && (
        <Box className={skeletonStyle.searcher}>
          <Skeleton height={50} width={100} />
        </Box>
      )}
      <Skeleton height={50} />
      <Skeleton height={200} />
    </Box>
  )
}
