import { Query } from '../../../common/api/Query'

export type SportCenterProps = {
  id: string
  cityHallID: string
  name: string
  postalCode: string
  location: string
  latitude: string
  longitude: string
}

export class SportCenter {
  private readonly _id: string
  private _cityHallID: string
  private _name: string
  private _postalCode: string
  private _location: string
  private _latitude: string
  private _longitude: string

  constructor(props: SportCenterProps) {
    this._id = props.id
    this._cityHallID = props.cityHallID
    this._name = props.name
    this._postalCode = props.postalCode
    this._location = props.location
    this._latitude = props.latitude
    this._longitude = props.longitude
  }

  get id(): string {
    return this._id
  }

  get cityHallID(): string {
    return this._cityHallID
  }

  set cityHallID(value: string) {
    this._cityHallID = value
  }

  get name(): string {
    return this._name
  }

  set name(value: string) {
    this._name = value
  }

  get postalCode(): string {
    return this._postalCode
  }

  set postalCode(value: string) {
    this._postalCode = value
  }

  get location(): string {
    return this._location
  }

  set location(value: string) {
    this._location = value
  }

  get latitude(): string {
    return this._latitude
  }

  set latitude(value: string) {
    this._latitude = value
  }

  get longitude(): string {
    return this._longitude
  }

  set longitude(value: string) {
    this._longitude = value
  }
}

export class SportCenterQuery extends Query<SportCenter> {
  id?: string
  ids?: string[]
  cityHallID?: string
  name?: string
  postalCode?: string
  location?: string
  longitude?: string
  latitude?: string
}
