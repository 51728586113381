import { Container } from '../../common/container/Container'

export type CalendarContainerConfig = {
  moduleFullUrl: string
}

export type CalendarProps = {
  parentContainer: Container
  config: CalendarContainerConfig
}

export const CALENDAR_MODULE = Symbol('CALENDAR_MODULE')

export const EVENT_API_KEY = Symbol('EVENT_API_KEY')

export const EVENT_SERVICE_KEY = Symbol('EVENT_SERVICE_KEY')
