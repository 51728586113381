import React, { ChangeEvent, useEffect, useState } from 'react'
import { Box, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import style from './Searcher.module.css'

type SearcherProps = {
  disabled?: boolean
  handler: (search: string) => void
  reverse: () => void
  handleNewConversation: () => void
}

export function Searcher(props: SearcherProps): JSX.Element {
  const { t } = useTranslation()

  const [search, setSearch] = useState<string>('')
  /* const [descToAsc, setDescToAsc] = useState<boolean>(false) */

  const handlerChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    setSearch(event.target.value)

  useEffect(() => {
    props.handler(search)
  }, [search])

  return (
    <Box className={style.inputBox}>
      <TextField
        sx={{
          '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': { fontFamily: 'Poppins' },
        }}
        className={style.inputSearcher}
        label={t('filterByConversation')}
        variant={'outlined'}
        type="text"
        value={search}
        onChange={handlerChange}
        disabled={props.disabled}
      />
    </Box>
  )
}
