import { Box, Popper } from '@mui/material'
import React from 'react'

type SubMenuProps = {
  open: boolean
  setOpen: (open: boolean) => void
  setCircleHover?: (hover: boolean) => void
  children: React.ReactNode
  style: string
  anchorEl: HTMLElement | null
}

export const SubMenu = ({
  open,
  setOpen,
  children,
  style,
  setCircleHover,
  anchorEl,
}: SubMenuProps) => {
  return (
    <div onMouseEnter={() => (setOpen ? setOpen(true) : '')} onMouseLeave={() => setOpen(false)}>
      <Popper
        anchorEl={anchorEl}
        id={open ? 'simple-popper' : undefined}
        open={open ?? false}
        style={{
          position: 'fixed',
          top: '141px',
          backgroundColor: 'rgba(67,67,67,0.55)',
          height: '100vh',
          mixBlendMode: 'normal',
          zIndex: 99 // Needed background overlap every element under
        }}
        transition={true}
      >
        <Box
          onMouseLeave={() => {
            if (setOpen) {
              setOpen(false)
            }
            if (setCircleHover) {
              setCircleHover(false)
            }
          }}
          className={style}
        >
          {children}
        </Box>
      </Popper>
    </div>
  )
}
