import {
  Box,
  Divider,
  FormControlLabel,
  ListItem,
  Modal,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from '@mui/material'
import styles from './ModalTemplate.module.css'
import { useTranslation } from 'react-i18next'
import { Form } from '../../../modules/forms/models/Form'
import { ProfessionalType } from '../../../modules/users/models/ProfessionalType'
import React, { useState } from 'react'
import { AppButton, ButtonTheme } from '../../../components/app-button/AppButton'
import icoDelete from '../../../assets/resource_icons/ico-eliminar.svg'
import { getUserContainer } from '../../../container/user-module'
import { ILoggedUserService } from '../../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../../modules/users'
import { styled } from '@mui/system'
import { Theme } from '@mui/material/styles'
import { FormType } from '../../../modules/forms/enums/FormType'

type ModalTemplateProps = {
  open: boolean
  handleClose: () => void
  templates: Form[]
  professionalType: ProfessionalType | undefined
  handlerShowAllTemplates: () => void
  showAllTemplates: boolean
  professionalSpecialities: ProfessionalType[]
  handlerProfessionalTypeSelected: (professionalType: ProfessionalType) => void
  professionalTypeSelected: ProfessionalType | undefined
  editTemplate: (id: string) => void
}

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

export function ModalTemplate({
  open,
  handleClose,
  templates,
  professionalType,
  handlerShowAllTemplates,
  showAllTemplates,
  professionalSpecialities,
  handlerProfessionalTypeSelected,
  editTemplate,
}: ModalTemplateProps) {
  const loggedUser = loggedUserService.get()
  const { t } = useTranslation()
  const [backGround, setBackground] = useState<number>(-1)
  const [templateSelected, setTemplateSelected] = useState<string>('')

  // Boolean to find out if the user is a extern professional
  const isExtern = loggedUser?.roles?.some((role) => role === 'externProfessional')

  const handlerSelectTemplate = (index: number, professionalType: ProfessionalType) => {
    setBackground(index)
    handlerProfessionalTypeSelected(professionalType)
  }

  const handleRadioButtons = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTemplateSelected(event.target.value)
  }

  const CustomSwitch = styled(Switch)(({ theme }: { theme?: Theme }) => ({
    width: 54,
    height: 26,
    padding: 0,
    margin: theme?.spacing(2),
    '.MuiSwitch-switchBase': {
      padding: 1,
      '&.Mui-checked': {
        transform: 'translateX(28px)',
        color: theme?.palette.common.white,
        '& + .MuiSwitch-track': {
          backgroundColor: '#f37e31',
          opacity: 1,
          border: 'none',
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#f37e31',
        border: '6px solid #fff',
      },
    },
    '.MuiSwitch-thumb': {
      width: 24,
      height: 24,
    },
    '.MuiSwitch-track': {
      borderRadius: 52 / 2,
      border: `1px solid #efefef`,
      backgroundColor: '#d1d1d1',
      opacity: 1,
      transition: theme?.transitions.create(['background-color', 'border']),
    },
  }))

  return (
    <Modal
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      open={open}
      onClose={handleClose}
    >
      <Box className={styles.container}>
        <Box className={styles.header}>
          <Typography className={styles.title} variant="h5" component="h2">
            {t('templates')}
          </Typography>
          <img
            style={{ cursor: 'pointer', marginRight: 14, width: 30 }}
            onClick={handleClose}
            src={icoDelete}
            alt={'closemodal'}
          />
        </Box>
        <Divider className={styles.divider} color={'#b2b2b2'} />
        <Box className={styles.content}>
          <Box className={styles.boxAllTemplates}>
            {isExtern ? (
              professionalSpecialities.map((professionalType, index) => (
                <Box
                  className={
                    backGround === index ? styles.buttonTemplateSelected : styles.buttonTemplate
                  }
                  key={professionalType.id}
                  onClick={() => {
                    handlerSelectTemplate(index, professionalType)
                  }}
                >
                  <Typography
                    className={
                      backGround === index ? styles.textTemplateSelected : styles.textTemplate
                    }
                  >
                    {professionalType.name}
                  </Typography>
                </Box>
              ))
            ) : (
              <>
                <Box className={styles.containerSwitch}>
                  <Typography className={styles.textAllTemplates}>{t('allTemplates')}</Typography>
                  <CustomSwitch checked={showAllTemplates} onChange={handlerShowAllTemplates} />
                </Box>
                <Box className={styles.boxTemplates}>
                  {showAllTemplates ? (
                    professionalSpecialities.map((professionalType, index) => (
                      <Box
                        className={
                          backGround === index
                            ? styles.buttonTemplateSelected
                            : styles.buttonTemplate
                        }
                        key={professionalType.id}
                        onClick={() => {
                          handlerSelectTemplate(index, professionalType)
                        }}
                      >
                        <Typography
                          className={
                            backGround === index ? styles.textTemplateSelected : styles.textTemplate
                          }
                        >
                          {professionalType.name}
                        </Typography>
                      </Box>
                    ))
                  ) : (
                    <>
                      {professionalType?.name ? (
                        <Box className={styles.buttonTemplateSelected} key={professionalType?.id}>
                          <Typography className={styles.textTemplateSelected}>
                            {professionalType?.name}
                          </Typography>
                        </Box>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </Box>
              </>
            )}
          </Box>
          <Divider orientation={'vertical'} flexItem color={'#b2b2b2'} />
          <Box style={{ width: '100%', height: 'auto', minHeight: '300px' }}>
            <Box
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Box className={styles.scrollableBox}>
                {templates.map(
                  (template, index) =>
                    template.isTemplate === FormType.Template && (
                      <Box className={styles.boxTemplate} key={template.id}>
                        <RadioGroup
                          key="radioButtonDay"
                          row
                          name="radioButtonDay"
                          onChange={handleRadioButtons}
                        >
                          <ListItem>
                            <FormControlLabel
                              name="buttonDay"
                              value={template.id}
                              checked={templateSelected === templates[index].id}
                              control={<Radio />}
                              label={
                                <span style={{ fontFamily: 'Poppins' }}>{template.title}</span>
                              }
                            />
                          </ListItem>
                        </RadioGroup>
                      </Box>
                    )
                )}
              </Box>
              {templates.length > 0 && (
                <Box style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px' }}>
                  <AppButton
                    theme={ButtonTheme.NewPrimary}
                    handler={() => editTemplate(templateSelected)}
                    type={'button'}
                    label={t('next')}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
