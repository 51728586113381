import { ArticleCategory } from '../enums/ArticleCategory'
import { LangType } from '../enums/LangType'

export type ArticleProps = {
  id?: string
  category: ArticleCategory
  lang: LangType
  title: string
  content: string
  files: string[]
  metaTitle: string
  metaDescription: string
  ogTitle: string
  ogDescription: string
  ogImage: string[]
  slug: string // unique
  createdAt: Date
  updatedAt: Date
  tags: string[]
}

export class Article {
  private readonly _id: string | undefined
  private readonly _createdAt: Date

  private _category: ArticleCategory
  private _lang: LangType
  private _title: string
  private _content: string
  private _files: string[]
  private _metaTitle: string
  private _metaDescription: string
  private _ogTitle: string
  private _ogDescription: string
  private _ogImage: string[]
  private _slug: string
  private _updatedAt: Date
  private _tags: string[]

  constructor(props: ArticleProps) {
    this._id = props.id
    this._createdAt = props.createdAt
    this._category = props.category
    this._lang = props.lang
    this._title = props.title
    this._content = props.content
    this._files = props.files
    this._metaTitle = props.metaTitle
    this._metaDescription = props.metaDescription
    this._ogTitle = props.ogTitle
    this._ogDescription = props.ogDescription
    this._ogImage = props.ogImage
    this._slug = props.slug
    this._updatedAt = props.updatedAt
    this._tags = props.tags
  }

  get id(): string | undefined {
    return this._id
  }

  get createdAt(): Date {
    return this._createdAt
  }

  get title(): string {
    return this._title
  }

  set title(value: string) {
    this._title = value
  }

  get content(): string {
    return this._content
  }

  set content(value: string) {
    this._content = value
  }

  get category(): ArticleCategory {
    return this._category
  }

  set category(value: ArticleCategory) {
    this._category = value
  }

  get lang(): LangType {
    return this._lang
  }

  set lang(value: LangType) {
    this._lang = value
  }

  get files(): string[] {
    if (!this._files) return []
    return [...this._files]
  }

  addFile(value: string) {
    this._files.push(value)
  }

  removeFile(value: string) {
    this._files = this._files.filter((f) => f !== value)
  }

  get metaTitle(): string {
    return this._metaTitle
  }

  set metaTitle(value: string) {
    this._metaTitle = value
  }

  get metaDescription(): string {
    return this._metaDescription
  }

  set metaDescription(value: string) {
    this._metaDescription = value
  }

  get ogTitle(): string {
    return this._ogTitle
  }

  set ogTitle(value: string) {
    this._ogTitle = value
  }

  get ogDescription(): string {
    return this._ogDescription
  }

  set ogDescription(value: string) {
    this._ogDescription = value
  }

  get ogImage(): string[] {
    return this._ogImage
  }

  addOgImage(value: string) {
    this._ogImage.push(value)
  }

  removeOgImage(value: string) {
    this._ogImage = this._ogImage.filter((i) => i !== value)
  }

  get slug(): string {
    return this._slug
  }

  set slug(value: string) {
    this._slug = value
  }

  get updatedAt(): Date {
    return this._updatedAt
  }

  set updatedAt(value: Date) {
    this._updatedAt = value
  }

  get tags(): string[] {
    if (!this._tags) return []
    return [...this._tags]
  }

  set tags(value: string[]) {
    this._tags = value
  }

  addTag(value: string) {
    this._tags.push(value)
  }

  removeTag(tagID: string) {
    this._tags = this._tags.filter((t) => t !== tagID)
  }
}
