import React, { ReactNode, useMemo } from 'react'
import { getUserCircleContainer } from 'container/user-circle-module'
import { IUserCircleActiveService, USER_CIRCLE_ACTIVE_SERVICE_KEY } from 'modules/user-circle'

type NewCircleContextProviderProps = {
  children: ReactNode
}
export type CircleContextProps = {
  userCircleService: IUserCircleActiveService | undefined
}

// user selected when clicking
const userCircleService = getUserCircleContainer().get<IUserCircleActiveService>(
  USER_CIRCLE_ACTIVE_SERVICE_KEY
)

export const NewCircleConfigContext = React.createContext<CircleContextProps>({
  userCircleService: undefined,
})

export const NewCircleContextProvider: React.FC<NewCircleContextProviderProps> = ({ children }) => {
  const circleConfigValue = useMemo(() => ({ userCircleService }), [])
  return (
    <NewCircleConfigContext.Provider value={circleConfigValue}>
      {children}
    </NewCircleConfigContext.Provider>
  )
}

// custom hook creation for the momment, move later on

export const useCircleConfiguration = () => {
  const circleContext = React.useContext(NewCircleConfigContext)
  return circleContext
}
