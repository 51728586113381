import React, { FormEvent, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import style from './Modal.module.css'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { v4 as uuidv4 } from 'uuid'
import close from '../../assets/table_icons/ico-eliminar.svg'
import { Header } from '../../components/header/Header'
import { ItemList } from '../../common/models/ItemList'
import { Vaccine } from '../../modules/vaccines/models/Vaccines'
import { FormCard } from '../../components/form-card/FormCard'
import { Box, Container, Grid, TextField, Modal as M } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'

type PatientCalendarConfigEvent = {
  id: string
  month: number
  title: string
}

type OpenPatientCalendarConfigModal = {
  open: boolean
  patientCalendarConfigEvent?: PatientCalendarConfigEvent
}

type CalendarConfigModalProps = {
  openCalendarConfigEvent: OpenPatientCalendarConfigModal
  handleOnClose: () => void
  setEvent: (configEvent: PatientCalendarConfigEvent) => void
  vaccines?: ItemList<Vaccine>
}

type AutocompleteVaccineInterface = {
  key: number
  value: string
}
export const Modal = (props: CalendarConfigModalProps) => {
  const { t } = useTranslation()
  const [vaccines, setVaccines] = useState<Map<number, AutocompleteVaccineInterface>>(new Map())
  const [selectedVaccines, setSelectedVaccines] = useState<AutocompleteVaccineInterface[]>([])
  const [currentVaccine, setCurrentVaccine] = useState<AutocompleteVaccineInterface>()

  const [id, setId] = useState<string>(
    props.openCalendarConfigEvent.patientCalendarConfigEvent
      ? props.openCalendarConfigEvent.patientCalendarConfigEvent.id
      : uuidv4()
  )
  const [month, setMonth] = useState<number>(
    props.openCalendarConfigEvent.patientCalendarConfigEvent
      ? props.openCalendarConfigEvent.patientCalendarConfigEvent.month % 12
      : 0
  )
  const [year, setYear] = useState<number>(
    props.openCalendarConfigEvent.patientCalendarConfigEvent
      ? Math.floor(props.openCalendarConfigEvent.patientCalendarConfigEvent.month / 12)
      : 0
  )
  let [title, setTitle] = useState<string>(
    props.openCalendarConfigEvent.patientCalendarConfigEvent
      ? props.openCalendarConfigEvent.patientCalendarConfigEvent.title
      : ''
  )

  useEffect(() => {
    const ul: AutocompleteVaccineInterface[] = []

    if (props.vaccines) {
      const tmpMap = new Map()
      props.vaccines.items.forEach((e: any) => {
        tmpMap.set(e.tvac, { key: e.tvac, value: e.name })
        setVaccines(new Map(tmpMap))
        ul.push({ key: e.tvac, value: e.name })
        setSelectedVaccines([...ul].sort((a, b) => a.value.localeCompare(b.value)))
      })
      setCurrentVaccine(selectedVaccines[0])
    }
  }, [])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.id === 'month') {
      setMonth(+event.target.value)
    } else if (event.target.id === 'year') {
      setYear(+event.target.value)
    } else {
      setTitle(event.target.value)
    }
  }

  const handleSave = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (props.vaccines) {
      if (currentVaccine) {
        title = currentVaccine.value
      }
    }

    props.setEvent({
      id,
      month: month + year * 12,
      title,
    })
    setId('')
    setMonth(0)
    setYear(0)
    setTitle('')
    props.handleOnClose()
  }

  const handleSelectableVaccines = (value: any) => {
    setCurrentVaccine(Object.assign({ ...currentVaccine }, { key: value.key, value: value.value }))
  }

  return (
    <>
      <M open={props.openCalendarConfigEvent.open}>
        <Container component={'div'} maxWidth={'sm'}>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh' }}
          >
            <form onSubmit={handleSave}>
              <Box className={style.modalView} component={'div'}>
                <Header label={t('eventInformation')} icon={close} onClick={props.handleOnClose} />
                <Box ml={2} className={style.modalBox}>
                  <Box mb={2} component={'div'}>
                    <TextField
                      id={'month'}
                      key={'month'}
                      name={'month'}
                      type={'number'}
                      value={month}
                      inputProps={{ min: '0', max: '11' }}
                      fullWidth
                      label={t('month')}
                      variant={'outlined'}
                      onChange={handleChange}
                    />
                  </Box>
                  <Box mb={2} component={'div'}>
                    <TextField
                      id={'year'}
                      key={'year'}
                      name={'year'}
                      type={'number'}
                      value={year}
                      inputProps={{ min: '0', max: '160' }}
                      fullWidth
                      label={t('year')}
                      variant={'outlined'}
                      onChange={handleChange}
                    />
                  </Box>
                  {props.vaccines && (
                    <FormCard>
                      <Box>
                        <Autocomplete
                          id={'vaccine'}
                          key={'vaccine'}
                          style={{ width: '100%', marginBottom: '5%' }}
                          options={Array.from(vaccines.values()).filter(
                            (u) => !selectedVaccines.includes(u)
                          )}
                          getOptionLabel={(opt) => t(opt.value)}
                          value={currentVaccine}
                          onChange={(_, v) => handleSelectableVaccines(v)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant={'outlined'}
                              label={t('vaccine')}
                              helperText={t('requiredField')}
                              required
                            />
                          )}
                          noOptionsText={t('noElementsAvailable')}
                          size={'small'}
                          disableClearable
                        />
                      </Box>
                    </FormCard>
                  )}
                  {!props.vaccines && (
                    <Box mb={2} component={'div'}>
                      <TextField
                        id={'title'}
                        key={'title'}
                        name={'title'}
                        value={title}
                        fullWidth
                        label={t('title')}
                        variant={'outlined'}
                        onChange={handleChange}
                        required
                      />
                    </Box>
                  )}
                </Box>
                <Box justifyContent={'center'} display={'flex'}>
                  <AppButton
                    theme={ButtonTheme.NewPrimary}
                    handler={() => {}}
                    type={'submit'}
                    label={t('save')}
                  />
                </Box>
              </Box>
            </form>
          </Grid>
        </Container>
      </M>
    </>
  )
}
