export enum SourceType {
  CalendarEventCreated = 1,
  CalendarEventUpdated = 2,
  CalendarNotification = 10,
  MessengerMessageCreated = 51,
  QueryMessageCreated = 52,
  FormsNewUserForm = 101,
  FormsUserFormFilled = 102,
  FilesNewCreated = 301,
  ContentNewArticle = 401,
  PatientSymptomCreated = 501,
  TreatmentCreated = 601,
  NewRegisteredUser = 702,
}

export const sourceTypes = (): Record<SourceType, string> => ({
  [SourceType.CalendarEventCreated]: 'New Event',
  [SourceType.CalendarEventUpdated]: 'Event Updated',
  [SourceType.CalendarNotification]: 'Event Notification',
  [SourceType.MessengerMessageCreated]: 'New Message',
  [SourceType.QueryMessageCreated]: 'New Query Message',
  [SourceType.FormsNewUserForm]: 'Form Pending',
  [SourceType.FormsUserFormFilled]: 'Form Filled',
  [SourceType.FilesNewCreated]: 'A new resource was created',
  [SourceType.ContentNewArticle]: 'A new article was created',
  [SourceType.PatientSymptomCreated]: 'A new patient symptom was created',
  [SourceType.TreatmentCreated]: 'A new treatment was created',
  [SourceType.NewRegisteredUser]: 'A new user has been registered',
})
