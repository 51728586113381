import { EventCategory } from '../../calendar/enums/EventCategory'
import { PatientCalendarEvent } from './PatientCalendarEvent'

export type PatientCalendarProps = {
  id?: string
  title: string
  year: number
  pathologyID: string
  category: EventCategory
  events: PatientCalendarEvent[]
}

export class PatientCalendar {
  private readonly _id: string | undefined
  private _title: string
  private _year: number
  private _pathologyID: string
  private _category: EventCategory
  private _events: PatientCalendarEvent[]

  constructor(p: PatientCalendarProps) {
    this._id = p.id
    this._title = p.title
    this._year = p.year
    this._pathologyID = p.pathologyID
    this._category = p.category
    this._events = p.events
  }

  get id(): string | undefined {
    return this._id
  }

  get title(): string {
    return this._title
  }

  set title(t: string) {
    this._title = t
  }

  get year(): number {
    return this._year
  }

  set year(y: number) {
    this._year = y
  }

  get pathologyID(): string {
    return this._pathologyID
  }

  set pathologyID(p: string) {
    this._pathologyID = p
  }

  get category(): EventCategory {
    return this._category
  }

  set category(c: EventCategory) {
    this._category = c
  }

  get events(): PatientCalendarEvent[] {
    return this._events
  }

  set events(value: PatientCalendarEvent[]) {
    this._events = value
  }
}

export interface PatientCalendarQuery {
  year: number
}
