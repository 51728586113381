import { Container } from '../../common/container/Container'

export type MessengerContainerConfig = {
  moduleFullUrl: string
}

export type MessengerProps = {
  parentContainer: Container
  config: MessengerContainerConfig
}

export const MESSENGER_MODULE = Symbol('MESSENGER_MODULE')

export const CONTACT_API_KEY = Symbol('CONTACT_API_KEY')
export const CONVERSATION_API_KEY = Symbol('CONVERSATION_API_KEY')

export const CONTACT_SERVICE_KEY = Symbol('CONTACT_SERVICE_KEY')
export const CONVERSATION_SERVICE_KEY = Symbol('CONVERSATION_SERVICE_KEY')
