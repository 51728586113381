import { Treatments as T } from '../../features/patient-data'
import { RouteProps } from '../../routes/AppRouter'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'

export function Treatments(props: RouteProps) {
  const title = props.title ?? ''

  return (
    <>
      <CurrentNavHeaderEsfera title={title} />
      <T />
    </>
  )
}
