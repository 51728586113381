import { ICircleService, CIRCLE_SERVICE_KEY } from '../../../../modules/circle'
import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Divider } from '@mui/material'
import {
  ROUTE_ADD_NEW_CIRCLE,
  ROUTE_CIRCLE_CONFIGURATOR,
  ROUTE_ADD_NEW_CIRCLE_RESOURCES_CONFIGURATOR,
} from '../../../../routes/routes-constants'
import { useNavigate } from 'react-router-dom'
import genericStyle from '../../../../common/utils/generic.module.css'
import { AppButton, ButtonTheme } from '../../../../components/app-button/AppButton'
import style from '../AddNewCircle.module.css'
import { getCircleContainer } from '../../../../container/circle-module'
import { TextFieldItem } from '../../../../components/form-card/TextFieldItem'
import { Header } from '../../../../components/header/Header'
import { CircleDTO, emptyCircleDTO, fromModel } from '../../../../modules/circle/models/CircleDTO'
import styles from '../../../user-profile/Editor.module.css'
import CustomStepper from '../CustomStepper'
import { steps } from '../Steps'

const circleService = getCircleContainer().get<ICircleService>(CIRCLE_SERVICE_KEY)

export function AddNewCircleConfigurator() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [circleDraft, setCircleDraft] = useState<CircleDTO>(emptyCircleDTO())
  // get circleDraftId from local storage
  const circleDraftId = localStorage.getItem('circleDraftID')

  useEffect(() => {
    if (circleDraftId) {
      circleService.getByCircleDraftID(circleDraftId).subscribe((circle) => {
        if (!circle) {
          navigate(ROUTE_CIRCLE_CONFIGURATOR)
          return
        }
        setCircleDraft(fromModel(circle))
      })
    }
  }, [])

  const handleMenuConfig = (e: ChangeEvent<HTMLInputElement>) => {
    setCircleDraft({
      ...circleDraft,
      menuConfig: { ...circleDraft.menuConfig, [e.target.name]: e.target.checked },
    })
  }

  const handleCalendarConfig = (e: ChangeEvent<HTMLInputElement>) => {
    setCircleDraft({
      ...circleDraft,
      calendarConfig: { ...circleDraft.calendarConfig, [e.target.name]: e.target.checked },
    })
  }

  const handlePatientConfig = (e: ChangeEvent<HTMLInputElement>) => {
    switch (e.target.name) {
      case 'medicalData':
        setCircleDraft({
          ...circleDraft,
          patientConfig: { ...circleDraft.patientConfig, patientChart: e.target.checked },
        })
        break
      case 'medicalDataHealthyChild':
        setCircleDraft({
          ...circleDraft,
          patientConfig: { ...circleDraft.patientConfig, healthyChildChart: e.target.checked },
        })
        break
      case 'activePlan':
        setCircleDraft({
          ...circleDraft,
          patientConfig: { ...circleDraft.patientConfig, activePlan: e.target.checked },
        })
        break
      case 'evolutionControl':
        setCircleDraft({
          ...circleDraft,
          patientConfig: { ...circleDraft.patientConfig, evolutionaryControl: e.target.checked },
        })
        break
      case 'symptoms':
        setCircleDraft({
          ...circleDraft,
          patientConfig: { ...circleDraft.patientConfig, symptoms: e.target.checked },
        })
        break
      case 'treatments':
        setCircleDraft({
          ...circleDraft,
          patientConfig: { ...circleDraft.patientConfig, treatments: e.target.checked },
        })
        break
      case 'vaccinationSchedule':
        setCircleDraft({
          ...circleDraft,
          patientConfig: { ...circleDraft.patientConfig, vacCalendar: e.target.checked },
        })
        break
      case 'oralHealth':
        setCircleDraft({
          ...circleDraft,
          patientConfig: { ...circleDraft.patientConfig, oralHealth: e.target.checked },
        })
        break
      case 'wearables':
        setCircleDraft({
          ...circleDraft,
          patientConfig: { ...circleDraft.patientConfig, wearables: e.target.checked },
        })
        break
    }
  }

  const handleQueryConfig = (e: ChangeEvent<HTMLInputElement>) => {
    switch (e.target.name) {
      case 'teleCall':
        setCircleDraft({
          ...circleDraft,
          queryConfig: { ...circleDraft.queryConfig, teleCall: e.target.checked, videoCall: false },
        })
        break
      case 'videoCall':
        setCircleDraft({
          ...circleDraft,
          queryConfig: { ...circleDraft.queryConfig, videoCall: e.target.checked, teleCall: false },
        })
        break
      case 'yes':
        setCircleDraft({
          ...circleDraft,
          queryConfig: { ...circleDraft.queryConfig, acceptQuery: true },
        })
        break
      case 'no':
        setCircleDraft({
          ...circleDraft,
          queryConfig: { ...circleDraft.queryConfig, acceptQuery: false },
        })
        break
    }
  }

  const toggleServiceNotAvailableTextField = circleDraft.queryConfig.acceptQuery

  const updateDraft = (): boolean => {
    if (!circleDraft) return false

    circleService.updateCircleDraft(circleDraft).subscribe((res) => {})
    return true
  }

  const goPreviousStep = () =>
    updateDraft() && navigate(ROUTE_ADD_NEW_CIRCLE, { state: { circleDraftID: circleDraftId } })

  const goNextStep = () => updateDraft() && navigate(ROUTE_ADD_NEW_CIRCLE_RESOURCES_CONFIGURATOR)

  const saveDraft = () => updateDraft() && navigate(ROUTE_CIRCLE_CONFIGURATOR)

  return (
    <>
      <Box className={genericStyle.pageContainer}>
        <Header label={t('circleConfiguration')} />
        <Box>
          <CustomStepper steps={steps} activeStep={1} />
        </Box>

        <Box>
          <Box>
            <p
              style={{
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 'bold',
                fontSize: window.innerWidth > 1220 ? '1.2rem' : '0.9rem',
                marginLeft: 0,
                width: '100%',
              }}
            >
              {t('menuConfigurator')}
            </p>
            <Divider
              light
              style={{ backgroundColor: '#68b3e0', height: 4, marginBottom: '30px' }}
            />
          </Box>

          <Box display="flex" style={{ backgroundColor: '#fff' }} className={style.containerInput}>
            <Box className={style.columnContainer}>
              <label className={style.checkboxContainer}>
                <input
                  type="checkbox"
                  name="wall"
                  className={styles.check}
                  checked={circleDraft.menuConfig.wall}
                  disabled={false}
                  onChange={(e) => handleMenuConfig(e)}
                />
                {t('Dashboard')}
              </label>
              <label className={style.checkboxContainer}>
                <input
                  type="checkbox"
                  name="patientData"
                  className={styles.check}
                  checked={circleDraft.menuConfig.patientData}
                  disabled={false}
                  onChange={(e) => handleMenuConfig(e)}
                />
                {t('patientData')}
              </label>
            </Box>
            <Box className={style.columnContainer}>
              <label className={style.checkboxContainer}>
                <input
                  type="checkbox"
                  name="participant"
                  className={styles.check}
                  checked={circleDraft.menuConfig.participant}
                  onChange={(e) => handleMenuConfig(e)}
                />
                {t('participants')}
              </label>
              <label className={style.checkboxContainer}>
                <input
                  type="checkbox"
                  name="chats"
                  className={styles.check}
                  checked={circleDraft.menuConfig.chats}
                  onChange={(e) => handleMenuConfig(e)}
                />
                {t('Chats')}
              </label>
            </Box>
            <Box className={style.columnContainer}>
              <label className={style.checkboxContainer}>
                <input
                  type="checkbox"
                  name="querys"
                  className={styles.check}
                  checked={circleDraft.menuConfig.querys}
                  onChange={(e) => handleMenuConfig(e)}
                />
                {t('Consultations')}
              </label>
              <label className={style.checkboxContainer}>
                <input
                  type="checkbox"
                  name="calendar"
                  className={styles.check}
                  checked={circleDraft.menuConfig.calendar}
                  onChange={(e) => handleMenuConfig(e)}
                />
                {t('Calendars')}
              </label>
            </Box>
          </Box>
        </Box>

        <Box>
          <Box>
            <p
              style={{
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 'bold',
                fontSize: window.innerWidth > 1220 ? '1.2rem' : '0.9rem',
                marginLeft: 0,
                width: '100%',
              }}
            >
              {t('calendarModuleConfigurator')}
            </p>
            <Divider
              light
              style={{ backgroundColor: '#68b3e0', height: 4, marginBottom: '30px' }}
            />
          </Box>
          <span style={{ fontWeight: 'bold', color: '#68b3e0' }}>{t('visualisation')}</span>
          <Box display="flex" style={{ backgroundColor: '#fff' }} className={style.containerInput}>
            <label className={style.checkboxContainer}>
              <input
                type="checkbox"
                name="monthly"
                className={styles.check}
                checked={circleDraft.calendarConfig.monthly}
                onChange={handleCalendarConfig}
              />
              {t('Monthly')}
            </label>
            <label className={style.checkboxContainer}>
              <input
                type="checkbox"
                name="weekly"
                className={styles.check}
                checked={circleDraft.calendarConfig.weekly}
                onChange={handleCalendarConfig}
              />
              {t('Weekly')}
            </label>
            <label className={style.checkboxContainer}>
              <input
                type="checkbox"
                name="diary"
                className={styles.check}
                checked={circleDraft.calendarConfig.diary}
                onChange={handleCalendarConfig}
              />
              {t('Daily')}
            </label>
          </Box>
          <span style={{ fontWeight: 'bold', color: '#68b3e0' }}>{t('categories')}</span>

          <Box className={style.containerInput}>
            <Box className={style.columnContainer}>
              <label className={style.checkboxContainer}>
                <input
                  type="checkbox"
                  name="vaccines"
                  className={styles.check}
                  checked={circleDraft.calendarConfig.vaccines}
                  onChange={handleCalendarConfig}
                />
                {t('vaccines')}
              </label>
              <label className={style.checkboxContainer}>
                <input
                  type="checkbox"
                  name="symptoms"
                  className={styles.check}
                  checked={circleDraft.calendarConfig.symptoms}
                  onChange={handleCalendarConfig}
                />
                {t('symptoms')}
              </label>
            </Box>
            <Box className={style.columnContainer}>
              <label className={style.checkboxContainer}>
                <input
                  type="checkbox"
                  name="appointments"
                  className={styles.check}
                  checked={circleDraft.calendarConfig.appointments}
                  onChange={handleCalendarConfig}
                />
                {t('medicalappointment')}
              </label>
              <label className={style.checkboxContainer}>
                <input
                  type="checkbox"
                  name="treatments"
                  className={styles.check}
                  onChange={handleCalendarConfig}
                  checked={circleDraft.calendarConfig.treatments}
                />
                {t('treatments')}
              </label>
            </Box>
            <Box className={style.columnContainer}>
              <label className={style.checkboxContainer}>
                <input
                  type="checkbox"
                  name="others"
                  className={styles.check}
                  onChange={handleCalendarConfig}
                  checked={circleDraft.calendarConfig.others}
                />
                {t('others')}
              </label>
            </Box>
          </Box>
        </Box>

        <Box>
          <Box>
            <p
              style={{
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 'bold',
                fontSize: window.innerWidth > 1220 ? '1.2rem' : '0.9rem',
                marginLeft: 0,
                width: '100%',
              }}
            >
              {t('patientDataModuleConfigurator')}
            </p>
            <Divider
              light
              style={{ backgroundColor: '#68b3e0', marginTop: 8, height: 4, marginBottom: '30px' }}
            />
          </Box>
          <Box display="flex" style={{ backgroundColor: '#fff' }} className={style.containerInput}>
            <Box className={style.columnContainer}>
              <label className={style.checkboxContainer}>
                <input
                  type="checkbox"
                  name="medicalData"
                  onChange={handlePatientConfig}
                  checked={circleDraft.patientConfig.patientChart}
                  className={styles.check}
                />
                {t('medicalData')}
              </label>
              <label className={style.checkboxContainer}>
                <input
                  type="checkbox"
                  name="medicalDataHealthyChild"
                  className={styles.check}
                  onChange={handlePatientConfig}
                  checked={circleDraft.patientConfig.healthyChildChart}
                />
                {t('medicalDataHealthyChild')}
              </label>
              <label className={style.checkboxContainer}>
                <input
                  type="checkbox"
                  name="activePlan"
                  className={styles.check}
                  onChange={handlePatientConfig}
                  checked={circleDraft.patientConfig.activePlan}
                />
                {t('activePlan')}
              </label>
            </Box>
            <Box className={style.columnContainer}>
              <label className={style.checkboxContainer}>
                <input
                  type="checkbox"
                  name="evolutionControl"
                  className={styles.check}
                  onChange={handlePatientConfig}
                  checked={circleDraft.patientConfig.evolutionaryControl}
                />
                {t('evolutionControl')}
              </label>
              <label className={style.checkboxContainer}>
                <input
                  type="checkbox"
                  name="symptoms"
                  className={styles.check}
                  onChange={handlePatientConfig}
                  checked={circleDraft.patientConfig.symptoms}
                />
                {t('symptoms')}
              </label>
              <label className={style.checkboxContainer}>
                <input
                  type="checkbox"
                  name="treatments"
                  className={styles.check}
                  onChange={handlePatientConfig}
                  checked={circleDraft.patientConfig.treatments}
                />
                {t('treatments')}
              </label>
            </Box>
            <Box className={style.columnContainer}>
              <label className={style.checkboxContainer}>
                <input
                  type="checkbox"
                  name="vaccinationSchedule"
                  className={styles.check}
                  onChange={handlePatientConfig}
                  checked={circleDraft.patientConfig.vacCalendar}
                />
                {t('vaccinationSchedule')}
              </label>
              <label className={style.checkboxContainer}>
                <input
                  type="checkbox"
                  name="oralHealth"
                  className={styles.check}
                  onChange={handlePatientConfig}
                  checked={circleDraft.patientConfig.oralHealth}
                />
                {t('oralHealth')}
              </label>
              <label className={style.checkboxContainer}>
                <input
                  type="checkbox"
                  name="wearables"
                  value="no"
                  className={styles.check}
                  onChange={handlePatientConfig}
                  checked={circleDraft.patientConfig.wearables}
                />
                {t('wearables')}
              </label>
            </Box>
          </Box>
        </Box>

        <Box>
          <Box>
            <p
              style={{
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 'bold',
                fontSize: window.innerWidth > 1220 ? '1.2rem' : '0.9rem',
                marginLeft: 0,
                width: '100%',
              }}
            >
              {t('queryModuleConfigurator')}
            </p>
            <Divider
              light
              style={{ backgroundColor: '#68b3e0', marginTop: 8, height: 4, marginBottom: '30px' }}
            />
          </Box>
          <span style={{ fontWeight: 'bold', color: '#68b3e0' }}>{t('typeOfQuery')}</span>
          <Box display="flex" style={{ backgroundColor: '#fff' }} className={style.containerInput}>
            <label className={style.checkboxContainer}>
              <input
                type="radio"
                name="teleCall"
                className={styles.check}
                disabled={false}
                onChange={(e) => handleQueryConfig(e)}
                checked={circleDraft.queryConfig.teleCall}
              />
              {t('Teleconsult')}
            </label>
            <label className={style.checkboxContainer}>
              <input
                type="radio"
                name="videoCall"
                className={styles.check}
                disabled={false}
                onChange={(e) => handleQueryConfig(e)}
                checked={circleDraft.queryConfig.videoCall}
              />
              {t('videoCall')}
            </label>
            <label className={`${style.checkboxContainer} ${style.invisibleCheckbox}`}>
              <input type="radio" />
            </label>
          </Box>
          <span style={{ fontWeight: 'bold', color: '#68b3e0' }}>
            {t('acceptQuerysAtTheMoment')}
          </span>
          <Box display="flex" style={{ backgroundColor: '#fff' }} className={style.containerInput}>
            <label className={style.checkboxContainer}>
              <input
                type="radio"
                name="yes"
                className={styles.check}
                disabled={false}
                onChange={(e) => handleQueryConfig(e)}
                checked={circleDraft.queryConfig.acceptQuery}
              />
              {t('yes')}
            </label>
            <label className={style.checkboxContainer}>
              <input
                type="radio"
                name="no"
                className={styles.check}
                onChange={(e) => handleQueryConfig(e)}
                checked={!circleDraft.queryConfig.acceptQuery}
              />
              {t('no')}
            </label>
            <label className={`${style.checkboxContainer} ${style.invisibleCheckbox}`}>
              <input type="radio" />
            </label>
          </Box>
          <Box>
            {toggleServiceNotAvailableTextField ? (
              ''
            ) : (
              <>
                <span style={{ fontWeight: 'bold', color: '#68b3e0' }}>
                  {t('messageConfigurationCircleConfigurator')}
                </span>
                <Box display="flex">
                  <TextFieldItem
                    sx={{ margin: '0' }}
                    field={''}
                    value={circleDraft.queryConfig.noAvailableMessage}
                    handleChange={(e) => {
                      setCircleDraft({
                        ...circleDraft,
                        queryConfig: {
                          ...circleDraft.queryConfig,
                          noAvailableMessage: e.target.value,
                        },
                      })
                    }}
                    label={t('')}
                    rows={6}
                    required={true}
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>

        <Box display="flex" style={{ justifyContent: 'space-between' }}>
          <AppButton
            theme={ButtonTheme.whiteAndBlue}
            type={'button'}
            label={t('saveDraft')}
            marginStartIcon={{ marginRight: '10px' }}
            handler={saveDraft}
            vertical={true}
          />{' '}
          <Box style={{ display: 'flex' }}>
            <Box style={{ marginRight: '20px' }}>
              <AppButton
                theme={ButtonTheme.newEvent}
                type={'button'}
                label={t('backOneToResources')}
                marginStartIcon={{ marginRight: '10px' }}
                handler={goPreviousStep}
                vertical={true}
              />
            </Box>
            <Box>
              <AppButton
                theme={ButtonTheme.newEvent}
                type={'button'}
                label={t('saveAndNext')}
                marginStartIcon={{ marginRight: '10px' }}
                handler={goNextStep}
                vertical={true}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
