import { FormCard } from '../../components/form-card/FormCard'
import React, { FormEvent, useEffect, useState } from 'react'
import { emptyFileDTO, FileDTO, fromModel } from '../../modules/files/models/FileDTO'
import { TextFieldItem } from '../../components/form-card/TextFieldItem'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { ROUTE_RESOURCES } from '../../routes/routes-constants'
import { useTranslation } from 'react-i18next'
import { Query } from '../../common/api/Query'
import { User } from '../../modules/users/models/User'
import { getUserContainer } from '../../container/user-module'
import { ICircleService } from '../../modules/users/services/CircleService'
import {
  CIRCLE_SERVICE_KEY,
  LOGGED_USER_SERVICE_KEY,
  PROFESSIONALTYPE_SERVICE_KEY,
  ROLES_SERVICE_KEY,
  USER_SERVICE_KEY,
} from '../../modules/users/container'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { IUserService } from '../../modules/users'
import { getFileContainer } from '../../container/file-module'
import { FileService } from '../../modules/files/services/FileService'
import { FILE_SERVICE_KEY } from '../../modules/files'
import { FileItem } from '../../components/form-card/FileItem'
import { File as F } from '../../modules/files/models/File'
import style from './Editor.module.css'
import { forkJoin, Observable } from 'rxjs'
import { RolesService } from 'modules/users/services/RolesServices'
import { Roles } from '../../modules/users/enums/Roles'
import styles from '../user-profile/Editor.module.css'
import professionalSMS from '../../assets/role_icons/ico-rol-profesional.svg'
import patient from '../../assets/role_icons/ico-rol-paciente.svg'
import career from '../../assets/role_icons/ico-rol-cuidador.svg'
import family from '../../assets/role_icons/ico-rol-familiar.svg'
import allMyCircles from '../../assets/left_menu/circulos.svg'
import externProfessional from '../../assets/role_icons/ico-rol-externo.svg'
import { LoadingSpinner } from '../../components/loading-spinner/LoadingSpinner'
import { ProfessionalType } from '../../modules/users/models/ProfessionalType'
import { ProfessionalTypesService } from '../../modules/users/services/ProfessionalTypeService'
import { RolesType } from '../../common/enums/RolesType'
import { useNavigate } from 'react-router-dom'
import { getUserCircleContainer } from '../../container/user-circle-module'
import {
  IUserCircleActiveService,
  USER_CIRCLE_ACTIVE_SERVICE_KEY,
  USER_CIRCLE_SERVICE_KEY,
} from '../../modules/user-circle'
import { Alert, Box, TextField, Typography } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { UserCircleService } from 'modules/user-circle/services/UserCircleConfigService'

type EditorProps = {
  id?: string
  isDir: boolean
  parentID: string | null
  breadcrumbUrl?: Map<string, string>
}

type AutocompleteUserInterface = {
  key: string
  value: string
  role: string
}

enum ResourceErrors {
  NO_FILE = 'noFile',
}

const loggedUserContainer = getUserContainer()
const loggedUserService = loggedUserContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

const fileContainer = getFileContainer()
const fileService = fileContainer.get<FileService>(FILE_SERVICE_KEY)

const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)

const roleService = getUserContainer().get<RolesService>(ROLES_SERVICE_KEY)

const userContainer = getUserContainer()
const professionalTypeService = userContainer.get<ProfessionalTypesService>(
  PROFESSIONALTYPE_SERVICE_KEY
)

const userCircleActiveService = getUserCircleContainer().get<IUserCircleActiveService>(
  USER_CIRCLE_ACTIVE_SERVICE_KEY
)

const userCircleService = getUserCircleContainer().get<UserCircleService>(USER_CIRCLE_SERVICE_KEY)

export function Editor(props: EditorProps) {
  const { t } = useTranslation()
  const user = userCircleActiveService.getActiveFullUserCircle()
  const circle = getUserContainer().get<ICircleService>(CIRCLE_SERVICE_KEY).getActiveUser()
  const activeUser = userCircleActiveService.getActiveFullUserCircle()
  const navigate = useNavigate()

  const loggedUser = loggedUserService.get()

  const [resource, setResource] = useState<FileDTO>(
    emptyFileDTO(loggedUser?.id, activeUser?.id, false)
  )
  const [users, setUsers] = useState<Map<string, AutocompleteUserInterface>>(new Map())
  const [selectedUsers, setSelectedUsers] = useState<AutocompleteUserInterface[]>([])
  const [files, setFiles] = useState<F[]>([])
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isSending, setIsSending] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [roles, setRoles] = useState<Map<string, string>>(new Map())
  const [professionalType, setProfessionalType] = useState<ProfessionalType>()
  const [parentShared, setParentShared] = useState<boolean>(false)

  const getUsers = (ids: string[]): Observable<User[]> =>
    forkJoin(ids.map((id) => userService.getByID(id))) as unknown as Observable<User[]>

  useEffect(() => {
    if (!loggedUser?.id) return
    const listAux: ProfessionalType[] = []
    const isContentManager = loggedUser?.roles?.some((role) => role.startsWith('manager'))

    // Set professional type in case is manager
    if (!isContentManager) {
      professionalTypeService.getProfessionalTypeByUserID(loggedUser?.id).subscribe((res) => {
        if (!res) return
        listAux.push(res)
        setProfessionalType(listAux[0])
      })
    }

    roleService
      .getAll(
        new Query({
          sort: [{ field: 'name' }],
          pager: { offset: 0, limit: -1 },
        })
      )
      .subscribe((res) => {
        const tmpMap = new Map<string, string>()
        res.forEach((r) => tmpMap.set(r.id, r.name))
        setRoles(tmpMap)
      })

    if (props.id) {
      fileService.getByID(props.id).subscribe((res) => {
        if (res) {
          setResource(fromModel(res))
          setFiles([res])

          getUsers(res.sharedWith).subscribe((ul) => {
            setSelectedUsers(
              ul
                .filter((u) => u)
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((u) => ({ key: u.id, value: u.name, role: u.roles?.length ? u.roles[0] : '' }))
            )
          })
        }
      })
    } else {
      setResource(Object.assign({ ...resource }, { isDir: props.isDir, parent: props.parentID }))
    }
    if (!user) return

    // Get participants from circle to send the resource
    userCircleService.getByUserIDWithRelaters(user.user.id).subscribe((res) => {
      if (!res || !res[0]) return

      const value = res[0]
      const ul = [
        ...value.careers,
        ...value.familiars,
        ...value.profExt,
        ...value.profSms,
        ...[value.user], // esto añade dentro del selector al circulo que hemos seleccionado
        {
          id: 'allMyCircles',
          name: 'allMyCircles',
          value: 'allMyCircles',
          roles: [],
        },
      ]
      const mapped = new Map(
        ul.map((item) => [
          item.id,
          { key: item.id, value: item.name, role: item.roles?.length ? item.roles[0] : '' },
        ])
      )
      setUsers(mapped)
      setIsLoading(false)
    })
  }, [])

  useEffect(() => {
    if (!props.parentID) return

    fileService.getByID(props.parentID).subscribe((res) => {
      if (res) {
        if (res.sharedWith.length > 0) setParentShared(true)
      }
    })
  }, [props.parentID])

  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setResource(Object.assign({ ...resource }, { [e.target.name]: e.target.value }))

  const handleUsers = (value: AutocompleteUserInterface[]) => {
    if (!Array.isArray(value)) {
      return
    }
    if (value.find((v) => v.key === 'allMyCircles')) {
      const result = Array.from(users.values()).filter((v) => v.key !== 'allMyCircles')
      setSelectedUsers(result)
      setResource(Object.assign({ ...resource }, { sharedWith: result.map((v) => v.key) }))
      return
    }
    setSelectedUsers(value)
    setResource(Object.assign({ ...resource }, { sharedWith: value.map((v) => v.key) }))
  }

  const handleFiles = (field: string, value: F[]) => {
    if (!Array.isArray(value)) {
      return
    }
    setFiles([...value])
    if (value.length > 0) {
      const f = fromModel(value[0])
      setResource(
        Object.assign(
          { ...resource },
          { data: f.data, extension: f.extension, mimeType: f.mimeType }
        )
      )
    }
  }

  const goBack = () => {
    if (props.parentID) {
      navigate(`${ROUTE_RESOURCES}/${props.parentID}`, {
        state: { breadcrumbUrl: props.breadcrumbUrl },
      })
    } else {
      navigate(ROUTE_RESOURCES, { state: { breadcrumbUrl: props.breadcrumbUrl } })
    }
  }

  const validateResource = (): boolean => {
    if (!props.isDir && files.length === 0) {
      setErrorMessage(ResourceErrors.NO_FILE)
      setIsSending(false)
      return false
    }
    setErrorMessage('')
    return true
  }

  async function saveResource(e: FormEvent<HTMLFormElement>) {
    setIsSending(true)
    e.preventDefault()

    if (!validateResource()) {
      return
    }

    const newResource = Object.assign(
      { ...resource },
      {
        specialities: professionalType?.name,
        circles: undefined,
        createdByDoctor: loggedUser?.roles.includes(RolesType.ProfessionalSMS),
      }
    )

    if (props.id) {
      fileService.update(newResource).subscribe(() => {
        setIsSending(false)
        goBack()
      })
    } else {
      fileService.add(newResource).subscribe(() => {
        setIsSending(false)
        goBack()
      })
    }
  }

  const getImage = (option: AutocompleteUserInterface) => {
    if (option.key === 'allMyCircles') {
      return <img className={styles.roleIcon} src={allMyCircles} alt={t('allMyCircles')} />
    }
    switch (roles.get(option.role)) {
      case Roles.Professional:
      case Roles.Admin:
      case Roles.ContentManager:
        return <img className={style.roleIcon} src={professionalSMS} alt={t(Roles.Professional)} />

      case Roles.Patient:
        if (option.key === circle?.id) {
          return <img className={styles.roleIcon} src={family} alt={t(Roles.FamilyOrTutor)} />
        }
        return <img className={styles.roleIcon} src={patient} alt={t(Roles.Patient)} />

      case Roles.CarerOrTeacher:
        return <img className={styles.roleIcon} src={career} alt={t(Roles.CarerOrTeacher)} />

      case Roles.FamilyOrTutor:
        if (option.key === circle?.id) {
          return <img className={styles.roleIcon} src={patient} alt={t(Roles.Patient)} />
        }
        return <img className={styles.roleIcon} src={family} alt={t(Roles.FamilyOrTutor)} />

      case Roles.ProfessionalExtern:
        return (
          <img
            className={styles.roleIcon}
            src={externProfessional}
            alt={t(Roles.ProfessionalExtern)}
          />
        )
    }
  }

  return (
    <Box className={style.pageContainer}>
      {isLoading && (
        <Box className={style.loadingContainer}>
          <LoadingSpinner />
          <Typography variant="body1" component="p">
            {t('loadingBtn')}
          </Typography>
        </Box>
      )}
      {!isLoading && (
        <FormCard>
          <form onSubmit={saveResource} className={style.form}>
            <Box>
              <TextFieldItem
                field="name"
                value={resource.name}
                label={'name'}
                type={'text'}
                handleChange={handleInput}
                rows={undefined}
                required={true}
              />
            </Box>

            <Box>
              <Autocomplete
                key={resource.id}
                id={`${resource.id}-standard`}
                disabled={parentShared}
                options={Array.from(users.values())}
                multiple
                getOptionLabel={(opt) => t(opt.value)}
                isOptionEqualToValue={(option, value) => option.key === value.key}
                value={selectedUsers}
                onChange={(_, v) => handleUsers(v)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label={parentShared ? t('dirShared') : t('sharedWith')}
                  />
                )}
                noOptionsText={t('noElementsAvailable')}
                renderOption={(props, option) => (
                  <li {...props}>
                    <span className={style.text}>{getImage(option)}</span>
                    <span className={style.text}>{t(option.value)}</span>
                  </li>
                )}
              />
            </Box>

            <Box>
              {!props.isDir && (
                <FileItem
                  key={'files'}
                  field={'files'}
                  filesID={[]}
                  handleFiles={handleFiles}
                  userID={loggedUser?.id ?? ''}
                  cleanFiles={false}
                  uploadRemoveFileDirectly={false}
                  disabled={files.length > 0}
                  defaultFile={files}
                  maxFiles={1}
                  multiple={false}
                />
              )}
            </Box>

            {errorMessage && (
              <Box>
                <Alert severity="warning" key="errorMessage" id="errorMessage">
                  {t(errorMessage)}
                </Alert>
              </Box>
            )}

            <Box className={style.actionBtns}>
              <AppButton
                theme={ButtonTheme.NewSecondary}
                type={'button'}
                label={t('cancel')}
                handler={goBack}
              />
              <AppButton
                theme={ButtonTheme.NewPrimary}
                isLoading={isSending}
                disabled={isSending}
                type={'submit'}
                label={t('save')}
                handler={() => {}}
              />
            </Box>
          </form>
        </FormCard>
      )}
    </Box>
  )
}
