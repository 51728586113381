import React, { useEffect, useState } from 'react'
import { Box, Grid } from '@mui/material'

import { useTranslation } from 'react-i18next'
import genericStyle from '../../../common/utils/generic.module.css'
import styles from '../PatientData.module.css'
import styleMedical from './FamiliarData.module.css'
import { getUserContainer } from '../../../container/user-module'
import { IUserService, USER_SERVICE_KEY } from '../../../modules/users'
import dayjs from 'dayjs'
import ExpandedMedicalData from './ExpandedMedicalData'
import { getPatientDataContainer } from '../../../container/patient-data-module'
import { PatientDataService } from '../../../modules/patient-data/services/PatientDataService'
import { PATIENT_DATA_API_KEY } from '../../../modules/patient-data'
import { PatientDataDTO } from '../../../modules/patient-data/models/PatientDataDTO'
import { FamiliarDataTable } from './FamiliarDataTable'
import { LandmarkTable } from './LandmarkTable'
import { GrowthMonitoringTable } from './GrowthMonitoringTable'
import { getUserCircleContainer } from '../../../container/user-circle-module'
import {
  IUserCircleActiveService,
  USER_CIRCLE_ACTIVE_SERVICE_KEY,
} from '../../../modules/user-circle'
import { AppButton, ButtonTheme } from '../../../components/app-button/AppButton'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATIENT_DATA } from '../../../routes/routes-constants'
import right_arrow from '../../../assets/buttons/right_arrow.svg'
import { BackHeader } from '../../../components/navbar-responsive/backHeader'

const patientDataService = getPatientDataContainer().get<PatientDataService>(PATIENT_DATA_API_KEY)
const userContainer = getUserContainer()
const userService = userContainer.get<IUserService>(USER_SERVICE_KEY)

const UserCircleActiveService = getUserCircleContainer().get<IUserCircleActiveService>(
  USER_CIRCLE_ACTIVE_SERVICE_KEY
)

export function MedicalData() {
  const { t } = useTranslation()
  const activeUser = UserCircleActiveService.getActiveFullUserCircle()
  const navigate = useNavigate()
  const [data, setData] = useState<PatientDataDTO>()
  const [address, setAddress] = useState<string>('')
  const [town, setTown] = useState<string>('')
  const [familiarDataModal, setFamiliarDataModal] = useState<boolean>(false)
  const [landmarkModal, setLandmarkModal] = useState<boolean>(false)
  const [growMonitoringModal, setGrowMonitoringModal] = useState<boolean>(false)
  const [value, setValue] = useState<number>(0)

  const handleChangeValue = (newValue: number) => {
    setValue(newValue)
  }

  // No borrar. Hace falta para dar estilo.
  /*
  const auxiliarData: PatientDataDTO = {
    zero2One: {
      nif: 'nif',
      centerName: 'centerName',
      cipa: 'cipa',
      phone: 'phone',
      controlledPregnancy: 'controlledPregnancy',
      gestationType: 'gestationType',
      uniqueFetus: 'uniqueFetus',
      observations: 'observations',
      bloodType: 'bloodType',
      meconium: 'meconium',
      lactation: 'lactation',
      ocularProfilax: 'ocularProfilax',
      kVitamin: 'kVitamin',
      bHepatitis: 'bHepatitis',
      bHepatitisVaccine: 'bHepatitisVaccine',
      pulsioximetria: 'pulsioximetria',
      weight: 100,
      weightDate: new Date(),
      height: 100,
      heightDate: new Date(),
      cranialPerimeter: 100,
      cranialPerimeterDate: new Date(),
      metabolic: 'metabolic',
      metabolicDate: new Date(),
      deafness: 'deafness',
      deafnessDate: new Date(),
    },
    two2SixMonths: {
      height: 100,
      heightDate: new Date(),
      food: 'food',
      weight: 100,
      weightDate: new Date(),
      cranialPerimeter: 100,
      cranialPerimeterDate: new Date(),
    },
    seven2TwentyThreeMonths: {
      height: 100,
      heightDate: new Date(),
      weight: 100,
      weightDate: new Date(),
      cranialPerimeter: 100,
      cranialPerimeterDate: new Date(),
    },
    two2FiveYears: {
      height: 100,
      heightDate: new Date(),
      weight: 100,
      weightDate: new Date(),
      cranialPerimeter: 100,
      cranialPerimeterDate: new Date(),
      stillLactation: 'stillLactation',
    },
    six2ThirteenYears: {
      height: 100,
      heightDate: new Date(),
      weight: 100,
      weightDate: new Date(),
    },
  }

 */

  useEffect(() => {
    if (!activeUser?.user?.cip) {
      return
    }

    patientDataService.getByCIPA(activeUser?.user.cip).subscribe((res) => {
      let PD: PatientDataDTO

      if (!res) return
      PD = res
      patientDataService.getSeleneIndicators(activeUser?.user.cip).subscribe((resS) => {
        if (!resS) {
          setData(PD)
          return
        }
        let oP = ''
        let kV = ''
        let bH = ''
        let bHV = ''
        let pX = ''
        resS.indicators.forEach((i) => {
          switch (i.id) {
            case 'NEO_PROFILOCULAR':
              oP = i.value
              break
            case 'RN_ADMVITK':
              kV = i.value
              break
            case 'NEO_DOGAMMA_HepB':
              bH = i.value
              break
            case 'NEO_DOVACU_HepB':
              bHV = i.value
              break
            case 'CRIB_PULSI_RESULT':
              pX = i.value
              break
            default:
              break
          }
        })
        PD = {
          ...PD,
          zero2One: {
            nif: PD.zero2One.nif,
            centerName: PD.zero2One.centerName,
            cipa: PD.zero2One.cipa,
            phone: PD.zero2One.phone,
            controlledPregnancy: PD.zero2One.controlledPregnancy,
            gestationType: PD.zero2One.gestationType,
            uniqueFetus: PD.zero2One.uniqueFetus,
            observations: PD.zero2One.observations,
            bloodType: PD.zero2One.bloodType,
            meconium: PD.zero2One.meconium,
            lactation: PD.zero2One.lactation,
            metabolic: PD.zero2One.metabolic,
            metabolicDate: PD.zero2One.metabolicDate,
            deafness: PD.zero2One.deafness,
            deafnessDate: PD.zero2One.deafnessDate,
            ocularProfilax: oP,
            kVitamin: kV,
            bHepatitis: bH,
            bHepatitisVaccine: bHV,
            pulsioximetria: pX,
            height: PD.zero2One.height,
            heightDate: PD.zero2One.heightDate,
            weight: PD.zero2One.weight,
            weightDate: PD.zero2One.weightDate,
            cranialPerimeter: PD.zero2One.cranialPerimeter,
            cranialPerimeterDate: PD.zero2One.cranialPerimeterDate,
          },
        }
        setData(PD)
      })
    })
    userService
      .getCitizenData({
        id: activeUser?.user.cip,
        typeData: 'cim',
      })
      .subscribe((res) => {
        if (!res) {
          setAddress('Calle prueba, nº123 3º A')
          setTown('El Palmar')
          return
        }
        let addressAux = ''
        if (res.Body.CitizenConsResponse.DataCitizen.Citizen.Address) {
          if (res.Body.CitizenConsResponse.DataCitizen.Citizen.Address.RoadType)
            addressAux = res.Body.CitizenConsResponse.DataCitizen.Citizen.Address.RoadType
          if (res.Body.CitizenConsResponse.DataCitizen.Citizen.Address.RoadName)
            addressAux =
              addressAux + ' ' + res.Body.CitizenConsResponse.DataCitizen.Citizen.Address.RoadName
          if (res.Body.CitizenConsResponse.DataCitizen.Citizen.Address.RoadNumber)
            addressAux =
              addressAux + ' ' + res.Body.CitizenConsResponse.DataCitizen.Citizen.Address.RoadNumber
          if (res.Body.CitizenConsResponse.DataCitizen.Citizen.Address.Other)
            addressAux =
              addressAux + ' ' + res.Body.CitizenConsResponse.DataCitizen.Citizen.Address.Other
          setAddress(addressAux)
          res.Body.CitizenConsResponse.DataCitizen.Citizen.Address.CityName &&
            setTown(res.Body.CitizenConsResponse.DataCitizen.Citizen.Address.CityName)
        }
      })
  }, [activeUser])

  const goBack = () => navigate(ROUTE_PATIENT_DATA)

  const CircleMedicalData = () => {
    return (
      <>
        <Box mb={3} className={styles.listContainer} style={{ cursor: 'auto' }}>
          <Grid container xs={12}>
            <Grid item md={6} xs={12}>
              {/* Columna 1 */}
              <Grid container>
                <Grid item xs={12}>
                  <Grid container className={styles.patientDataRow}>
                    <Grid item xs={6}>
                      {t('fullName')}
                    </Grid>
                    <Grid item xs={6} className={styles.textFieldBox}>
                      {activeUser?.user.name} {activeUser?.user.lastname}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container className={styles.patientDataRow}>
                    <Grid item xs={6}>
                      {t('birthDate')}
                    </Grid>
                    <Grid item xs={6} className={styles.textFieldBox}>
                      {dayjs(activeUser?.user?.birthDate).format('DD/MM/YYYY')}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container className={styles.patientDataRow}>
                    <Grid item xs={6}>
                      {t('Address')}
                    </Grid>
                    <Grid item xs={6} className={styles.textFieldBox}>
                      {address}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} xs={12}>
              {/* Columna 2 */}
              <Grid container>
                <Grid item xs={12}>
                  <Grid container className={styles.patientDataRow}>
                    <Grid item xs={6}>
                      {t('Town')}
                    </Grid>
                    <Grid item xs={6} className={styles.textFieldBox}>
                      {town}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container className={styles.patientDataRow}>
                    <Grid item xs={6}>
                      {t('Phone')}
                    </Grid>
                    <Grid item xs={6} className={styles.textFieldBox}>
                      {activeUser?.user ? activeUser?.user.phone : ''}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container className={styles.patientDataRow}>
                    <Grid item xs={6}>
                      {t('Clinic')}
                    </Grid>
                    <Grid item xs={6} className={styles.textFieldBox}>
                      {data ? data.zero2One.centerName : ''}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {data && <ExpandedMedicalData data={data} value={value} changeValue={handleChangeValue} />}
        {/* <ExpandedMedicalData
          data={auxiliarData}
          value={value}
          changeValue={handleChangeValue} /> */}
      </>
    )
  }

  const openFamiliarDataModal = () => {
    setFamiliarDataModal(true)
  }

  const handleCloseFamiliarDataModal = () => {
    setFamiliarDataModal(false)
  }

  const openLandmarkModal = () => {
    setLandmarkModal(true)
  }

  const handleCloseLandmarkModal = () => {
    setLandmarkModal(false)
  }

  const openGrowMonitoringModal = () => {
    setGrowMonitoringModal(true)
  }

  const handleCloseGrowMonitoringModal = () => {
    setGrowMonitoringModal(false)
  }

  return (
    <>
      <Box className={genericStyle.pageContainer}>
        <Box mb={innerWidth < 599 ? 3 : 0} display={'flex'} justifyContent={'space-between'}>
          <AppButton
            theme={ButtonTheme.NewSecondary}
            type={'button'}
            label={t('back')}
            handler={goBack}
          />
        </Box>
        {!familiarDataModal && !landmarkModal && !growMonitoringModal && (
          <Box>
            <Box>
              <Box className={styleMedical.titleMedicalData}>{t('patientData')}</Box>
              <Box>
                <CircleMedicalData />
              </Box>
            </Box>
            {innerWidth > 599 ? (
              <>
                <Box>
                  <Box className={styleMedical.titleMedicalData}>{t('familiarData')}</Box>
                  <Box>
                    <FamiliarDataTable />
                  </Box>
                </Box>
                <Box>
                  <Box className={styleMedical.titleMedicalData}>{t('landmarks')}</Box>
                  <Box>
                    <LandmarkTable />
                  </Box>
                </Box>
                <Box>
                  <Box className={styleMedical.titleMedicalData}>{t('growthMonitoring')}</Box>
                  <Box>
                    <GrowthMonitoringTable />
                  </Box>
                </Box>
              </>
            ) : (
              <>
                <Box>
                  <Box className={styleMedical.containerButtonResponsive}>
                    <AppButton
                      theme={ButtonTheme.borderOrangeTextBlack}
                      handler={openFamiliarDataModal}
                      type={'button'}
                      label={t('familiarData')}
                      endIcon={right_arrow}
                    />
                  </Box>
                  <Box className={styleMedical.containerButtonResponsive}>
                    <AppButton
                      theme={ButtonTheme.borderOrangeTextBlack}
                      handler={openLandmarkModal}
                      type={'button'}
                      label={t('landmarks')}
                      endIcon={right_arrow}
                    />
                  </Box>
                  <Box className={styleMedical.containerButtonResponsive}>
                    <AppButton
                      theme={ButtonTheme.borderOrangeTextBlack}
                      handler={openGrowMonitoringModal}
                      type={'button'}
                      label={t('growthMonitoring')}
                      endIcon={right_arrow}
                    />
                  </Box>
                </Box>
              </>
            )}
          </Box>
        )}
        {familiarDataModal && (
          <Box>
            <BackHeader labelText={t('medicalData')} handlerBack={handleCloseFamiliarDataModal} />
            <Box className={styleMedical.containerTitleResponsive}>{t('familiarData')}</Box>
            <FamiliarDataTable />
          </Box>
        )}
        {landmarkModal && (
          <Box>
            <BackHeader labelText={t('medicalData')} handlerBack={handleCloseLandmarkModal} />
            <Box className={styleMedical.containerTitleResponsive}>{t('landmarks')}</Box>
            <LandmarkTable />
          </Box>
        )}
        {growMonitoringModal && (
          <Box>
            <BackHeader labelText={t('medicalData')} handlerBack={handleCloseGrowMonitoringModal} />
            <Box className={styleMedical.containerTitleResponsive}>{t('growthMonitoring')}</Box>
            <GrowthMonitoringTable />
          </Box>
        )}
      </Box>
    </>
  )
}
