import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { Box, Tab, Tabs } from '@mui/material'
import genericStyle from '../../../common/utils/generic.module.css'
import {
  ROUTE_GOALS_FORM,
  ROUTE_LINK_WEARABLE,
  ROUTE_PATIENT_DATA,
} from '../../../routes/routes-constants'
import { useNavigate } from 'react-router-dom'
import { AppButton, ButtonTheme } from '../../../components/app-button/AppButton'
import { WearableType, wereableTypes } from '../../../modules/patient-data/enums/WearableType'
import styles from './Wearables.module.css'
import { GenericChart } from '../../../components/generic-chart/GenericChart'
import { getUserContainer } from '../../../container/user-module'
import { WearableService } from '../../../modules/patient-data/services/WearableService'
import { WEARABLE_SERVICE_KEY } from '../../../modules/patient-data/container'
import { getPatientDataContainer } from '../../../container/patient-data-module'
import { Query, QueryParam } from '../../../common/api/Query'
import { Wearable, WearableQuery } from '../../../modules/patient-data/models/Wearable'
import { ICircleService } from '../../../modules/users/services/CircleService'
import { CIRCLE_SERVICE_KEY, LOGGED_USER_SERVICE_KEY } from '../../../modules/users/container'
import { LoggedUserService } from '../../../modules/users/services/LoggedUserService'
import { Permission } from '../../../common/permission'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers'
import TextField from '@mui/material/TextField'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

const wearableTypes = wereableTypes()

const patientDataContainer = getPatientDataContainer()
const wearableService = patientDataContainer.get<WearableService>(WEARABLE_SERVICE_KEY)

const loggedUserService = getUserContainer().get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

export const Wearables = () => {
  const circle = getUserContainer().get<ICircleService>(CIRCLE_SERVICE_KEY).getActiveUser()

  const { t } = useTranslation()

  const [tabValue, setTabValue] = useState<number>(0)
  const [fromDate, setFromDate] = useState<Date>(
    new Date(new Date().setDate(new Date().getDate() - 7))
  )
  const [toDate, setToDate] = useState<Date>(new Date())
  const [wearableResult, setWearableResult] = useState<Wearable[]>([])
  const [wearableCharts, setWearableCharts] = useState<object[]>([])
  const [DateTimePickerError, setDateTimePickerError] = useState<string>('')
  const navigate = useNavigate()
  const [hasLogin, setHasLogin] = useState<boolean>(false)

  useEffect(() => {
    wearableService.getLoginType(circle?.id ?? '').subscribe((res) => {
      setHasLogin(res > 0)
    })
  }, [])

  useEffect(() => {
    if (!circle) {
      return
    }

    wearableService
      .getFilteredItems(
        new Query({
          query: [
            new QueryParam<WearableQuery>('userID', circle.id),
            new QueryParam<WearableQuery>('startDate', fromDate.toISOString()),
            new QueryParam<WearableQuery>('finishDate', toDate.toISOString()),
            new QueryParam<WearableQuery>('type', tabValue + 1),
          ],
        })
      )
      .subscribe((res) => {
        setWearableResult(res)
      })
  }, [tabValue, fromDate, toDate])

  useEffect(() => {
    if (!wearableResult.length) {
      return
    }

    const numberOfValues = wearableResult[0].values.length
    const tmpWearableChart = []

    for (let i = 0; i < numberOfValues; i++) {
      tmpWearableChart.push({
        label: wearableDatasetName(i),
        data: wearableResult.map((wr) => wr.values[i]),
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 2,
      })
    }
    setWearableCharts(tmpWearableChart)
  }, [wearableResult])

  const goBack = () => navigate(ROUTE_PATIENT_DATA)

  const goGoals = () => navigate(ROUTE_GOALS_FORM)

  const linkWearable = () => navigate(ROUTE_LINK_WEARABLE)

  const changeTabValue = (event: React.ChangeEvent<{}>, tabNumber: number) => setTabValue(tabNumber)

  const handleFromDateChange = (date: any) => {
    if (date) {
      setFromDate(date.toDate())
    }
  }

  const handleToDateChange = (date: any) => {
    if (date) {
      setToDate(date.toDate())
    }
  }

  const measurementUnit = (): string => {
    switch (tabValue + 1) {
      case WearableType.Steps:
        return t('steps')
      case WearableType.Calories:
        return 'kcal'
      case WearableType.Distance:
        return t('meters')
      case WearableType.SleepHours:
        return t('hours')
      case WearableType.RestingPulse:
        return t('pulse')
      case WearableType.OxygenSaturation:
        return '%'
      case WearableType.Weight:
        return 'kg'
      case WearableType.Fat:
        return '%'
      case WearableType.BloodPressure:
        return 'mmHG'
      case WearableType.Temperature:
        return '°C'
      default:
        return ''
    }
  }

  const wearableDatasetName = (value: number): string => {
    switch (tabValue + 1) {
      case WearableType.Steps:
        return t('steps')
      case WearableType.Calories:
        return t('calories')
      case WearableType.Distance:
        return t('distance')
      case WearableType.SleepHours:
        return t('sleepHours')
      case WearableType.RestingPulse:
        return t('restingPulse')
      case WearableType.OxygenSaturation:
        return t('oxygenSaturation')
      case WearableType.Weight:
        return t('weight')
      case WearableType.Fat:
        return t('fat')
      case WearableType.BloodPressure:
        if (value === 0) {
          return t('systolic')
        }
        return t('diastolic')
      case WearableType.Temperature:
        return t('temperature')
      default:
        return ''
    }
  }

  const chartTypeByWearables = (): string => {
    switch (tabValue + 1) {
      case WearableType.BloodPressure:
        return 'line'
      case WearableType.Weight:
        return 'line'
      default:
        return 'bar'
    }
  }

  return (
    <Box className={genericStyle.pageContainer}>
      <Box>
        <Box mb={3} display={'flex'} justifyContent={'space-between'}>
          <AppButton
            theme={ButtonTheme.NewSecondary}
            type={'button'}
            label={t('back')}
            handler={goBack}
          />
          {hasLogin && (
            <AppButton
              theme={ButtonTheme.NewPrimary}
              type={'button'}
              label={t('setGoals')}
              handler={goGoals}
            />
          )}
          {loggedUserService.userCan(Permission.linkWearable) && (
            <AppButton
              theme={ButtonTheme.NewPrimary}
              type={'button'}
              label={hasLogin ? t('unlinkWearable') : t('linkWearable')}
              handler={linkWearable}
            />
          )}
        </Box>
      </Box>
      <Box className={styles.dateContainer}>
        <Box flexDirection={'row'} display={'flex'} justifyContent={'space-between'}>
          {/* <Box flexDirection={'row'} display={'flex'} width={250} justifyContent={'space-between'}>
            <p>{t('from')}:</p>
            <KeyboardDatePicker
              className={styles.datePicker}
              value={fromDate}
              onChange={(date) => handleFromDateChange(date)}
              variant={'inline'}
              inputVariant={'outlined'}
              format={'DD/MM/YYYY'}
            />
          </Box> */}
          <p>{t('from')}:</p>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
            <DatePicker
              key={'date'}
              PopperProps={{
                sx: {
                  '& .css-dplwbx-MuiPickersCalendarHeader-label': {
                    fontFamily: 'Poppins',
                    textTransform: 'capitalize',
                  },
                  '& .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root': {
                    fontFamily: 'Poppins',
                  },
                  '& .css-raiqh1-MuiTypography-root-MuiDayPicker-weekDayLabel': {
                    fontFamily: 'Poppins',
                  },
                  '& .css-3eghsz-PrivatePickersYear-button': {
                    fontFamily: 'Poppins',
                  },
                },
              }}
              onError={(reason, value) => {
                switch (reason) {
                  case 'invalidDate':
                    setDateTimePickerError(t('invalidDateMessage'))
                    break
                  case 'minDate':
                    setDateTimePickerError(t('minDateMessage'))
                    break
                }
              }}
              renderInput={(props) => (
                <TextField
                  sx={{
                    '.css-1ptx2yq-MuiInputBase-root-MuiInput-root': { paddingRight: '17px' },
                    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
                      fontFamily: 'Poppins',
                    },
                    width: 170,
                  }}
                  variant={'outlined'}
                  helperText={props.error && DateTimePickerError}
                  {...props}
                />
              )}
              inputFormat="DD/MM/YYYY"
              value={fromDate}
              onChange={handleFromDateChange}
              maxDate={new Date()}
            />
          </LocalizationProvider>
        </Box>

        <Box flexDirection={'row'} display={'flex'} justifyContent={'space-between'}>
          <p>{t('to')}:</p>
          {/* <KeyboardDatePicker
              className={styles.datePicker}
              value={toDate}
              onChange={(date) => handleToDateChange(date)}
              variant={'inline'}
              inputVariant={'outlined'}
              format={'DD/MM/YYYY'}
            /> */}
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
            <DatePicker
              key={'date'}
              PopperProps={{
                sx: {
                  '& .css-dplwbx-MuiPickersCalendarHeader-label': {
                    fontFamily: 'Poppins',
                    textTransform: 'capitalize',
                  },
                  '& .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root': {
                    fontFamily: 'Poppins',
                  },
                  '& .css-raiqh1-MuiTypography-root-MuiDayPicker-weekDayLabel': {
                    fontFamily: 'Poppins',
                  },
                  '& .css-3eghsz-PrivatePickersYear-button': {
                    fontFamily: 'Poppins',
                  },
                },
              }}
              onError={(reason, value) => {
                switch (reason) {
                  case 'invalidDate':
                    setDateTimePickerError(t('invalidDateMessage'))
                    break
                  case 'minDate':
                    setDateTimePickerError(t('minDateMessage'))
                    break
                }
              }}
              renderInput={(props) => (
                <TextField
                  sx={{
                    '.css-1ptx2yq-MuiInputBase-root-MuiInput-root': { paddingRight: '17px' },
                    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
                      fontFamily: 'Poppins',
                    },
                    width: 170,
                  }}
                  variant={'outlined'}
                  helperText={props.error && DateTimePickerError}
                  {...props}
                />
              )}
              inputFormat="DD/MM/YYYY"
              value={toDate}
              onChange={handleToDateChange}
              minDate={fromDate}
              maxDate={new Date()}
            />
          </LocalizationProvider>
        </Box>
      </Box>
      <Box className={styles.chartContainer} flexGrow={'1'} display={'flex'}>
        <Tabs
          orientation={window.screen.width > 1200 ? 'vertical' : 'horizontal'}
          variant={'scrollable'}
          indicatorColor={'secondary'}
          value={tabValue}
          onChange={changeTabValue}
          className={styles.tabs}
        >
          {Object.keys(wearableTypes).map((wt) => (
            <Tab
              sx={{ wrapper: styles.tab }}
              key={wt}
              label={t(wearableTypes[wt as unknown as WearableType])}
            />
          ))}
        </Tabs>
        <Box className={styles.chart} mb={3}>
          <GenericChart
            title={'wearables'}
            type={chartTypeByWearables()}
            data={wearableCharts}
            labelX={wearableResult.map((wr) => new Date(wr.date).toLocaleDateString())}
            xAxisLabel={''}
            yAxisLabel={measurementUnit()}
            showTitle={false}
            showLegend={false}
            showTooltip={true}
          />
        </Box>
      </Box>
    </Box>
  )
}
