import { ICircleService, CIRCLE_SERVICE_KEY } from '../../modules/circle'
import { Query } from '../../common/api/Query'
import { useEffect, useState } from 'react'
import {
  IUserCircleActiveService,
  IUserCircleService,
  USER_CIRCLE_ACTIVE_SERVICE_KEY,
  USER_CIRCLE_SERVICE_KEY,
} from '../../modules/user-circle'
import { getUserCircleContainer } from '../../container/user-circle-module'
import { getCircleContainer } from '../../container/circle-module'
import { UserCircle } from '../../modules/user-circle/models/UserCircle'

const UserCircleService = getUserCircleContainer().get<IUserCircleService>(USER_CIRCLE_SERVICE_KEY)
const userCircleActiveService = getUserCircleContainer().get<IUserCircleActiveService>(
  USER_CIRCLE_ACTIVE_SERVICE_KEY
)
const circleService = getCircleContainer().get<ICircleService>(CIRCLE_SERVICE_KEY)

export const useIsEpilepsy = () => {
  const userCircle = userCircleActiveService.getActiveUser()
  const [userCircles, setUserCircles] = useState<UserCircle[]>([])
  const [isEpilepsy, setIsEpilepsy] = useState<boolean>(false)
  const [clear, setClear] = useState<boolean>(true)

  useEffect(() => {
    if (!userCircle?.id) return
    UserCircleService.getFilteredList(
      new Query({
        query: [
          {
            name: 'userID',
            value: userCircle?.id,
          },
        ],
        sort: [{ field: 'userID', desc: true }],
      })
    ).subscribe((res) => {
      setUserCircles(() => res.items)
    })
  }, [])

  useEffect(() => {
    if (!userCircle?.id || !clear) return
    userCircles.forEach((p) => {
      circleService
        .getFilteredList(
          new Query({
            query: [
              {
                name: 'id',
                value: p.circleID,
              },
            ],
            sort: [{ field: 'name', desc: true }],
          })
        )
        .subscribe((res) => {
          res.items.forEach((p) => {
            if (p.name === 'Epilepsia') {
              setIsEpilepsy(() => true)
              return null
            }
          })
        })
    })

    return () => {
      setClear(() => true)
    }
  }, [userCircles])

  return isEpilepsy
}
