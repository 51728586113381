import { LoggedUser } from '../models/LoggedUser'
import { Container, IInit } from '../../../common/container/Container'
import { AuthDTO } from '../models/AuthDTO'
import { ConnectableObservable, Observable, Subject } from 'rxjs'
import { IUserApi } from '../api/UserApi'
import { IStatusService } from '../../../common/status/StatusService'
import { LoggedUserDTO, toModel } from '../models/LoggedUserDTO'
import { multicast } from 'rxjs/operators'
import { ICircleService } from './CircleService'
import { CIRCLE_SERVICE_KEY } from '../container'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Roles } from '../enums/Roles'

export interface ILoggedUserService extends IInit {
  login(a: AuthDTO): Observable<LoggedUserDTO | undefined>

  logout(): void

  getCSRFToken(): Observable<string | undefined>

  refreshCSRFToken(): Observable<string | undefined>

  getObservable(): Observable<LoggedUser | undefined>

  get(): LoggedUser | undefined

  setRoles(roles: string[], permissions: string[]): void
}

const LOGGED_USER_KEY = 'logged user'
export const CSRF_KEY = 'XSRF-TOKEN'

type Props = {
  apiKey: symbol
}

export class LoggedUserService implements ILoggedUserService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IUserApi
  private _circleService!: ICircleService
  private _statusService!: IStatusService

  private _user: LoggedUser | undefined
  private _userSubject = new Subject<LoggedUser>()

  constructor(p: Props) {
    this.loadUser()
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IUserApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._circleService = this._container.get<ICircleService>(CIRCLE_SERVICE_KEY)
  }

  login(a: AuthDTO): Observable<LoggedUserDTO | undefined> {
    const multi = this._api
      .login(a)
      .pipe(multicast(() => new Subject<LoggedUserDTO | undefined>())) as ConnectableObservable<
      LoggedUserDTO | undefined
    >
    multi.connect()
    multi.subscribe((dto) => {
      if (dto?.id === 'banned') {
        this._statusService.sendStatus({ variant: 'banned', message: 'Usuario bloqueado' })
        return
      }
      if (dto) {
        this.storeUser(dto)
      }
    })

    return multi
  }

  logout() {
    this._api.logout().subscribe(() => {
      this.removeUser()
      this._circleService.setActive(undefined)
      document.cookie = 'SID' + '=; Max-Age=-1; Expires=0'
      localStorage.clear()
      sessionStorage.clear()
      window.location.reload()
    })
  }

  getCSRFToken(): Observable<string | undefined> {
    return this._api.getCSRFToken()
  }

  refreshCSRFToken(): Observable<string | undefined> {
    return this._api.refreshCSRFToken()
  }

  logoutAndRedirectToSedePruCarm() {
    this._api.logout().subscribe(() => {
      this.removeUser()
      this._circleService.setActive(undefined)
      document.cookie = 'SID' + '=; Max-Age=-1; Expires=0'
      localStorage.clear()
      sessionStorage.clear()
      window.location.replace('https://sede.carm.es/sms/portaldelpaciente/secure/inicio.xhtml')
    })
  }

  get(): LoggedUser | undefined {
    return this._user
  }

  getObservable(): Observable<LoggedUser | undefined> {
    this.getCSRFToken().subscribe((res) => {
      if (res) {
        sessionStorage.setItem(CSRF_KEY, res)
      }
    })
    return this._userSubject.pipe()
  }

  setRoles(roles: string[], permissions: string[]) {
    if (!this._user) {
      return
    }

    this._user.roles = roles
    this._user.permissions = permissions
  }

  private next() {
    this._userSubject.next(this._user)
  }

  private loadUser() {
    const dto = (JSON.parse(localStorage.getItem(LOGGED_USER_KEY) ?? 'null') ||
      undefined) as LoggedUserDTO
    if (dto && new Date(dto.sessionExpires) > new Date()) {
      this._user = toModel(dto)
      this.next()
      setTimeout(() => this.logout(), this._user.sessionExpires.valueOf() - new Date().valueOf())
    }
  }

  private storeUser(dto: LoggedUserDTO) {
    dto.dni = ''
    if (dto.roles.includes(Roles.Professional || Roles.Admin || Roles.ContentManager)) {
      dto.birthDate = ''
      dto.gender = 0
      dto.cip = ''
    }

    localStorage.setItem(LOGGED_USER_KEY, JSON.stringify(dto))
    this._user = toModel(dto)
    this.getCSRFToken().subscribe((token) => {
      if (token) {
        sessionStorage.setItem(CSRF_KEY, token)
      }
    })
    this.next()
    setTimeout(() => this.logout(), this._user.sessionExpires.valueOf() - new Date().valueOf())
  }

  private removeUser() {
    localStorage.removeItem(LOGGED_USER_KEY)
    sessionStorage.removeItem(CSRF_KEY)
    this._user = undefined
    this.next()
  }

  userCan(perm: string): boolean {
    return this._user?.permissions?.includes(perm) ?? false
  }
}
