import { Box, Divider, MenuItem, Modal, Select, SelectChangeEvent } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CircleDTO } from '../../modules/circle/models/CircleDTO'
import { Query } from '../../common/api/Query'
import { CircleQuery } from '../../modules/circle/models/Circle'
import { getCircleContainer } from '../../container/circle-module'
import { CIRCLE_SERVICE_KEY, ICircleService } from '../../modules/circle'
import { emptyUserDTO, toModel, UserDTO } from '../../modules/users/models/User'
import TextField from '@mui/material/TextField'
import { getUserContainer } from '../../container/user-module'
import { IUserService, USER_SERVICE_KEY } from '../../modules/users'
import { v4 as uuidv4 } from 'uuid'
import { RolesType } from '../../common/enums/RolesType'
import Button from '@mui/material/Button'
import { UserCircleDTO } from '../../modules/user-circle/models/UserCircleDTO'
import { IUserCircleService, USER_CIRCLE_SERVICE_KEY } from '../../modules/user-circle'
import { getUserCircleContainer } from '../../container/user-circle-module'

interface NewUserModalProps {
  open: boolean
  onClose: () => void
}

const circleService = getCircleContainer().get<ICircleService>(CIRCLE_SERVICE_KEY)
const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)
const userCircleService = getUserCircleContainer().get<IUserCircleService>(USER_CIRCLE_SERVICE_KEY)

export const NewUserModal = ({ open, onClose }: NewUserModalProps) => {
  const { t } = useTranslation()
  const [circle, setCircle] = useState<CircleDTO[]>([])
  const [newUser, setNewUser] = useState<UserDTO>(emptyUserDTO())
  const [currentCirclePathology, setCurrentCirclePathology] = useState<string>()

  useEffect(() => {
    circleService.getFilteredList(new Query<CircleQuery>({})).subscribe((res) => {
      if (!res) return
      setCircle(res.items)
    })
  }, [])

  const handleSelectCircle = (event: SelectChangeEvent<typeof currentCirclePathology>) => {
    const {
      target: { value },
    } = event
    setCurrentCirclePathology(value)
  }

  const handlerNewUser = (e: { target: { name: any; value: any } }) => {
    setNewUser(Object.assign({ ...newUser }, { [e.target.name]: e.target.value }))
  }

  const saveNewUser = () => {
    const completedUser: UserDTO = {
      id: uuidv4(),
      name: newUser.name,
      username: newUser.name,
      lastname: 'sanchez',
      isCircle: true,
      gender: 2,
      birthDate: new Date(),
      dni: '12345678A',
      cip: 'CARM12345678A',
      phone: '000000000',
      email: 'sanchez@gmail.com',
      roles: [RolesType.Patient],
      acceptChat: true,
      acceptVideoCalls: true,
    }
    if (!newUser) return

    const circleID = circle.filter((c) => c.name === currentCirclePathology)

    userService.add(toModel(completedUser)).subscribe((res) => {
      if (!res) return
      const newUserCircle: UserCircleDTO = {
        id: uuidv4(),
        circleID: circleID[0].id ?? '',
        userID: res.id,
        familiarsID: ['c991bb11-b6e4-439d-a6b9-a6dafe271b84'],
        profSmsID: ['61356ff9-d668-4fe2-a00e-d3ab4e284859'],
        profExtID: [],
        careersID: [],
      }
      userCircleService.add(newUserCircle).subscribe((res) => {})
    })
  }

  return (
    <Modal
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      open={open}
      onClose={onClose}
    >
      <div style={{ backgroundColor: 'white', width: '70%', padding: 20 }}>
        <h2>Crear nuevo usuario</h2>
        <Box>
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p
              style={{
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 'bold',
                fontSize: window.innerWidth > 1220 ? '1.2rem' : '0.9rem',
                marginLeft: 0,
              }}
            >
              {t('name')}
            </p>
          </Box>
          <Divider style={{ backgroundColor: '#68b3e0', marginTop: 8, height: 4 }} />
          <Box style={{ display: 'flex', marginTop: '30px' }}>
            <TextField
              style={{ width: '100%', backgroundColor: '#f5f2f2' }}
              type="text"
              name="name"
              onChange={handlerNewUser}
            />
          </Box>
        </Box>
        <Box style={{ width: '100%', marginTop: '30px' }}>
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p
              style={{
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 'bold',
                fontSize: window.innerWidth > 1220 ? '1.2rem' : '0.9rem',
                marginLeft: 0,
              }}
            >
              {t('selectCircle')}
            </p>
          </Box>
          <Divider style={{ backgroundColor: '#68b3e0', marginTop: 8, height: 4 }} />
          <Box style={{ display: 'flex', marginTop: '30px' }}>
            <Select
              MenuProps={{
                MenuListProps: {
                  sx: {
                    'li.MuiButtonBase-root': {
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      fontFamily: 'Poppins',
                      paddingLeft: '10px',
                    },
                  },
                },
              }}
              SelectDisplayProps={{
                style: {
                  paddingLeft: '20px',
                  fontFamily: 'Poppins',
                },
              }}
              fullWidth
              variant="outlined"
              style={{ backgroundColor: '#f5f2f2' }}
              value={currentCirclePathology ?? ''}
              onChange={handleSelectCircle}
            >
              {Object.values(circle).map((k) => (
                <MenuItem key={k.id} value={k.name}>
                  {k.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
        <Box>
          <Button onClick={saveNewUser}>Guardar</Button>
        </Box>
      </div>
    </Modal>
  )
}
