import { Article } from '../models/Article'
import { Observable } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { ItemList } from '../../../common/models/ItemList'
import { Container, IInit } from '../../../common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { IArticleApi } from '../api/ArticleApi'
import { ArticleDTO } from '../models/ArticleDTO'
import { STATUS_SERVICE_KEY } from '../../../container/app'

type Props = {
  apiKey: symbol
}

export interface IArticleService extends IInit {
  getByID(id: string): Observable<Article | undefined>

  getFilteredList(q: Query<Article>): Observable<ItemList<Article>>

  add(e: ArticleDTO): Observable<Article | undefined>

  update(e: ArticleDTO): Observable<Article | undefined>

  delete(id: string): Observable<boolean>
}

export class ArticleService implements IArticleService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IArticleApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IArticleApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: ArticleDTO): Observable<Article | undefined> {
    return this._api.add(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getByID(id: string): Observable<Article | undefined> {
    return this._api.getByID(id)
  }

  getFilteredList(q: Query<Article>): Observable<ItemList<Article>> {
    return this._api.getFilteredList(q).pipe()
  }

  update(e: ArticleDTO): Observable<Article | undefined> {
    return this._api.update(e)
  }
}
