import { getUserContainer } from '../../../container/user-module'
import { IUserService, LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../../modules/users'
import React, { ChangeEvent, useEffect, useState, useRef } from 'react'
import { User, UserQuery } from '../../../modules/users/models/User'
import { ILoggedUserService } from '../../../modules/users/services/LoggedUserService'
import { Query, QueryParam, QueryParamN } from '../../../common/api/Query'
import { useTranslation } from 'react-i18next'
import { Search, SearchValue } from '../../../components/table_type/types'
import styles from './AsignForm.module.css'
import { v4 as uuidv4 } from 'uuid'
import { USER_FORM_SERVICE_KEY } from '../../../modules/forms'
import { UserFormService } from '../../../modules/forms/services/UserFormService'
import { getFormContainer } from '../../../container/form-module'
import { UserForm } from '../../../modules/forms/models/UserForm'
import {
  Box,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from '@mui/material'
import { Searcher } from '../../../components/table/Searcher'
import { AssignCard, UserWithRelatersCustom } from './AssignCard'
import { CheckboxAssignType } from '../../../modules/forms/enums/CheckboxAssignType'
import { FormDTO } from '../../../modules/forms/models/FormDTO'
import { getUserCircleContainer } from '../../../container/user-circle-module'
import { IUserCircleService, USER_CIRCLE_SERVICE_KEY } from '../../../modules/user-circle'
import { UserCircleWithRelatersAndCircle } from '../../../modules/user-circle/models/UserCircleWithRelatersAndCircle'

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)
const userFormService = getFormContainer().get<UserFormService>(USER_FORM_SERVICE_KEY)
const userCircleService = getUserCircleContainer().get<IUserCircleService>(USER_CIRCLE_SERVICE_KEY)

enum RolesToAssign {
  CIRCLES = 'AllMyCircles',
  PATIENTS = 'AllMyPatients',
  FAMILIARS = 'AllFamiliars',
  CARERS = 'AllCareers',
  EXTERN = 'AllExternal',
}

type AssignedUser = {
  userID: string
  circleID: string | null
}

export type RowItem = {
  uuid: string
  id: string
  name: string
  birthDate: Date
  cip: string
  dni: string
  circleID: string | null
  roles: string[]
  userCircleID: string
  isPediatric?: boolean
}

export type RolesCheckbox = {
  label: RolesToAssign
  checked: boolean
}

type AssignFormSectionProps = {
  id?: string
  allPatients: User[]
  handlerAssignedUser: (assignedUsers: AssignedUser[]) => void
  handleSaveForm: (saveForm: () => void) => void
  saving: boolean
  mainRef: React.RefObject<HTMLDivElement> | undefined
  usersFormEdit: UserForm[]
  currentCirclePathology: string[] | undefined
  recoveredSelected: (s: RowItem[]) => void
  preview: boolean
  selected: RowItem[]
  handleCheckBoxSelected: (selected: CheckboxAssignType[]) => void
  form: FormDTO
  recoveredCheckBoxRoles: (roles: RolesCheckbox[]) => void
  checkboxRecover: RolesCheckbox[]
}

const arrayOfLetters = ['A-C', 'D-F', 'G-I', 'J-L', 'M-O', 'P-R', 'S-U', 'V-X', 'Y-Z']

export function AssignFormSection({
  id,
  handlerAssignedUser,
  handleSaveForm,
  saving,
  mainRef,
  usersFormEdit,
  recoveredSelected,
  preview,
  selected,
  handleCheckBoxSelected,
  form,
  recoveredCheckBoxRoles,
  checkboxRecover,
}: AssignFormSectionProps) {
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()
  const [searcher, setSearcher] = useState<SearchValue<UserQuery>[]>([
    {
      name: 'allFields',
      label: t('search') + '...',
      value: '',
    },
  ])

  const [checkboxRoles, setCheckboxRoles] = useState<RolesCheckbox[]>(
    checkboxRecover.length > 0
      ? checkboxRecover
      : Object.values(RolesToAssign).map((f) => ({ label: f, checked: false }))
  )
  const [assignedUsers, setAssignedUsers] = useState<AssignedUser[]>([])
  const [allUserCircles, setAllUserCircles] = useState<UserCircleWithRelatersAndCircle[]>([])
  const [custom, setCustom] = useState<boolean>(false)
  const [usersCards, setUsersCards] = useState<UserWithRelatersCustom[]>([])
  const [searchedUsers, setSearchedUsers] = useState<User[]>([])
  const [letterButton, setLetterButton] = useState<boolean[]>([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ])
  const [arrUserByLetter, setArrUserByLetter] = useState<UserCircleWithRelatersAndCircle[]>([])
  const [actualLetter, setActualLetter] = useState<number>(-1)
  const [isLoadingLetter, setIsLoadingLetter] = useState<boolean>(false)
  const [valueSelected, setValueSelected] = useState<boolean>(false)
  const valueSelectedR = useRef(false)

  // Boolean to find out if the user is a extern professional
  const isExternProfessional = loggedUser?.roles?.some((role) => role === 'externProfessional')

  useEffect(() => {
    userCircleService
      .getWichIncludesUserIDWithRelatersAndCircle(loggedUser?.id ?? '')
      .subscribe((res) => {
        setAllUserCircles(res ?? [])
      })
  }, [])

  useEffect(() => {
    if (id) {
      if (form.checkBoxesSelected && form.checkBoxesSelected !== '') {
        setCustom(false)
        const auxArrayCheckBoxes = form.checkBoxesSelected?.split(',')
        const auxArrayCheckBoxesSelected: CheckboxAssignType[] = []
        if (!auxArrayCheckBoxes) return
        auxArrayCheckBoxes.forEach((f) => {
          if (f === CheckboxAssignType.AllMyCircles.toString()) {
            checkboxRoles
              .filter((f) => f.label === RolesToAssign.CIRCLES)
              .map((f) => (f.checked = true))
            checkboxRoles
              .filter((f) => f.label === RolesToAssign.PATIENTS)
              .map((f) => (f.checked = true))
            checkboxRoles
              .filter((f) => f.label === RolesToAssign.FAMILIARS)
              .map((f) => (f.checked = true))
            checkboxRoles
              .filter((f) => f.label === RolesToAssign.EXTERN)
              .map((f) => (f.checked = true))
            checkboxRoles
              .filter((f) => f.label === RolesToAssign.CARERS)
              .map((f) => (f.checked = true))
            auxArrayCheckBoxesSelected.push(CheckboxAssignType.AllMyCircles)
          } else {
            if (f === CheckboxAssignType.AllMyPatients.toString()) {
              checkboxRoles
                .filter((f) => f.label === RolesToAssign.PATIENTS)
                .map((f) => (f.checked = true))
              auxArrayCheckBoxesSelected.push(CheckboxAssignType.AllMyPatients)
            }
            if (f === CheckboxAssignType.AllFamiliars.toString()) {
              checkboxRoles
                .filter((f) => f.label === RolesToAssign.FAMILIARS)
                .map((f) => (f.checked = true))
              auxArrayCheckBoxesSelected.push(CheckboxAssignType.AllFamiliars)
            }
            if (f === CheckboxAssignType.AllExternal.toString()) {
              checkboxRoles
                .filter((f) => f.label === RolesToAssign.EXTERN)
                .map((f) => (f.checked = true))
              auxArrayCheckBoxesSelected.push(CheckboxAssignType.AllExternal)
            }
            if (f === CheckboxAssignType.AllCareers.toString()) {
              checkboxRoles
                .filter((f) => f.label === RolesToAssign.CARERS)
                .map((f) => (f.checked = true))
              auxArrayCheckBoxesSelected.push(CheckboxAssignType.AllCareers)
            }
          }
        })
        handleCheckBoxSelected(auxArrayCheckBoxesSelected)
        recoveredCheckBoxRoles(checkboxRoles)
      } else {
        setCustom(true)
      }
    }
  }, [id, preview])

  useEffect(() => {
    handleSaveForm(async () => await saveForm())
  }, [saving, checkboxRoles])

  useEffect(() => {
    if (!id) {
      setCustom(true)
      return
    }
    userFormService
      .getFilteredList(
        new Query({
          query: [new QueryParam('formID', id)],
        })
      )
      .subscribe((res) => {
        setAssignedUsers(res.items.map((u) => ({ userID: u.userID, circleID: u.circleID })))
      })
  }, [])

  useEffect(() => {
    if (searcher[0].value !== '') {
      const cipas = usersCards.map((u) => u.user.cip)
      const cipasRel = usersCards.map((u) => u.relaters.map((r) => r.relater.cip)).flat()
      const auxCipas = [...cipas, ...cipasRel]

      userService
        .getFilteredList(
          new Query({
            query: [
              {
                name: 'allFields',
                value: auxCipas,
              },
              ...searcherQuery(searcher),
            ],
          })
        )
        .subscribe((res) => {
          if (!res) return
          setSearchedUsers(res.items)
        })
    }
  }, [usersCards, searcher])

  const handlerUsersCards = (users: UserWithRelatersCustom[]) => {
    setUsersCards(users)
  }

  const handleCheckbox = (e: ChangeEvent<HTMLInputElement>, label: string) => {
    setCustom(false)
    if (label === RolesToAssign.CIRCLES) {
      const selected = checkboxRoles.find((r) => r.label === label)?.checked
      setCheckboxRoles(checkboxRoles.map((rs) => ({ label: rs.label, checked: !selected })))
      return
    }
    setCheckboxRoles(
      checkboxRoles.map((rs) => {
        if (rs.label === RolesToAssign.CIRCLES) {
          rs.checked = false
        }
        return { label: rs.label, checked: rs.label === label ? !rs.checked : rs.checked }
      })
    )
  }

  const addUser = (u: RowItem) => {
    // assignedUsers-- esta variable solo se seteare cuando le demos nosotros manualmente click
    // a un elemento en el Card

    const userExists = assignedUsers.some((r) => r.userID === u.id && r.circleID === u.userCircleID)
    // Si el usuario tiene userCircleID y no existe ya en assignedUsers
    if (u.userCircleID && !userExists) {
      setAssignedUsers((prevAssignedUsers) => {
        const uniqueUsers = prevAssignedUsers.filter(
          (r) => !(r.userID === u.id && r.circleID === u.userCircleID)
        )
        return [...uniqueUsers, { userID: u.id, circleID: u.userCircleID }]
      })
    }
  }

  const saveForm = async () => {
    const selectedRoles = checkboxRoles.filter((r) => r.checked).map((r) => r.label)
    const arrayCheckBoxSelected: CheckboxAssignType[] = []

    if (selectedRoles.includes(RolesToAssign.CIRCLES)) {
      arrayCheckBoxSelected.push(CheckboxAssignType.AllMyCircles)
    } else {
      if (selectedRoles.includes(RolesToAssign.PATIENTS)) {
        arrayCheckBoxSelected.push(CheckboxAssignType.AllMyPatients)
      }
      if (selectedRoles.includes(RolesToAssign.FAMILIARS)) {
        arrayCheckBoxSelected.push(CheckboxAssignType.AllFamiliars)
      }
      if (selectedRoles.includes(RolesToAssign.EXTERN)) {
        arrayCheckBoxSelected.push(CheckboxAssignType.AllExternal)
      }
      if (selectedRoles.includes(RolesToAssign.CARERS)) {
        arrayCheckBoxSelected.push(CheckboxAssignType.AllCareers)
      }
    }

    handleCheckBoxSelected(arrayCheckBoxSelected)
  }

  const search: Search<UserQuery> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<UserQuery>[]) => {
      setLetterButton([false, false, false, false, false, false, false, false, false])
      setArrUserByLetter([])
      setValueSelected(false)
      setSearcher(svs)
    },
  }

  useEffect(() => {
    setArrUserByLetter([])
    if (actualLetter !== -1) {
      userCircleService
        .getWichIncludesUserIDWithRelatersAndCircleLetter(loggedUser?.id ?? '', actualLetter)
        .subscribe((res) => {
          if (res) {
            setArrUserByLetter(res)
          }
        })
    }
  }, [actualLetter, isLoadingLetter])

  const handlerLetterBox = (letter: number) => {
    const buttons = letterButton
    for (let i = 0; i < letterButton.length; i++) {
      if (i === letter) {
        buttons[i] = !buttons[i]
        if (buttons[i]) {
          setValueSelected(true)
          valueSelectedR.current = true
          setActualLetter(i)
        } else {
          setValueSelected(false)
          setActualLetter(-1)
        }
      } else {
        buttons[i] = false
        valueSelectedR.current = false
      }
    }
    setLetterButton(buttons)
    setIsLoadingLetter(!isLoadingLetter)
  }

  const letterButtonsComp = () => {
    return (
      <Box className={styles.letterBoxContainer}>
        {arrayOfLetters.map((letter, index) => (
          <Box
            key={letter + uuidv4()}
            className={
              letterButton[index] && searcher[0].value === ''
                ? styles.letterBoxClicked
                : styles.letterBox
            }
            onClick={() => handlerLetterBox(index)}
          >
            <Typography
              key={letter + uuidv4()}
              className={
                letterButton[index] && searcher[0].value === ''
                  ? styles.letterClicked
                  : styles.letter
              }
            >
              {letter}
            </Typography>
          </Box>
        ))}
      </Box>
    )
  }

  useEffect(() => {
    letterButtonsComp()
  }, [letterButton])

  useEffect(() => {
    if (custom) {
      form.checkBoxesSelected = ''
      setCheckboxRoles(Object.values(RolesToAssign).map((f) => ({ label: f, checked: false })))
    }
  }, [custom])

  return (
    <Box className={styles.container}>
      <Typography className={styles.title}>{t('myCircles')}</Typography>
      {!isExternProfessional && (
        <Box style={{ marginBottom: 15 }} className={styles.selectBox}>
          <Box className={styles.selectCheckBoxContent}>
            {checkboxRoles.map((f) => (
              <Box key={f.label + uuidv4()} style={{ minWidth: 214 }}>
                <Checkbox
                  style={{ width: '25px', height: '25px', marginLeft: '10px' }}
                  name={f.label}
                  checked={custom ? false : f.checked}
                  onChange={(e) => handleCheckbox(e, f.label)}
                />
                <p style={{ display: 'inline' }}>{t(f.label)}</p>
              </Box>
            ))}
          </Box>
          <Box className={styles.radioButtonCustom}>
            <Box>
              <RadioGroup>
                <FormControlLabel
                  value="all"
                  control={<Radio color="primary" />}
                  label={<p style={{ fontFamily: 'Poppins' }}>{t('custom')}</p>}
                  labelPlacement="end"
                  checked={custom}
                  onChange={() => {
                    setCustom(true)
                  }}
                />
              </RadioGroup>
              {!custom ? (
                <Tooltip arrow title={t('onlyAvailableCustom')}>
                  <Box
                    onKeyDownCapture={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault()
                      }
                    }}
                  >
                    <Searcher isPreventDefault={true} search={search} disabled={!custom} />
                  </Box>
                </Tooltip>
              ) : (
                <Box
                  onKeyDownCapture={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault()
                    }
                  }}
                >
                  <Searcher isPreventDefault={true} search={search} disabled={!custom} />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      )}

      {custom && (
        <>
          {letterButtonsComp()}
          <Box className={styles.cardsContainer}>
            <Typography className={styles.textSelectUser}>*{t('selectToUserAssign')}</Typography>
            <AssignCard
              userCircles={allUserCircles}
              addUser={addUser}
              handlerAssignedUser={handlerAssignedUser}
              assignedUsers={assignedUsers}
              mainRef={mainRef}
              id={id}
              usersFormEdit={usersFormEdit}
              handlerUsersCards={handlerUsersCards}
              custom={custom}
              searchedUsers={searchedUsers}
              searcher={searcher}
              recoveredSelected={recoveredSelected}
              preview={preview}
              selectedOldState={selected}
              usersLetter={arrUserByLetter}
              selectedValue={valueSelected}
              form={form}
            />
          </Box>
        </>
      )}
    </Box>
  )
}

const searcherQuery = (
  svs: SearchValue<UserQuery>[]
): QueryParam<UserQuery>[] | QueryParamN<UserQuery>[] =>
  svs.filter((sv) => sv.value).map((sv) => ({ name: sv.name, value: sv.value as string }))
