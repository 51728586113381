import { Query } from '../../../common/api/Query'

export type UserFormValueProps = {
  userFormID: string
  formFieldID: string
  values?: string | string[] | Function
  date: Date
  done: boolean
}

export class UserFormValue {
  private readonly _userFormID: string
  private readonly _formFieldID: string
  private readonly _done: boolean
  private _values: string | string[] | Function | undefined
  private _date: Date

  constructor(p: UserFormValueProps) {
    this._userFormID = p.userFormID
    this._formFieldID = p.formFieldID
    this._values = p.values
    this._date = p.date
    this._done = p.done
  }

  get userFormID(): string {
    return this._userFormID
  }

  get formFieldID(): string {
    return this._formFieldID
  }

  get done(): boolean {
    return this._done
  }

  getValues(): string | string[] | Function | undefined {
    if (Array.isArray(this._values)) {
      return [...this._values]
    }

    return this._values
  }

  get date(): Date {
    return this._date
  }

  setValues(value: string | Function) {
    if (!Array.isArray(this._values)) {
      this._values = value
    }
  }

  addValue(value: string) {
    if (Array.isArray(this._values)) {
      this._values.push(value)
    }
  }

  removeCorrectValue(value: string) {
    if (Array.isArray(this._values)) {
      this._values = this._values.filter((v) => v !== value)
    }
  }

  set date(value: Date) {
    this._date = value
  }
}

export class UserFormValueQuery extends Query<UserFormValue> {}
