import { PatientSymptom } from './PatientSymptom'
import { v4 as uuidv4 } from 'uuid'
import {
  PatientSymptomConditionDTO,
  fromModel as conditionFromModel,
  toModel as conditionToModel,
} from './PatientSymptomConditionDTO'
import { SymptomTypes } from '../enums/symptomTypes'

export interface PatientSymptomDTO {
  id: string | undefined
  symptomID: string
  userID: string
  userCircleID: string
  comment: string
  value: string
  idIcon: string
  creatorID: string
  isUploaded: boolean
  symptomStartDate: Date
  finishDate: Date
  symptomType: string
  conditions: PatientSymptomConditionDTO[]
}

export function emptyPatientSymptomDTO() {
  return {
    id: uuidv4(),
    symptomID: '',
    userID: '',
    userCircleID: '',
    symptomStartDate: new Date(),
    finishDate: new Date(),
    symptomType: SymptomTypes.default,
    comment: '',
    value: '',
    idIcon: '',
    creatorID: '',
    isUploaded: false,
    conditions: [],
  }
}

export function fromModel(d: PatientSymptom): PatientSymptomDTO {
  return {
    id: d.id,
    symptomID: d.symptomID,
    userID: d.userID,
    userCircleID: d.userCircleID,
    comment: d.comment,
    value: d.value,
    idIcon: d.idIcon,
    creatorID: d.creatorID,
    isUploaded: d.isUploaded,
    symptomStartDate: new Date(d.symptomStartDate),
    finishDate: new Date(d.finishDate),
    symptomType: d.symptomType,
    conditions: d.conditions.map((c) => conditionFromModel(c)),
  }
}

export function toModel(d: PatientSymptomDTO): PatientSymptom {
  return new PatientSymptom({
    comment: d.comment,
    value: d.value,
    idIcon: d.idIcon,
    symptomID: d.symptomID,
    userID: d.userID,
    userCircleID: d.userCircleID,
    symptomStartDate: new Date(d.symptomStartDate),
    finishDate: new Date(d.finishDate),
    symptomType: d.symptomType,
    id: d.id,
    creatorID: d.creatorID,
    isUploaded: d.isUploaded,
    conditions: d.conditions.map((c) => conditionToModel(c)),
  })
}
