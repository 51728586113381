import { RouteProps } from '../../routes/AppRouter'
import { EvolutionControl as E } from '../../features/patient-data/evolutionControl/EvolutionControl'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'

export function EvolutionControl(props: RouteProps) {
  const title = props.title ?? ''

  return (
    <>
      <CurrentNavHeaderEsfera title={title} />
      <E />
    </>
  )
}
