import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { FormCard } from '../../../components/form-card/FormCard'
import { TextFieldItem } from '../../../components/form-card/TextFieldItem'
import {
  Box,
  FilledTextFieldProps,
  OutlinedTextFieldProps,
  StandardTextFieldProps,
  TextField,
  TextFieldVariants,
} from '@mui/material'
import CustomToolbar from '../../calendar/CustomToolbar'
import { useTranslation } from 'react-i18next'
import { getPatientDataContainer } from '../../../container/patient-data-module'
import { LANDMARK_SERVICE_KEY } from '../../../modules/patient-data'
import { LandMarkService } from '../../../modules/patient-data/services/LandmarkService'
import {
  LandmarkDTO,
  emptyLandMarkDTO,
  fromModel,
} from '../../../modules/patient-data/models/LandmarkDTO'
import { useNavigate } from 'react-router-dom'
import { ROUTE_LANDMARK } from '../../../routes/routes-constants'
import { AppButton, ButtonTheme } from '../../../components/app-button/AppButton'
import genericStyle from '../../../common/utils/generic.module.css'
import { getUserContainer } from '../../../container/user-module'
import { ILoggedUserService } from '../../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../../modules/users'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import MobileDatePicker from '@mui/lab/MobileDatePicker'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { JSX } from 'react/jsx-runtime'

const landmarkContainer = getPatientDataContainer()
const landmarkService = landmarkContainer.get<LandMarkService>(LANDMARK_SERVICE_KEY)

type LandmarkEditorProps = {
  id?: string
}

export function LandmarkEditor(props: LandmarkEditorProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
  const [landmark, setLandmark] = useState<LandmarkDTO>(emptyLandMarkDTO())

  useEffect(() => {
    if (props.id) {
      landmarkService.getByID(props.id).subscribe((res) => {
        if (res) {
          setLandmark(fromModel(res))
        }
      })
    }
  }, [])

  const handleInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLandmark(Object.assign({ ...landmark }, { [e.target.name]: e.target.value }))
  }

  const handleDate = (event: any) => {
    if (event) {
      setLandmark(Object.assign({ ...landmark }, { birthDate: event.toDate() }))
    }
  }

  const goBack = () => navigate(ROUTE_LANDMARK)

  const saveLandmark = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (props.id) {
      landmarkService.update(landmark).subscribe(() => goBack())
    } else {
      const newLandmark = Object.assign({ ...landmark }, { userID: loggedUserService.get()?.id })
      landmarkService.add(newLandmark).subscribe(() => goBack())
    }
  }

  return (
    <Box className={genericStyle.pageContainer}>
      <FormCard>
        <form onSubmit={saveLandmark}>
          <Box mb={3}>
            <TextFieldItem
              field="title"
              value={landmark.title}
              label={'title'}
              type={'text'}
              handleChange={handleInput}
              rows={undefined}
              required={true}
            />
          </Box>
          <Box mb={3}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                key="birthDate"
                value={landmark.date}
                onChange={handleDate}
                renderInput={(
                  params: JSX.IntrinsicAttributes & {
                    variant?: TextFieldVariants
                  } & Omit<
                      FilledTextFieldProps | OutlinedTextFieldProps | StandardTextFieldProps,
                      'variant'
                    >
                ) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label={t('birthDate')}
                    margin="normal"
                    required
                    fullWidth
                  />
                )}
                autoOk
                invalidDateMessage={t('invalidDateMessage')}
                ToolbarComponent={CustomToolbar}
                format="dd/MM/yyyy"
              />
            </LocalizationProvider>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <AppButton
              theme={ButtonTheme.NewSecondary}
              type={'button'}
              label={t('cancel')}
              handler={goBack}
            />
            <AppButton
              theme={ButtonTheme.NewPrimary}
              type={'submit'}
              label={t('save')}
              handler={saveLandmark}
            />
          </Box>
        </form>
      </FormCard>
    </Box>
  )
}
