import { Query } from '../../../common/api/Query'
import { User, UserDTO, toModel } from '../../users/models/User'
import {
  ParticipantConfigDTO,
  toModel as toModelParticipantConfig,
} from './circleConfig/ParticipantConfigDTO'
import { MenuConfig } from './circleConfig/MenuConfig'
import { MenuConfigDTO, toModel as toModelMenuConfig } from './circleConfig/MenuConfigDTO'
import { PatientConfigDTO, toModel as toModelPatientConfig } from './circleConfig/PatientConfigDTO'
import {
  CalendarConfigDTO,
  toModel as toModelCalendarConfig,
} from './circleConfig/CalendarConfigDTO'
import { PatientConfig } from './circleConfig/PatientConfig'
import { CalendarConfig } from './circleConfig/CalendarConfig'
import { ParticipantConfig } from './circleConfig/ParticipantConfig'

export type CircleWithCreatorProps = {
  id: string
  name: string
  specialities: string[]
  symptoms: string[]
  description: string
  createdAt: Date
  createdBy: UserDTO
  participantConfig: ParticipantConfigDTO
  menuConfig: MenuConfigDTO
  patientConfig: PatientConfigDTO
  rolesID: string[]
  calendarConfig: CalendarConfigDTO
  idAssignResources: string
  idAssignForms: string
}

export class CircleWithCreator {
  private readonly _id: string
  private _name: string
  private _specialities: string[]
  private _symptoms: string[]
  private _description: string
  private _createdAt: Date
  private _createdBy: User
  private _participantConfig: ParticipantConfig
  private _menuConfig: MenuConfig
  private _patientConfig: PatientConfig
  private _rolesID: string[]
  private _calendarConfig: CalendarConfig
  private _idAssignResources: string
  private _idAssignForms: string

  constructor(p: CircleWithCreatorProps) {
    this._id = p.id
    this._name = p.name
    this._specialities = p.specialities
    this._symptoms = p.symptoms
    this._description = p.description
    this._createdAt = p.createdAt
    this._createdBy = toModel(p.createdBy)
    this._participantConfig = toModelParticipantConfig(p.participantConfig)
    this._menuConfig = toModelMenuConfig(p.menuConfig)
    this._patientConfig = toModelPatientConfig(p.patientConfig)
    this._rolesID = p.rolesID
    this._calendarConfig = toModelCalendarConfig(p.calendarConfig)
    this._idAssignResources = p.idAssignResources
    this._idAssignForms = p.idAssignForms
  }

  get id(): string {
    return this._id
  }

  get name(): string {
    return this._name
  }

  set name(value: string) {
    this._name = value
  }

  get specialities(): string[] {
    return this._specialities
  }

  set specialities(value: string[]) {
    this._specialities = value
  }

  get description(): string {
    return this._description
  }

  set description(value: string) {
    this._description = value
  }

  get createdAt(): Date {
    return this._createdAt
  }

  set createdAt(value: Date) {
    this._createdAt = value
  }

  get createdBy(): User {
    return this._createdBy
  }

  set createdBy(value: User) {
    this._createdBy = value
  }

  get participantConfig(): ParticipantConfig {
    return this._participantConfig
  }

  set participantConfig(value: ParticipantConfig) {
    this._participantConfig = value
  }

  get menuConfig(): MenuConfig {
    return this._menuConfig
  }

  set menuConfig(value: MenuConfig) {
    this._menuConfig = value
  }

  get patientConfig(): PatientConfig {
    return this._patientConfig
  }

  set patientConfig(value: PatientConfig) {
    this._patientConfig = value
  }

  get rolesID(): string[] {
    return this._rolesID
  }

  set rolesID(value: string[]) {
    this._rolesID = value
  }

  get calendarConfig(): CalendarConfig {
    return this._calendarConfig
  }

  set calendarConfig(value: CalendarConfig) {
    this._calendarConfig = value
  }

  get idAssignResources(): string {
    return this._idAssignResources
  }

  set idAssignResources(value: string) {
    this._idAssignResources = value
  }

  get idAssignForms(): string {
    return this._idAssignForms
  }

  set idAssignForms(value: string) {
    this._idAssignForms = value
  }

  get symptoms(): string[] {
    return this._symptoms
  }

  set symptoms(value: string[]) {
    this._symptoms = value
  }
}

export class CircleWithSpecialtyAndCreatorQuery extends Query<CircleWithCreator> {
  name?: string
  specialtyID?: string
  allFields?: boolean
}
