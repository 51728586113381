import { Container } from '../../common/container/Container'

export type PatientCalendarContainerConfig = {
  moduleFullUrl: string
}

export type PatientCalendarProps = {
  parentContainer: Container
  config: PatientCalendarContainerConfig
}

export const PATIENT_CALENDAR_MODULE = Symbol('PATIENT_CALENDAR_MODULE')

export const PATIENT_CALENDAR_API_KEY = Symbol('PATIENT_CALENDAR_API_KEY')

export const PATIENT_CALENDAR_SERVICE_KEY = Symbol('PATIENT_CALENDAR_SERVICE_KEY')
