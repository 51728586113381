import { Query } from '../../../common/api/Query'

export type ReactionProps = {
  userID: string
  messageID: string
  emoji: string
}

export class Reaction {
  private readonly _userID: string
  private readonly _messageID: string
  private readonly _emoji: string

  constructor(p: ReactionProps) {
    this._userID = p.userID
    this._messageID = p.messageID
    this._emoji = p.emoji
  }

  get userID(): string {
    return this._userID
  }

  get messageID(): string {
    return this._messageID
  }

  get emoji(): string {
    return this._emoji
  }
}

export class ReactionQuery extends Query<Reaction> {}
