import { FamiliarDataDTO } from './FamiliarDataDTO'

export class FamiliarData {
  private readonly _id: string
  private readonly _userID: string
  private readonly _information: string
  private readonly _date: Date

  constructor(p: FamiliarDataDTO) {
    this._id = p.id
    this._userID = p.userID
    this._information = p.information
    this._date = p.date
  }

  get id(): string {
    return this._id
  }

  get userID(): string {
    return this._userID
  }

  get information(): string {
    return this._information
  }

  get date(): Date {
    return this._date
  }
}

export interface FamiliarDataQuery {
  userID: string
}
