import { FormCard } from '../../components/form-card/FormCard'
import React, { FormEvent, useEffect, useState } from 'react'
import { VaccinesService } from '../../modules/vaccines/services/VaccinesService'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { ROUTE_VACCINES } from '../../routes/routes-constants'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { VACCINES_SERVICE_KEY } from '../../modules/vaccines'
import { Box, Alert, TextField } from '@mui/material'
import { getVaccinesContainer } from '../../container/vaccine-module'
import style from './form.module.css'
import { TextFieldItem } from '../../components/form-card/TextFieldItem'
import { emptyVaccinesDTO, VaccinesDTO, fromModel } from '../../modules/vaccines/models/VaccinesDTO'
import { Vaccine } from '../../modules/vaccines/models/Vaccines'

type EditorProps = {
  id?: string
  title: string
}

export function Editor(props: EditorProps) {
  const { t } = useTranslation()
  const vaccinesContainer = getVaccinesContainer()
  const vaccinesService = vaccinesContainer.get<VaccinesService>(VACCINES_SERVICE_KEY)
  const [vaccine, setVaccine] = useState<VaccinesDTO>(emptyVaccinesDTO())
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const goBack = () => navigate(ROUTE_VACCINES)

  useEffect(() => {
    if (props.id) {
      vaccinesService.getByID(props.id).subscribe((res) => {
        if (res) {
          setVaccine(fromModel(res))
        }
      })
    }
  }, [vaccinesService, props.id])

  const validateForm = () => {
    if (vaccine.name === '' || undefined) {
      setErrorMessage('los campos marcados con * son obligatorios')
      return false
    } else if (vaccine.tvac === 0) {
      return false
    }

    setErrorMessage('')
    return true
  }

  const saveVaccine = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!validateForm()) {
      return
    }

    if (props.id) {
      vaccinesService.update(vaccine).subscribe((result) => handleSaveResult(result))
    } else {
      vaccinesService.add(vaccine).subscribe((result) => handleSaveResult(result))
    }
  }

  const handleSaveResult = (result: Vaccine | undefined) => {
    if (result) {
      goBack() // Se agregó o actualizó correctamente
    } else {
      setErrorMessage('El TVAC ingresado ya existe')
    }
  }

  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setVaccine(Object.assign({ ...vaccine }, { [e.target.name]: e.target.value }))

  const handleNumber = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setVaccine(Object.assign({ ...vaccine }, { [e.target.name]: +e.target.value }))

  return (
    <>
      <Box className={style.pageContainer}>
        <h3 className={style.title} style={{ padding: 0 }}>
          {props.title}
        </h3>
        {errorMessage && (
          <Box mb={3}>
            <Alert severity="warning" key="errorMessage" id="errorMessage">
              {t(errorMessage)}
            </Alert>
          </Box>
        )}
        <FormCard>
          <Box mb={3} className={style.row}>
            <label className={style.label}>{t('TVAC')}*</label>
            <Box className={style.box}>
              <TextField
                id={'tvac'}
                key={'tvac'}
                name={'tvac'}
                InputProps={{ inputProps: { min: 1 } }}
                value={vaccine.tvac}
                type={'number'}
                onChange={handleNumber}
                required={true}
                variant="outlined"
              />
            </Box>
          </Box>
        </FormCard>
        <FormCard>
          <Box mb={3} className={style.row}>
            <label className={style.label}>{t('description')}*</label>
            <Box className={style.box}>
              <TextFieldItem
                field="name"
                value={vaccine.name}
                label={''}
                type={'text'}
                handleChange={handleInput}
                rows={undefined}
                required={true}
              />
            </Box>
          </Box>
        </FormCard>
        <FormCard>
          <Box mb={3} className={style.row} style={{ alignItems: 'flex-start' }}>
            <label className={style.label}>{t('observations')}</label>
            <Box className={style.box}>
              <TextFieldItem
                field="description"
                value={vaccine.description ?? ''}
                label={''}
                type={'text'}
                handleChange={handleInput}
                rows={3}
                required={false}
              />
            </Box>
          </Box>
        </FormCard>
        <FormCard>
          <Box className={style.boxButtons}>
            <div className={style.div}>
              <AppButton
                theme={ButtonTheme.NewPrimary}
                type={'submit'}
                label={t('accept')}
                handler={saveVaccine}
              />
            </div>
            <AppButton
              theme={ButtonTheme.NewSecondary}
              type={'button'}
              label={t('cancel')}
              handler={goBack}
            />
          </Box>
        </FormCard>
      </Box>
    </>
  )
}
