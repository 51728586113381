import { Container, IInit } from '../../../common/container/Container'
import { MessengerConsultaContainerConfig } from '../container'
import { Observable, of } from 'rxjs'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { catchError, map } from 'rxjs/operators'
import { fromModel, toModel, ProfessionalQueryDTO } from '../models/ProfessionalQueryDTO'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { ProfessionalQuery } from '../models/ProfessionalQuery'

export interface IProfessionalQueryApi extends IInit {
  getByID(id: string): Observable<ProfessionalQuery | undefined>
  getByUserID(id: string): Observable<ProfessionalQuery | undefined>

  add(e: ProfessionalQuery): Observable<ProfessionalQuery | undefined>

  update(e: ProfessionalQuery): Observable<ProfessionalQuery | undefined>

  delete(e: ProfessionalQuery): Observable<boolean>
}

export class ProfessionalQueryApi implements IProfessionalQueryApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url =
      (this._container.config as MessengerConsultaContainerConfig).moduleFullUrl +
      '/professional_consulta'
  }

  add(e: ProfessionalQuery): Observable<ProfessionalQuery | undefined> {
    return this._httpClient.post<ProfessionalQuery>({ url: this._url, body: fromModel(e) }).pipe(
      map<ProfessionalQueryDTO, ProfessionalQuery>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(e: ProfessionalQuery): Observable<boolean> {
    return this._httpClient.delete({ url: this._url, body: fromModel(e) }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string): Observable<ProfessionalQuery | undefined> {
    return this._httpClient.get<ProfessionalQuery>({ url: `${this._url}/${id}` }).pipe(
      map<ProfessionalQueryDTO, ProfessionalQuery>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getByUserID(id: string): Observable<ProfessionalQuery | undefined> {
    return this._httpClient.get<ProfessionalQuery>({ url: `${this._url}/byUserID/${id}` }).pipe(
      map<ProfessionalQueryDTO, ProfessionalQuery>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  update(e: ProfessionalQuery): Observable<ProfessionalQuery | undefined> {
    return this._httpClient.put<ProfessionalQuery>({ url: this._url, body: fromModel(e) }).pipe(
      map<ProfessionalQueryDTO, ProfessionalQuery>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }
}
