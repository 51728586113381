import React, { useState } from 'react'
import { Action, Search, SearchValue } from '../table_type/types'
import { Box } from '@mui/material'
import TextField from '@mui/material/TextField'

type SearchProps<Q> = {
  readonly search: Search<Q>
  readonly autocompleteItems?: string[]
  readonly autocompleteLabel?: string
  readonly autocompleteAction?: Action
  disabled?: boolean
  isPreventDefault?: boolean
}

export function Searcher<Q extends Record<string, any>>(props: SearchProps<Q>) {
  const [values, setValues] = useState<SearchValue<Q>[]>(props.search.searchValues)

  const handleSubmit = (e: React.FormEvent<HTMLDivElement>) => {
    if (props.isPreventDefault) {
      e.preventDefault()
    }
    props.search.handleSearch([...values])
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    values.find((sv) => e.target.name === sv.name)!.value = e.target.value
    setValues([...values])
    props.search.handleSearch([...values])
  }

  const getWidthSize = () => {
    if(window.innerWidth < 378){
        return "120px";
    }
    if(window.innerWidth < 490){
      return "150px"
    }
    return "190px";
  }

  return (
    <Box marginBottom="5px">
      <Box display="flex" justifyContent={'flex-end'} flexWrap="wrap">
        {values.map((v) => (
          <Box
            mr={2}
            width={v.width}
            marginBottom={'5px'}
            marginRight={'5px'}
            key={v.name as string}
            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                margin: '0 10px 0 0',
              }}
            >
              Buscar
            </Box>
            <TextField
              disabled={props.disabled}
              name={v.name as string}
              // label={v.label}
              style={{ width: getWidthSize(), fontFamily: 'Roboto-Light' }}
              onChange={handleChange}
              type={v.type ?? 'text'}
              value={v.value ?? ''}
              variant="outlined"
              size="small"
              onKeyDown={(event) => {
                if (event.keyCode === 13) {
                  handleSubmit(event)
                }
              }}
            />
          </Box>
        ))}
      </Box>
    </Box>
  )
}
