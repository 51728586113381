import { Query } from '../../../common/api/Query'
import { PatientConfig } from './circleConfig/PatientConfig'
import { MenuConfig } from './circleConfig/MenuConfig'
import { ParticipantConfig } from './circleConfig/ParticipantConfig'
import { CalendarConfig } from './circleConfig/CalendarConfig'
import { ParticipantConfigDTO, toModel } from './circleConfig/ParticipantConfigDTO'
import { MenuConfigDTO, toModel as toModel2 } from './circleConfig/MenuConfigDTO'
import { PatientConfigDTO, toModel as toModel3 } from './circleConfig/PatientConfigDTO'
import { CalendarConfigDTO, toModel as toModel4 } from './circleConfig/CalendarConfigDTO'
import { QueryConfigDTO, toModel as toModel5 } from './circleConfig/QueryConfigDTO'
import { QueryConfig } from './circleConfig/QueryConfig'
import { SymptomConfig } from './circleConfig/SymptomConfig'

export type CircleProps = {
  id: string
  name: string
  specialities: string[]
  symptoms: SymptomConfig[]
  description: string
  createdAt: Date
  createdBy: string
  participantConfig: ParticipantConfigDTO
  menuConfig: MenuConfigDTO
  queryConfig: QueryConfigDTO
  patientConfig: PatientConfigDTO
  calendarConfig: CalendarConfigDTO
  idAssignResources: string
  idAssignForms: string
}

export class Circle {
  private readonly _id: string
  private _name: string
  private _specialities: string[]
  private _symptoms: SymptomConfig[]
  private _description: string
  private _createdAt: Date
  private _createdBy: string
  private _participantConfig: ParticipantConfig
  private _menuConfig: MenuConfig
  private _queryConfig: QueryConfig
  private _patientConfig: PatientConfig
  private _calendarConfig: CalendarConfig
  private _idAssignResources: string
  private _idAssignForms: string

  constructor(p: CircleProps) {
    this._id = p.id
    this._name = p.name
    this._specialities = p.specialities
    this._symptoms = p.symptoms
    this._description = p.description
    this._createdAt = p.createdAt
    this._createdBy = p.createdBy
    this._participantConfig = toModel(p.participantConfig)
    this._menuConfig = toModel2(p.menuConfig)
    this._queryConfig = toModel5(p.queryConfig)
    this._patientConfig = toModel3(p.patientConfig)
    this._calendarConfig = toModel4(p.calendarConfig)
    this._idAssignResources = p.idAssignResources
    this._idAssignForms = p.idAssignForms
  }

  get id(): string {
    return this._id
  }

  get name(): string {
    return this._name
  }

  set name(value: string) {
    this._name = value
  }

  get specialities(): string[] {
    return this._specialities
  }

  set specialities(value: string[]) {
    this._specialities = value
  }

  get description(): string {
    return this._description
  }

  set description(value: string) {
    this._description = value
  }

  get createdAt(): Date {
    return this._createdAt
  }

  set createdAt(value: Date) {
    this._createdAt = value
  }

  get createdBy(): string {
    return this._createdBy
  }

  set createdBy(value: string) {
    this._createdBy = value
  }

  get participantConfig(): ParticipantConfig {
    return this._participantConfig
  }

  set participantConfig(value: ParticipantConfig) {
    this._participantConfig = value
  }

  get menuConfig(): MenuConfig {
    return this._menuConfig
  }

  set menuConfig(value: MenuConfig) {
    this._menuConfig = value
  }

  get queryConfig(): QueryConfig {
    return this._queryConfig
  }

  set queryConfig(value: QueryConfig) {
    this._queryConfig = value
  }

  get patientConfig(): PatientConfig {
    return this._patientConfig
  }

  set patientConfig(value: PatientConfig) {
    this._patientConfig = value
  }

  get calendarConfig(): CalendarConfig {
    return this._calendarConfig
  }

  set calendarConfig(value: CalendarConfig) {
    this._calendarConfig = value
  }

  get idAssignResources(): string {
    return this._idAssignResources
  }

  set idAssignResources(value: string) {
    this._idAssignResources = value
  }

  get idAssignForms(): string {
    return this._idAssignForms
  }

  set idAssignForms(value: string) {
    this._idAssignForms = value
  }

  get symptoms(): SymptomConfig[] {
    return this._symptoms
  }

  set symptoms(value: SymptomConfig[]) {
    this._symptoms = value
  }
}

export class CircleQuery extends Query<Circle> {
  id?: string
  specialtyID?: string
}
