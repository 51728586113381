import { Observable } from 'rxjs'
import { UserConfig } from '../models/UserConfig'
import { Query } from '../../../common/api/Query'
import { Container, IInit } from '../../../common/container/Container'
import { IUserConfigApi } from '../api/UserConfigApi'
import { IStatusService } from '../../../common/status/StatusService'
import { ItemList } from '../../../common/models/ItemList'
import { STATUS_SERVICE_KEY } from '../../../container/app'

type Props = {
  apiKey: symbol
}

export interface IUserConfigService extends IInit {
  getByID(id: string): Observable<UserConfig | undefined>

  getListByUserID(id: string): Observable<ItemList<UserConfig>>

  getFilteredItems(q: Query<UserConfig>): Observable<UserConfig[]>

  setConfigs(id: string, ucs: UserConfig[]): Observable<UserConfig[] | undefined>

  add(e: UserConfig): Observable<UserConfig | undefined>

  update(e: UserConfig): Observable<UserConfig | undefined>

  delete(e: UserConfig): Observable<boolean>
}

export class UserConfigService implements IUserConfigService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IUserConfigApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IUserConfigApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: UserConfig): Observable<UserConfig | undefined> {
    return this._api.add(e)
  }

  delete(e: UserConfig): Observable<boolean> {
    return this._api.delete(e)
  }

  getByID(id: string): Observable<UserConfig | undefined> {
    return this._api.getByID(id)
  }

  getListByUserID(id: string): Observable<ItemList<UserConfig>> {
    return this._api.getListByUserID(id)
  }

  getFilteredItems(q: Query<UserConfig>): Observable<UserConfig[]> {
    return this._api.getFilteredItems(q).pipe()
  }

  update(e: UserConfig): Observable<UserConfig | undefined> {
    return this._api.update(e)
  }

  setConfigs(id: string, ucs: UserConfig[]): Observable<UserConfig[] | undefined> {
    return this._api.setConfigs(id, ucs)
  }
}
