import { Query } from '../../../common/api/Query'

export type ContactProps = {
  userID: string
  relatedUserID: string
}

export class Contact {
  private readonly _userID: string
  private readonly _relatedUserID: string

  constructor(p: ContactProps) {
    this._userID = p.userID
    this._relatedUserID = p.relatedUserID
  }

  get userID(): string {
    return this._userID
  }

  get relatedUserID(): string {
    return this._relatedUserID
  }
}

export class ContactQuery extends Query<Contact> {}
