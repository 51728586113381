export enum TreatmentAdministration {
  HOME,
  HOSPITAL,
  PICK_HOSPITAL_AND_HOME,
}

export type TreatmentProps = {
  id: string | undefined
  name: string
  date: Date
  endDate: Date | undefined
  duration: number
  frequency: number
  actualDose: number
  newDose: number | undefined
  actualDoseUnit: string
  newDoseUnit: string | undefined
  changeOfDose: number
  reason: string | undefined
  creatorID: string
  userCircleID: string
  administrationMethod: number
  reminderTime: number
  reminderApp: boolean
  reminderEmail: boolean
  reminderSMS: boolean
  currently: boolean
}

export class Treatment {
  private readonly _id: string | undefined
  private _name: string
  private _date: Date
  private _endDate: Date | undefined
  private _duration: number
  private _frequency: number
  private _actualDose: number
  private _newDose: number | undefined
  private _actualDoseUnit: string
  private _newDoseUnit: string | undefined
  private _changeOfDose: number
  private _creatorID: string
  private _userCircleID: string
  private _administrationMethod: number
  private _reason: string | undefined
  private _reminderTime: number
  private _reminderApp: boolean
  private _reminderEmail: boolean
  private _reminderSMS: boolean
  private _currently: boolean

  constructor(p: TreatmentProps) {
    this._id = p.id
    this._name = p.name
    this._date = p.date
    this._endDate = p.endDate
    this._duration = p.duration
    this._frequency = p.frequency
    this._actualDose = p.actualDose
    this._newDose = p.newDose
    this._actualDoseUnit = p.actualDoseUnit
    this._newDoseUnit = p.newDoseUnit
    this._changeOfDose = p.changeOfDose
    this._reason = p.reason
    this._creatorID = p.creatorID
    this._userCircleID = p.userCircleID
    this._administrationMethod = p.administrationMethod
    this._reminderTime = p.reminderTime
    this._reminderApp = p.reminderApp
    this._reminderEmail = p.reminderEmail
    this._reminderSMS = p.reminderSMS
    this._currently = p.currently
  }

  get id(): string | undefined {
    return this._id
  }

  get name(): string {
    return this._name
  }

  get date(): Date {
    return this._date
  }

  get endDate(): Date | undefined {
    return this._endDate
  }

  get duration(): number {
    return this._duration
  }

  get frequency(): number {
    return this._frequency
  }

  get actualDose(): number {
    return this._actualDose
  }

  get newDose(): number | undefined {
    return this._newDose
  }

  get actualDoseUnit(): string {
    return this._actualDoseUnit
  }

  get newDoseUnit(): string | undefined {
    return this._newDoseUnit
  }

  get administrationMethod(): number {
    return this._administrationMethod
  }

  get changeOfDose(): number {
    return this._changeOfDose
  }

  get reason(): string | undefined {
    return this._reason
  }

  set reason(value: string | undefined) {
    this._reason = value
  }

  get creatorID(): string {
    return this._creatorID
  }

  get userCircleID(): string {
    return this._userCircleID
  }

  get reminderTime(): number {
    return this._reminderTime
  }

  set reminderTime(value: number) {
    this._reminderTime = value
  }

  get reminderApp(): boolean {
    return this._reminderApp
  }

  set reminderApp(value: boolean) {
    this._reminderApp = value
  }

  get reminderEmail(): boolean {
    return this._reminderEmail
  }

  set reminderEmail(value: boolean) {
    this._reminderEmail = value
  }

  get reminderSMS(): boolean {
    return this._reminderSMS
  }

  set reminderSMS(value: boolean) {
    this._reminderSMS = value
  }

  get currently(): boolean {
    return this._currently
  }
}

export interface TreatmentQuery {
  name: string
  user: string
  userCircleID: string
  date: Date
}
