import { Box, FormControlLabel, Modal, Radio, RadioGroup } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { AppTable, Field } from '../../../components/table'
import { useNavigate } from 'react-router-dom'
import { AppButton, ButtonTheme } from '../../../components/app-button/AppButton'
import { Actions, Pager } from '../../../components/table_type/types'
import { useTranslation } from 'react-i18next'
import {
  ROUTE_CONFIGURATION,
  ROUTE_CREATE,
  ROUTE_DENTIST_FORM,
  ROUTE_DENTIST_MAP,
} from '../../../routes/routes-constants'
import { BoolQueryParam, Query } from '../../../common/api/Query'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { Dentist } from '../../../modules/dentists/models/Dentist'
import { DentistService } from '../../../modules/dentists/services/DentistService'
import { DENTIST_SERVICE_KEY } from '../../../modules/dentists'
import { reduceString } from '../../../common/utils/strings'
import { dateToDateString } from '../../../common/utils/date'
import { getDentistContainer } from '../../../container/dentist-module'
import genericStyle from '../../../common/utils/generic.module.css'
import { downloadFile } from '../../../common/utils/file'
import editIcon from '../../../assets/table_icons/ico-edit.svg'
import deleteIcon from '../../../assets/table_icons/ico-eliminar.svg'
import downloadIcon from '../../../assets/table_icons/ico-download.svg'
import { LOGGED_USER_SERVICE_KEY } from '../../../modules/users'
import { LoggedUserService } from '../../../modules/users/services/LoggedUserService'
import { Permission } from '../../../common/permission'
import { getUserContainer } from '../../../container/user-module'
import { CustomModal } from 'components/modal/CustomModal'

const userContainer = getUserContainer()
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

enum DentistType {
  PUBLIC = 'public',
  PRIVATE = 'private',
}

const dentistContainer = getDentistContainer()
const dentistService = dentistContainer.get<DentistService>(DENTIST_SERVICE_KEY)

export function Table() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [count, setCount] = useState<number>(0)
  const [dentists, setDentist] = useState<ItemList<Dentist>>(emptyList<Dentist>())
  const [dentistsPerPage, setDentistsPerPage] = useState<number>(10)
  const [page, setPage] = useState<number>(0)
  const [pager, setPager] = useState<Pager>()
  const [isPublic, setIsPublic] = useState<boolean>(true)
  const [response, setResponse] = useState<string>('')
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [currentDestist, setCurrentDestist] = useState<Dentist>()

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: dentistsPerPage,
      handleChangeRowsPerPage,
    })
    getData()
  }, [page, count, dentistsPerPage, isLoading])

  const getData = () => {
    dentistService
      .getFilteredList(
        new Query({
          pager: { limit: dentistsPerPage, offset: page * dentistsPerPage },
          query: [{ name: 'isPublic', value: new BoolQueryParam(isPublic) }],
          sort: [{ field: 'name' }],
        })
      )
      .subscribe((res) => {
        setCount(res.count)
        setDentist(res)
        setIsLoading(false)
      })
  }

  const handleType = (v: string) => {
    setIsPublic(v === DentistType.PUBLIC)
    setPage(0)
    setIsLoading(true)
  }

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setDentistsPerPage(10)
      return
    }
    setDentistsPerPage(Number.parseInt(event.target.value))
  }

  const goBack = () =>
    navigate(
      loggedUserService.userCan(Permission.configureDentists)
        ? ROUTE_CONFIGURATION
        : ROUTE_DENTIST_MAP
    )

  const createDentist = () => navigate(`${ROUTE_DENTIST_FORM}/${ROUTE_CREATE}`)

  const editDentist = (s: Dentist) => navigate(`${ROUTE_DENTIST_FORM}/${s.id}`)

  const removeDentist = (s: Dentist) => {
    setCurrentDestist(s)
    setOpenDeleteModal(true)
  }

  const generatePDF = () => {
    dentistService.createPDF().subscribe((res) => {
      setResponse(res)
    })
  }

  const download = () => {
    if (!response) {
      return
    }
    const name = `${t('dentists').toLowerCase()}-${dateToDateString(new Date())}`
    downloadFile(name, 'application/pdf', response)
  }

  const fields: Field<Dentist>[] = [
    {
      name: 'name',
      label: t('name'),
    },
    {
      name: 'phone',
      label: t('phone'),
    },
    {
      name: 'email',
      label: t('email'),
    },
    {
      name: 'longitude',
      label: t('longitude'),
    },
    {
      name: 'latitude',
      label: t('latitude'),
    },
    {
      name: 'web',
      label: t('web'),
    },
    {
      name: 'isPublic',
      label: t('isPublic'),
      renderFunc: (f, i) => (i.isPublic ? t('yes') : t('no')),
    },
    {
      name: 'information',
      label: t('information'),
      renderFunc: (f, i) => reduceString(i.information, 100),
    },
  ]

  const actions: Actions<Dentist> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: editDentist,
        icon: editIcon,
        label: 'edit',
      },
      {
        handler: removeDentist,
        icon: deleteIcon,
        label: 'delete',
      },
    ],
  }

  const emptyActions: Actions<Dentist> = { items: [] }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false)
  }

  const handleDeleteDentist = () => {
    if (currentDestist)
      if (currentDestist.id) {
        dentistService.delete(currentDestist.id).subscribe((_) => setIsLoading(!isLoading))
      }
    setOpenDeleteModal(false)
    setIsLoading(true)
  }

  return (
    <Box className={genericStyle.pageContainer}>
      <Box mb={3} display="flex" justifyContent="space-between">
        <AppButton
          theme={ButtonTheme.NewSecondary}
          type={'button'}
          label={t('back')}
          handler={goBack}
        />
        {loggedUserService.userCan(Permission.configureDentists) && (
          <AppButton
            theme={ButtonTheme.NewPrimary}
            type={'button'}
            label={t('addDentist')}
            handler={createDentist}
          />
        )}
      </Box>
      <Box>
        <h4 style={{ display: 'inline', marginRight: '10px' }}>{t('selectType')}: </h4>
        <RadioGroup
          style={{ display: 'inline' }}
          key="type"
          name="type"
          onChange={(_, v) => handleType(v)}
        >
          <FormControlLabel
            name="public"
            value="public"
            control={<Radio />}
            checked={isPublic}
            label={t(DentistType.PUBLIC)}
            labelPlacement={'end'}
          />
          <FormControlLabel
            name="private"
            value="private"
            checked={!isPublic}
            control={<Radio />}
            label={t(DentistType.PRIVATE)}
            labelPlacement={'end'}
          />
        </RadioGroup>
      </Box>
      <Box>
        <Modal open={openDeleteModal} onClose={handleCloseDeleteModal}>
          <CustomModal
            handleClose={handleCloseDeleteModal}
            handleSave={handleDeleteDentist}
            title={t('deleteDentist')}
            warningText={t('irreversibleDentistAction')}
          ></CustomModal>
        </Modal>
        <AppTable
          items={dentists.items}
          rowKeyField="id"
          fields={fields}
          actions={loggedUserService.userCan(Permission.configureDentists) ? actions : emptyActions}
          pager={pager}
        />
      </Box>
      <Box mt={3}>
        <AppButton
          theme={ButtonTheme.NewPrimary}
          handler={!response ? generatePDF : download}
          label={!response ? t('generatePDF') : t('downloadPDF')}
          type={'button'}
          endIcon={response ? downloadIcon : ''}
        />
      </Box>
    </Box>
  )
}
