import React, { useEffect, useState } from 'react'
import { Avatar, Box, Dialog, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import PersonIcon from '@mui/icons-material/Person'
import style from './Header.module.css'
import { useTranslation } from 'react-i18next'
import { getUserContainer } from '../../container/user-module'
import { UserService } from '../../modules/users/services/UserService'
import { LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { User, UserQuery } from '../../modules/users/models/User'
import { GenericTooltip } from '../../components/generic-tooltip'
import { Query, QueryParam } from '../../common/api/Query'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'

const userContainer = getUserContainer()
const usersService = userContainer.get<UserService>(USER_SERVICE_KEY)

export type ConversationHeaderProps = {
  headerName: string
  avatarUrl?: string
  creatorID: string
  description: string
  handleEdit: () => void
  handleDelete: () => void
  conversationUsers: string[]
}
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

export function Header(props: ConversationHeaderProps): JSX.Element {
  const loggedUser = loggedUserService.get()
  const { t } = useTranslation()

  const [conversationCreator, setConversationCreator] = useState<User>()
  const [conversationUsers, setConversationsUsers] = useState<User[]>([])
  const [openPopup, setOpenPopup] = useState<boolean>(false)

  useEffect(() => {
    usersService.getByID(props.creatorID).subscribe((res) => {
      setConversationCreator(res)
    })
    usersService
      .getFilteredList(
        new Query({ query: [new QueryParam<UserQuery>('ids', props.conversationUsers)] })
      )
      .subscribe((res) => setConversationsUsers(res.items))
  }, [props.creatorID, props.conversationUsers])

  const changeOpenPopup = () => {
    setOpenPopup(!openPopup)
  }

  const handleEditConversation = () => {
    changeOpenPopup()
    props.handleEdit()
  }

  const handleDeleteConversation = () => {
    changeOpenPopup()
    props.handleDelete()
  }

  const handleClosePopup = () => {
    changeOpenPopup()
  }

  return (
    <Box
      className={style.divHeader}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box className={style.divHeaderLeft} display="flex" alignItems="center">
        <Avatar src={props.avatarUrl} style={{ color: '#d2e2ee', backgroundColor: '#FFFFFF' }} />
        <div>
          <Box display="flex" alignItems="baseline">
            <Box className={style.contactFullName} component="h2">
              {props.headerName}
            </Box>
          </Box>
        </div>
      </Box>
      <Box className={style.conversationLeft} display="flex">
        <Box className={style.conversationOptions} display="flex">
          <div>
            <GenericTooltip
              values={conversationUsers.map((u) => u.name)}
              icon={<PersonIcon color={'primary'} />}
              noUseFab={true}
            />
          </div>
          <>
            {conversationCreator?.id === loggedUser?.id ? (
              <div>
                <MoreVertIcon color={'primary'} cursor="pointer" onClick={changeOpenPopup} />
              </div>
            ) : null}
          </>
        </Box>
        <Box component="p">{t(`chatOpenedBy`) + ' ' + conversationCreator?.name}</Box>
      </Box>
      <HeaderPopup
        open={openPopup}
        handleEdit={handleEditConversation}
        handleDelete={handleDeleteConversation}
        handleClosePopup={handleClosePopup}
      />
    </Box>
  )
}

type HeaderPopupProps = {
  open: boolean
  handleEdit: () => void
  handleDelete: () => void
  handleClosePopup: () => void
}

function HeaderPopup(props: HeaderPopupProps): JSX.Element {
  const { t } = useTranslation()

  return (
    <Dialog
      onClose={props.handleClosePopup}
      aria-labelledby="header-popup-conversation"
      open={props.open}
    >
      <List>
        <ListItem button onClick={props.handleEdit}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText primary={t('edit')} />
        </ListItem>
        <ListItem button onClick={props.handleDelete}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText primary={t('delete')} />
        </ListItem>
      </List>
    </Dialog>
  )
}
