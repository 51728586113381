import { Box } from '@mui/material'
import React from 'react'
import {
  ROUTE_DENTIST_CONFIG,
  ROUTE_NOTIFICATIONS_CONFIG,
  ROUTE_PATIENT_CALENDAR_CONFIG,
  ROUTE_CHANGE_PASSWORD,
  ROUTE_QUERY_CONFIG,
  ROUTE_SPORTCENTRE_CONFIG,
  ROUTE_CITY_HALL_CONFIG,
  ROUTE_LEGAL_ADVICE,
} from '../../routes/routes-constants'
import { Link, useNavigate } from 'react-router-dom'
import notifications from '../../assets/configuration/notifications.svg'
import changePassword from '../../assets/configuration/changePassword.svg'
import patientData from '../../assets/left_menu/circulos.svg'
import calendar from '../../assets/left_menu/calendar.svg'
import legalAdvice from '../../assets/configuration/legalAdvice.svg'
import consulta from '../../assets/left_menu/icono-consulta.svg'
import { useTranslation } from 'react-i18next'
import { version } from '../../../package.json'

import style from './Configuration.module.css'
import { getUserContainer } from '../../container/user-module'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { Permission } from 'common/permission'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'

export type ConfigurationProps = {}
const userContainer = getUserContainer()
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

export function Configuration(props: ConfigurationProps): JSX.Element {
  const { t } = useTranslation()
  const externalPass = process.env.REACT_APP_EXTERNAL_PASS
  const isMobile = window.innerWidth < 768

  return (
    <Box className={style.configurationContainer}>
      <Box className={style.configurationList}>
        {externalPass === 'true' && (
          <ConfigurationItemLink
            imgSrc={changePassword}
            label="Cambiar contraseña"
            route={ROUTE_CHANGE_PASSWORD}
            addSeparator={true}
          />
        )}
        {loggedUserService.userCan(Permission.configureNotifications) && (
          <ConfigurationItemLink
            imgSrc={notifications}
            label={t('notificationsConfig')}
            route={ROUTE_NOTIFICATIONS_CONFIG}
            addSeparator={true}
          />
        )}
        {loggedUserService.userCan(Permission.configurePatientCalendar) && (
          <ConfigurationItemLink
            imgSrc={calendar}
            label={t('patientCalendarConfig')}
            route={ROUTE_PATIENT_CALENDAR_CONFIG}
            addSeparator={true}
          />
        )}
        {loggedUserService.userCan(Permission.configureDentists) && (
          <ConfigurationItemLink
            imgSrc={patientData}
            label={t('dentistConfig')}
            route={ROUTE_DENTIST_CONFIG}
            addSeparator={true}
          />
        )}
        {loggedUserService.userCan(Permission.configurePatientCalendar) && (
          <ConfigurationItemLink
            imgSrc={consulta}
            label={t('configQuery')}
            route={ROUTE_QUERY_CONFIG}
            addSeparator={true}
          />
        )}
        {loggedUserService.userCan(Permission.configActivePlans) && (
          <>
            <ConfigurationItemLink
              imgSrc={consulta}
              label={t('sportCentreConfig')}
              route={ROUTE_SPORTCENTRE_CONFIG}
              addSeparator={true}
            />
            <ConfigurationItemLink
              imgSrc={consulta}
              label={t('cityHallConfig')}
              route={ROUTE_CITY_HALL_CONFIG}
              addSeparator={true}
            />
          </>
        )}
        <ConfigurationItemLink
          imgSrc={legalAdvice}
          label={t('legalAdvice')}
          route={ROUTE_LEGAL_ADVICE}
          addSeparator={true}
        />
        {!isMobile && <h3 className={style.version}>Version {version}</h3>}
      </Box>
    </Box>
  )
}

type ConfigurationItemLinkProps = {
  route: string
  label: string
  imgSrc: string
  addSeparator?: boolean
}

function ConfigurationItemLink(props: ConfigurationItemLinkProps): JSX.Element {
  const isMobile = window.innerWidth < 768
  const navigate = useNavigate()

  return isMobile ? (
    <>
      <AppButton
        label={props.label}
        theme={ButtonTheme.NewPrimary}
        handler={() => {
          navigate(props.route)
        }}
        type={'button'}
        fullWidth={true}
      />
    </>
  ) : (
    <>
      <Link className={style.configurationLink} to={props.route} id={props.route}>
        <Box className={style.configurationItem} display="flex" alignItems="center">
          <img className={style.icon} src={props.imgSrc} alt={props.imgSrc} />
          <h3 className={style.configurationItemLabel}>{props.label}</h3>
        </Box>
      </Link>
      {props.addSeparator ? <hr className={style.configurationSeparator} /> : null}
    </>
  )
}
