import { Autocomplete, Box, Chip, Switch, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import style from './ConversationEditor.module.css'
import { Conversation } from '../../modules/messenger/models/Conversation'
import { getUserContainer } from '../../container/user-module'
import {
  LOGGED_USER_SERVICE_KEY,
  ROLES_SERVICE_KEY,
  USER_SERVICE_KEY,
  IUserService,
} from '../../modules/users'
import { BoolQueryParam, Query, QueryParam } from '../../common/api/Query'
import { User, UserQuery } from '../../modules/users/models/User'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import {
  ConversationDTO,
  emptyConversationDTO,
  emptyConversationUserDTO,
  fromModel,
  toModel,
} from '../../modules/messenger/models/ConversationDTO'
import professionalSMS from '../../assets/role_icons/ico-rol-profesional.svg'
import patient from '../../assets/role_icons/ico-rol-paciente.svg'
import career from '../../assets/role_icons/ico-rol-cuidador.svg'
import family from '../../assets/role_icons/ico-rol-familiar.svg'
import externProfessional from '../../assets/role_icons/ico-rol-externo.svg'
import { Header } from '../../components/header/Header'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { forkJoin, Observable } from 'rxjs'
import { Roles } from '../../modules/users/enums/Roles'
import { RolesService } from '../../modules/users/services/RolesServices'
import styles from '../user-profile/Editor.module.css'

import { useGetActiveUser } from '../../hooks/get-active-user/useGetActiveUser'
import { getUserCircleContainer } from '../../container/user-circle-module'
import { UserCircleService } from '../../modules/user-circle/services/UserCircleConfigService'
import { USER_CIRCLE_SERVICE_KEY } from '../../modules/user-circle'
import { ConversatonUserGender } from '../../modules/messenger/models/ConversationUser'
import close from '../../assets/esfera/buttons/delete.svg'

type NewConversationProps = {
  conversation?: Conversation
  handleClose: () => void
  handleSave: (c: Conversation, isUpdate: boolean) => void
}

type AutocompleteUserInterface = {
  key: string
  value: string
  role: string
  gender: ConversatonUserGender
}

const loggedUserContainer = getUserContainer()
const loggedUserService = loggedUserContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)
const roleService = getUserContainer().get<RolesService>(ROLES_SERVICE_KEY)
const userCircleService = getUserCircleContainer().get<UserCircleService>(USER_CIRCLE_SERVICE_KEY)

export const ConversationEditor = (props: NewConversationProps): JSX.Element => {
  const { t } = useTranslation()
  const { activeUser } = useGetActiveUser()
  const [videoconference, setVideoConference] = useState<boolean>(false)
  const loggedUser = loggedUserService.get()
  const [conversation, setConversation] = useState<ConversationDTO>(
    props.conversation
      ? fromModel(props.conversation)
      : emptyConversationDTO(
          loggedUser?.id ?? '',
          activeUser?.circle.id ?? '',
          activeUser?.id ?? ''
        )
  )
  const [usersMap, setUsersMap] = useState<Map<string, AutocompleteUserInterface>>(new Map())
  const [selectedUsers, setSelectedUsers] = useState<AutocompleteUserInterface[]>([])
  const [circleUser, setCircleUser] = useState<User>()
  const [selectedUsersObjects, setSelectedUsersObjects] = useState<User[]>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [roles, setRoles] = useState<Map<string, string>>(new Map())

  const getUsers = (ids: string[]): Observable<User[]> =>
    forkJoin(ids.map((id) => userService.getByID(id))) as unknown as Observable<User[]>

  useEffect(() => {
    getUsers(selectedUsers.map((s) => s.key)).subscribe((res) => setSelectedUsersObjects(res))
  }, [selectedUsers])

  useEffect(() => {
    roleService
      .getAll(
        new Query({
          sort: [{ field: 'name' }],
          pager: { offset: 0, limit: -1 },
        })
      )
      .subscribe((res) => {
        const tmpMap = new Map<string, string>()
        res.forEach((r) => tmpMap.set(r.id, r.name))
        setRoles(tmpMap)
      })

    if (props.conversation) {
      // edicion de alguna conversacion
      getUsers(
        props.conversation.users.filter((u) => u.userID !== loggedUser?.id).map((u) => u.userID)
      ).subscribe((res) => {
        setSelectedUsers(
          res.map((r) => ({
            key: r.id,
            value: r.name,
            role: r.roles?.length ? r.roles[0] : '',
            gender: r.gender,
          }))
        )
      })
    }
    if (!activeUser?.id) {
      return
    }

    userCircleService
      .getWichIncludesUserIDWithRelatersAndCircle(activeUser?.user.id)
      .subscribe((res) => {
        if (!res) return
        const userCircleParticipants = res.find((uc) => uc.circle.id === activeUser?.circle.id)
        if (!userCircleParticipants) return
        const newMap = new Map<string, AutocompleteUserInterface>()
        // anadimos al paciente en caso de que no sea el mismo usuario que el logeado.
        if (activeUser?.user?.id !== loggedUser?.id) {
          newMap.set(userCircleParticipants?.user?.id ?? '', {
            key: userCircleParticipants?.user?.id ?? '',
            value: userCircleParticipants?.user.name ?? '',
            role: Roles.Patient,
            gender: userCircleParticipants.user.gender ?? 0,
          })
        }

        // anadimos familiares/tutor.
        userCircleParticipants?.familiars.forEach((u) =>
          newMap.set(u.id, {
            key: u.id,
            value: u.name,
            role: Roles.FamilyOrTutor,
            gender: u.gender,
          })
        )
        // añadimos cuidadores
        userCircleParticipants?.careers.forEach((u) =>
          newMap.set(u.id, {
            key: u.id,
            value: u.name,
            role: Roles.CarerOrTeacher,
            gender: u.gender,
          })
        )
        // añadimos profesionales externos
        userCircleParticipants?.profExt.forEach((u) =>
          newMap.set(u.id, {
            key: u.id,
            value: u.name,
            role: Roles.ProfessionalExtern,
            gender: u.gender,
          })
        )
        // añadimos profesionales del SMS
        userCircleParticipants?.profSms
          .filter((u) => u.acceptChat)
          .forEach((u) =>
            newMap.set(u.id, {
              key: u.id,
              value: u.name,
              role: Roles.Professional,
              gender: u.gender,
            })
          )
        setUsersMap(newMap)
      })
  }, [activeUser])

  useEffect(() => {
    activeUser?.user?.id &&
      userService.getByID(activeUser?.user?.id).subscribe((res) => setCircleUser(res))
  }, [])

  const handleSelectableUsers = (value: AutocompleteUserInterface[]) => {
    setSelectedUsers([...selectedUsers, ...value])
    setConversation(
      Object.assign(
        { ...conversation },
        {
          users: [...selectedUsers, ...value].map((u) => ({
            conversationID: conversation.id ?? '',
            userID: u.key,
            isAdmin: u.key === loggedUser?.id,
            userGender: u.gender,
          })),
        }
      )
    )
  }

  const handleInput = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    setConversation(Object.assign({ ...conversation }, { [e.target.name]: e.target.value }))

  const saveConversation = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (videoconference) {
      if (selectedUsersObjects.length === 0) {
        return
      }

      let telfsEmails = ''

      selectedUsersObjects.forEach((s, i) => {
        if (i === 0) {
          telfsEmails += s.phone
        } else {
          telfsEmails += ',' + s.phone
        }
      })

      telfsEmails += ',' + circleUser?.phone

      selectedUsersObjects.forEach((s) => {
        telfsEmails += ',' + s.email
      })

      telfsEmails += ',' + circleUser?.email

      const win = window.open(
        `https://www.sms.carm.es/conecta/secure/GeneradorVideoconferenciaServlet?asunto=VideoconferenciaCirculoDelPaciente%20VideoConferencia%20Médica&invitacion=${telfsEmails}`,
        '_blank'
      )

      if (win) {
        win.focus()
        props.handleClose()
      }
    } else {
      const users = conversation.users
      if (!users.find((u) => u.userID === loggedUser?.id)) {
        users.push(
          emptyConversationUserDTO(
            conversation.id ?? '',
            loggedUser?.id ?? '',
            loggedUser?.gender ?? 0
          )
        )
      }

      if (users.length <= 1) {
        return
      }
      const newConversation = Object.assign({ ...conversation }, { users })
      newConversation.circleID = activeUser?.circle.id
      newConversation.userCircleID = activeUser?.id
      props.handleSave(toModel(newConversation), props.conversation !== undefined)
    }
  }

  const removeUser = (id: string) => setSelectedUsers(selectedUsers.filter((u) => u.value !== id))

  const getImage = (option: AutocompleteUserInterface) => {
    if (option.key === 'every') return
    switch (option.role) {
      case Roles.Professional:
        return <img className={style.roleIcon} src={professionalSMS} alt={t(Roles.Professional)} />

      case Roles.ContentManager:
        return <img className={style.roleIcon} src={professionalSMS} alt={t(Roles.Professional)} />

      case Roles.Patient:
        if (option.key !== activeUser?.user.id) {
          return <img className={styles.roleIcon} src={family} alt={t(Roles.FamilyOrTutor)} />
        }
        return <img className={style.roleIcon} src={patient} alt={t(Roles.Patient)} />

      case Roles.CarerOrTeacher:
        return <img className={style.roleIcon} src={career} alt={t(Roles.CarerOrTeacher)} />

      case Roles.FamilyOrTutor:
        if (option.key === activeUser?.user.id) {
          return <img className={styles.roleIcon} src={patient} alt={t(Roles.Patient)} />
        }
        return <img className={style.roleIcon} src={family} alt={t(Roles.FamilyOrTutor)} />

      case Roles.ProfessionalExtern:
        return (
          <img
            className={style.roleIcon}
            src={externProfessional}
            alt={t(Roles.ProfessionalExtern)}
          />
        )
    }
  }
  const handleVideoConference = () => {
    setVideoConference(!videoconference)

    if (!activeUser?.user.id) {
      return
    }

    if (!videoconference) {
      userService.getRelated(activeUser?.user.id).subscribe((res) => {
        if (!res) {
          return
        }
        userService
          .getFilteredItems(
            new Query({
              query: [
                new QueryParam<UserQuery>('ids', [
                  ...res.relatedUsers.map((r) => r.id),
                  activeUser?.user.id,
                ]),
                new QueryParam<UserQuery>('AcceptVideocalls', new BoolQueryParam(true)),
              ],
            })
          )
          .subscribe((ul) => {
            const newMap = new Map()
            ul.filter((u) => u.id !== loggedUser?.id).map((u) => {
              return newMap.set(u.id, { key: u.id, value: u.name })
            })
            setUsersMap(newMap)
          })
      })
      return
    }

    userService.getRelated(activeUser?.user.id).subscribe((res) => {
      if (!res) {
        return
      }
      userService
        .getFilteredItems(
          new Query({
            query: [
              new QueryParam<UserQuery>('ids', [
                ...res.relatedUsers.map((r) => r.id),
                activeUser?.user.id,
              ]),
              new QueryParam<UserQuery>('AcceptChat', new BoolQueryParam(true)),
            ],
          })
        )
        .subscribe((ul) => {
          const newMap = new Map()
          ul.filter((u) => u.id !== loggedUser?.id).map((u) => {
            return newMap.set(u.id, { key: u.id, value: u.name })
          })
          setUsersMap(newMap)
        })
    })
  }

  return (
    <Box className={style.newConversationContainer}>
      <Box className={style.exitIcon}>
        <img className={style.icon} src={close} alt={'close icon'} onClick={props.handleClose} />
      </Box>
      <Header label={t(props.conversation ? 'editConversation' : 'newConversation')} />
      <form onSubmit={saveConversation}>
        {!props.conversation && (
          <Box className={style.videoCallContainer}>
            <p className={style.label}>{t('videoCall')}:</p>
            <Switch color="primary" checked={videoconference} onChange={handleVideoConference} />
          </Box>
        )}
        <Box mt={3} mb={3} display={'flex'} justifyContent={'space-between'}>
          <Box>
            <p className={style.label}>{t('subject')}</p>
          </Box>
          <Box className={style.imputsSize}>
            <TextField
              key={'name'}
              id={'name'}
              name={'name'}
              value={conversation.name}
              onChange={handleInput}
              required={true}
              size={'small'}
              variant={'outlined'}
              fullWidth={true}
            />
          </Box>
        </Box>

        {!videoconference && (
          <Box mb={3} display={'flex'} justifyContent={'space-between'}>
            <Box>
              <p className={style.label}>{t('description')}</p>
            </Box>
            <Box className={style.imputsSize}>
              <TextField
                key={'description'}
                id={'description'}
                name={'description'}
                value={conversation.description}
                onChange={handleInput}
                required={true}
                size={'small'}
                variant={'outlined'}
                fullWidth={true}
              />
            </Box>
          </Box>
        )}

        {!props.conversation && (
          <>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Box>
                <p className={style.label}>{t('users')}</p>
              </Box>
              <Box className={style.imputsSize}>
                <Autocomplete
                  className={style.autocomplete}
                  style={{ width: '100%' }}
                  key={conversation.id}
                  id={`${conversation.id}-standard`}
                  value={[]}
                  multiple
                  disabled={usersMap.size === 0}
                  options={Array.from(usersMap.values()).filter((u) => !selectedUsers.includes(u))}
                  getOptionLabel={(opt) => t(opt.value)}
                  renderOption={(props, option) => (
                    <React.Fragment>
                      <span {...props}>
                        {getImage(option)}
                        {t(option.value)}
                      </span>
                    </React.Fragment>
                  )}
                  onChange={(_, v) => handleSelectableUsers(v)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      required={selectedUsers.length === 0}
                    />
                  )}
                  noOptionsText={t('noElementsAvailable')}
                  size="small"
                />
              </Box>
            </Box>
            <Box ml={'20%'} mb={3} display={'flex'}>
              {selectedUsers.length > 0 && (
                <Box display={'inline'} mt={3}>
                  {selectedUsers.map((u) => (
                    <Chip
                      key={u.key}
                      className={style.chip}
                      label={u.value}
                      onDelete={() => removeUser(u.value)}
                    />
                  ))}
                </Box>
              )}
            </Box>
          </>
        )}

        <Box className={style.buttonContainer}>
          <AppButton
            theme={window.innerWidth > 599 ? ButtonTheme.NewSecondary : ButtonTheme.whiteAndBlue}
            type={'button'}
            label={t('close')}
            handler={props.handleClose}
          />
          <AppButton
            theme={ButtonTheme.NewPrimary}
            type={'submit'}
            label={t('save')}
            handler={() => {}}
          />
        </Box>
      </form>
    </Box>
  )
}
