import { Container, ContainerItem } from '../common/container/Container'
import { FormService } from '../modules/forms/services/FormService'
import { UserFormService } from '../modules/forms/services/UserFormService'
import {
  FORM_API_KEY,
  FORM_SERVICE_KEY,
  FORMS_MODULE,
  FormsProps,
  USER_FORM_API_KEY,
  USER_FORM_SERVICE_KEY,
} from '../modules/forms/container'
import { FormApi } from '../modules/forms/api/FormApi'
import { UserFormApi } from '../modules/forms/api/UserFormApi'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'

let container: Container

function init(p: FormsProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(FORM_API_KEY, new ContainerItem({ instance: new FormApi() }))
  items.set(USER_FORM_API_KEY, new ContainerItem({ instance: new UserFormApi() }))

  items.set(
    FORM_SERVICE_KEY,
    new ContainerItem({ instance: new FormService({ apiKey: FORM_API_KEY }) })
  )
  items.set(
    USER_FORM_SERVICE_KEY,
    new ContainerItem({ instance: new UserFormService({ apiKey: USER_FORM_API_KEY }) })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getFormContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[FORMS_MODULE],
      },
    })
  }

  return container
}
