import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PatientCalendar } from '../../modules/patient-calendar-config/models/PatientCalendar'
import { emptyList, ItemList } from '../../common/models/ItemList'
import { Actions, Field, Pager } from '../../components/table_type/types'
import { useNavigate } from 'react-router-dom'
import {
  ROUTE_CONFIGURATION,
  ROUTE_CREATE,
  ROUTE_PATIENT_CALENDAR_CONFIG_FORM,
} from '../../routes/routes-constants'
import { Box } from '@mui/material'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { AppTable } from '../../components/table'
import { LoadingSpinner } from '../../components/loading-spinner/LoadingSpinner'
import styles from '../../components/loading-spinner/LoadingSpinner.module.css'
import genericStyle from '../../common/utils/generic.module.css'
import seeIcon from '../../assets/table_icons/ico-ver.svg'
import { Query } from '../../common/api/Query'
import { getPatientCalendarContainer } from '../../container/patient-calendar-module'
import { PatientCalendarService } from '../../modules/patient-calendar-config/services/PatientCalendarService'
import { PATIENT_CALENDAR_SERVICE_KEY } from '../../modules/patient-calendar-config'

const patientCalendarService = getPatientCalendarContainer().get<PatientCalendarService>(
  PATIENT_CALENDAR_SERVICE_KEY
)

export const Table = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [calendars, setCalendars] =
    useState<ItemList<PatientCalendar>>(emptyList<PatientCalendar>())
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [count, setCount] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [pager, setPager] = useState<Pager>()
  const [calendarsPerPage, setCalendarsPerPage] = useState<number>(10)

  const fields: Field<PatientCalendar>[] = [
    {
      name: 'title',
      label: t('title'),
    },
  ]

  const actions: Actions<PatientCalendar> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: (patientCalendar: PatientCalendar) =>
          navigate(`${ROUTE_PATIENT_CALENDAR_CONFIG_FORM}/${patientCalendar.id}`),
        icon: seeIcon,
        label: 'see',
      },
    ],
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: calendarsPerPage,
      handleChangeRowsPerPage,
    })
    getData()
  }, [page, count, calendarsPerPage])

  const getData = () => {
    setIsLoading(true)
    patientCalendarService
      .getFilteredList(
        new Query({
          sort: [{ field: 'title' }],
          pager: { offset: page * calendarsPerPage, limit: calendarsPerPage },
        })
      )
      .subscribe((res) => {
        const calendarList = emptyList<PatientCalendar>()
        calendarList.items = res.items
        calendarList.count = res.count
        setCalendars(calendarList)
        setCount(res.count)
        setIsLoading(false)
      })
  }

  const goBack = () => navigate(ROUTE_CONFIGURATION)

  const createCalendar = () => navigate(`${ROUTE_PATIENT_CALENDAR_CONFIG_FORM}/${ROUTE_CREATE}`)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setCalendarsPerPage(10)
      return
    }
    setCalendarsPerPage(Number.parseInt(event.target.value))
  }

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  return (
    <Box className={genericStyle.pageContainer}>
      {!isLoading ? (
        <Box>
          <Box mb={3} display="flex" justifyContent="space-between">
            <AppButton
              theme={ButtonTheme.NewSecondary}
              type={'button'}
              label={t('back')}
              handler={goBack}
            />
            <AppButton
              theme={ButtonTheme.NewPrimary}
              type={'button'}
              label={t('addCalendar')}
              handler={createCalendar}
            />
          </Box>
          <AppTable
            items={calendars.items}
            rowKeyField="id"
            fields={fields}
            actions={actions}
            pager={pager}
          />
        </Box>
      ) : (
        <LoadingSpinner className={styles.loadingSpinner} />
      )}
    </Box>
  )
}
