import { Container, IInit } from '../../../common/container/Container'
import { Observable, of } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { catchError, map } from 'rxjs/operators'
import { prepareURL } from '../../../common/api/http-helpers'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { UserTypeExternalProfessionalContainerConfig } from '../container'
import { toModel, ExternalProfessionalPendingDTO } from '../models/ExternalProfessionalPendingDTO'
import { ExternalProfessionalPending } from '../models/ExternalProfessionalPending'

export interface IExternalProfessionalPendingApi extends IInit {
  getByID(id: string): Observable<ExternalProfessionalPending | undefined>

  getFilteredList(
    q: Query<ExternalProfessionalPending>
  ): Observable<ItemList<ExternalProfessionalPending>>

  add(e: ExternalProfessionalPendingDTO): Observable<ExternalProfessionalPending | undefined>

  update(e: ExternalProfessionalPendingDTO): Observable<ExternalProfessionalPending | undefined>

  delete(id: string): Observable<boolean>

  sendFormsToTrainer(
    e: ExternalProfessionalPendingDTO
  ): Observable<ExternalProfessionalPending | undefined>
}

export class ExternalProfessionalPendingApi implements IExternalProfessionalPendingApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url =
      (this._container.config as UserTypeExternalProfessionalContainerConfig).moduleFullUrl +
      '/trainerPending'
  }

  add(e: ExternalProfessionalPendingDTO): Observable<ExternalProfessionalPending | undefined> {
    return this._httpClient.post<ExternalProfessionalPending>({ url: this._url, body: e }).pipe(
      map<ExternalProfessionalPendingDTO, ExternalProfessionalPending>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: this._url + '/' + id }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string): Observable<ExternalProfessionalPending | undefined> {
    return this._httpClient.get<ExternalProfessionalPending>({ url: `${this._url}/${id}` }).pipe(
      map<ExternalProfessionalPendingDTO, ExternalProfessionalPending>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getFilteredList(
    q: Query<ExternalProfessionalPending>
  ): Observable<ItemList<ExternalProfessionalPending>> {
    return this._httpClient
      .get<ItemList<ExternalProfessionalPending>>({ url: prepareURL(`${this._url}`, q) })
      .pipe(
        map<ItemList<ExternalProfessionalPendingDTO>, ItemList<ExternalProfessionalPending>>(
          (dto) => {
            const itemList = emptyList<ExternalProfessionalPending>()
            itemList.count = dto.count
            itemList.items = dto.items.map((d) => toModel(d))
            return itemList
          }
        ),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<ExternalProfessionalPending>())
        })
      )
  }

  update(e: ExternalProfessionalPendingDTO): Observable<ExternalProfessionalPending | undefined> {
    return this._httpClient.put<ExternalProfessionalPending>({ url: this._url, body: e }).pipe(
      map<ExternalProfessionalPendingDTO, ExternalProfessionalPending>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  sendFormsToTrainer(
    e: ExternalProfessionalPendingDTO
  ): Observable<ExternalProfessionalPending | undefined> {
    return this._httpClient
      .post<ExternalProfessionalPending>({ url: `${this._url}/trainerPending/sendForms`, body: e })
      .pipe(
        map<ExternalProfessionalPendingDTO, ExternalProfessionalPending>(() => {
          this._statusService.sendStatus({ variant: 'success' })
          return toModel(e)
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }
}
