import { createContext, useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

type SelectedMenu = {
  handleSetMenuSelected: (menu: string) => void
}

export const SelectedMenuContext = createContext<SelectedMenu>({
  handleSetMenuSelected: () => {}, // maybe we can improved this one
})

export const useSelectedMenuContext = () => useContext(SelectedMenuContext)

type SelectedMenuProviderProps = {
  handleSetMenuSelected: (menu: string) => void
  children: React.ReactNode
}

export const SelectedMenuProvider: React.FC<SelectedMenuProviderProps> = ({
  handleSetMenuSelected,
  children,
}) => {
  const location = useLocation()

  /*

  hemos actualizado la funcion de efecto de abajo por lo siguiente
  Para este supuesto lo que necesitamos es poder actualizar el Menu selecionado( handleSetMenuSelected)
  en base a lo que el usuario selecciona en el navbar, el problema surge cuando no seleccionados desde el navbar pero
  nos dirigimos hacia las mismas vistas/partes que si lo hiciesemos con el navbar.
  Para solucionar dicho problema, lo que hacemos es con este custom hook actualizamos el 
  menu selected como si lo hiciesemos desde el navbar , pero utilizando la location,
  la funcion isIdSegment, se ha creado para algunos corner cases: como los siguientes
  , tenemos algunos URLS que son del tipo /portaldelpaciente/esfera/patient-data/treatments/UUID
  en este caso locatin no va a actualizar correctamente nuestro handleSetMenuSelected, ya que el UUID no entra dentro de 
  las contantes que usamos para actualizar el menu, por ello hemos creado esta funcion auxiliar para que 
  en el momento que detectemos esos UUID poder eliminarlos para que podamos actualizar el menu seleccionado
  acorde a la string que handleSetMenuSelected espera ejemplo:(/portaldelpaciente/esfera/patient-data/treatments)
  
  */

  useEffect(() => {
    const isIdSegment = (segment: string) => {
      // regex que  coincide con el formato UUID
      return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(segment)
    }
    const pathSegments = location.pathname.split('/').filter((segment) => segment.length > 0)

    if (pathSegments.length > 0) {
      // Comprobamos el último segmento
      const lastSegment = pathSegments[pathSegments.length - 1]

      if (isIdSegment(lastSegment)) {
        // Eliminar el último segmento si es un ID
        pathSegments.pop()
      }
    }

    // Reconstruir la nueva URL
    let newPath = '/' + pathSegments.join('/')

    // array para los corner cases de tratamientos y sintomas, se ha añadido un regex por aceptacion que cambia el url no es
    // /esfera sino /circulo
    const pathCornerCases = [
      /\/portaldelpaciente\/(esfera|circulo)\/patient-data\/treatments/,
      /\/portaldelpaciente\/(esfera|circulo)\/patient-data\/symptoms/,
    ]

    if (pathCornerCases.some((cornerCase) => cornerCase.test(newPath))) {
      const basePath = '/portaldelpaciente/esfera/patient-data'

      const basePahtAceptacion = '/sms/portaldelpaciente/circulo/patient-data'

      newPath.includes('/esfera/') ? (newPath = basePath) : (newPath = basePahtAceptacion)
    }

    handleSetMenuSelected(newPath)
  }, [location, handleSetMenuSelected])

  return (
    <SelectedMenuContext.Provider value={{ handleSetMenuSelected }}>
      {children}
    </SelectedMenuContext.Provider>
  )
}
