import { Box, Table, TableContainer } from '@mui/material'
import { Head } from './Head'
import { Pagination } from './Pagination'
import { Body } from './Body'
import { Action, Actions, Field, Pager, Search, Sort } from '../table_type/types'
import { Searcher } from './Searcher'
import style from './AppTable.module.css'

const DEFAULT_ROW_KEY = 'id'

export type TableProps<T, Q> = {
  readonly fields: Field<T>[]
  readonly items: T[]
  readonly rowKeyField: keyof T
  readonly autocompleteItems?: string[]
  readonly autocompleteLabel?: string
  readonly autocompleteAction?: Action
  readonly actions?: Actions<T>
  readonly search?: Search<Q>
  readonly sort?: Sort<T>
  readonly pager?: Pager
  readonly fieldsInDraggable?: boolean
  readonly isLoading?: boolean
  readonly isPreventDefault?: boolean
  readonly specialsRowStyle?: boolean
}

export function AppTable<T extends Record<string, any>, Q extends Record<string, any>>(
  props: TableProps<T, Q>
) {
  return (
    <>
      <Box className={style.searchContainer}>
        {props.search && (
          <Searcher
            search={props.search}
            autocompleteAction={props.autocompleteAction}
            autocompleteItems={props.autocompleteItems}
            autocompleteLabel={props.autocompleteLabel}
            isPreventDefault={props.isPreventDefault}
          />
        )}
      </Box>
      <TableContainer>
        <Table
          style={{ borderCollapse: 'separate', borderSpacing: '0 20px', borderColor: '#DBDADA' }}
        >
          <Head fields={props.fields} sort={props.sort} actions={props.actions} />
          <Body
            actions={props.actions}
            fields={props.fields}
            items={props.items}
            rowKeyField={props.rowKeyField || DEFAULT_ROW_KEY}
            specialRowStyle={props.specialsRowStyle}
          />
        </Table>
      </TableContainer>
      <Box display={'flex'} justifyContent={'flex-end'}>
        {props.pager && <Pagination {...props.pager} />}
      </Box>
    </>
  )
}
