import { createTheme } from '@mui/material'

export const cirpaTheme = createTheme({
  palette: {
    primary: {
      main: '#0063a6', // --color-dark-blue
    },
    secondary: {
      main: '#f37e31', // --color-orange
      contrastText: '#FFFFFF',
    },
  },
})
