import { Observable, Subject } from 'rxjs'
import { Container, IInit } from '../container/Container'

type Status = {
  variant: 'success' | 'error' | 'warning' | 'banned' | 'errorFileUpload'
  message?: string // e.message()
  error?: Error
}

export interface IStatusService extends IInit {
  sendStatus(sts: Status): void

  statusObservable(): Observable<any>
}

export class StatusService implements IStatusService {
  private readonly _status: Subject<Status>
  protected _container!: Container

  constructor() {
    this._status = new Subject<Status>()
  }

  init(c: Container): void {
    this._container = c
  }

  sendStatus(sts: Status): void {
    this._status.next(sts)
  }

  statusObservable(): Observable<Status> {
    return this._status.pipe()
  }
}
