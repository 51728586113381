import { useEffect, useRef } from 'react'

function usePrevious<Type>(value: Type) {
  const ref = useRef<Type>()
  const previous = ref.current
  useEffect(() => {
    ref.current = value
  }, [value])
  return previous
}

export default usePrevious
