import { FamiliarData } from './FamiliarData'
import { v4 as uuidv4 } from 'uuid'

export interface FamiliarDataDTO {
  id: string
  userID: string
  information: string
  date: Date
}

export function emptyFamiliarData(user: string) {
  return {
    id: uuidv4(),
    userID: user,
    information: '',
    date: new Date(),
  }
}

export function fromModel(u: FamiliarData): FamiliarDataDTO {
  return {
    id: u.id,
    userID: u.userID,
    information: u.information,
    date: new Date(),
  }
}

export function toModel(d: FamiliarDataDTO): FamiliarData {
  return new FamiliarData(d)
}
