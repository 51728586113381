import { Query } from '../../../common/api/Query'

type FileProp = string | null | undefined

export type FileProps = {
  id?: string
  data: string
  name: string
  extension: string
  basePath: string
  size: number
  mimeType: string
  createdAt: Date
  miniPreview: string
  order: number
  creator: string
  userCircleID: string
  parent: FileProp
  isDir?: boolean
  isSystem?: string
  sharedWith?: string[]
  articleId: FileProp
  messageId: FileProp
  eventId: FileProp
  isVisible?: boolean | true
  isUploaded: boolean
  createdByDoctor: boolean
  checkBoxesSelected?: string
  specialities: string
  circles: string
  clonedFrom?: string
  creationDate?: Date
  isCircleConfResource?: boolean
}

export class File {
  private readonly _id: string | undefined
  private readonly _data: string
  private readonly _size: number
  private readonly _mimeType: string
  private readonly _miniPreview: string
  private _creator: string
  private readonly _userCircleID: string
  private readonly _articleId: string | null
  private readonly _messageId: string | null
  private readonly _eventId: string | null
  private readonly _isVisible: boolean
  private readonly _createdByDoctor: boolean

  private _name: string
  private _extension: string
  private _basePath: string
  private _order: number
  private _parent: FileProp
  private _isDir: boolean
  private _isSystem: string
  private _sharedWith: string[]
  private _isUploaded: boolean
  private _checkBoxesSelected?: string
  private _specialities: string
  private _circles: string
  private _clonedFrom?: string
  private _createdAt: Date
  private readonly _creationDate?: Date
  private _isCircleConfResource?: boolean

  constructor(p: FileProps) {
    this._id = p.id
    this._data = p.data
    this._name = p.name
    this._extension = p.extension
    this._basePath = p.basePath
    this._size = p.size
    this._mimeType = p.mimeType
    this._miniPreview = p.miniPreview
    this._order = p.order
    this._creator = p.creator
    this._userCircleID = p.userCircleID
    this._parent = p.parent
    this._isDir = p.isDir ?? false
    this._isSystem = p.isSystem ?? ''
    this._sharedWith = p.sharedWith ?? []
    this._articleId = p.articleId ?? null
    this._messageId = p.messageId ?? null
    this._eventId = p.eventId ?? null
    this._isVisible = p.isVisible ?? true
    this._isUploaded = p.isUploaded ?? false
    this._createdByDoctor = p.createdByDoctor ?? false
    this._checkBoxesSelected = p.checkBoxesSelected
    this._specialities = p.specialities
    this._circles = p.circles
    this._clonedFrom = p.clonedFrom
    this._createdAt = p.createdAt
    this._creationDate = p.creationDate
    this._isCircleConfResource = p.isCircleConfResource
  }

  get id(): string | undefined {
    return this._id
  }

  get data(): string {
    return this._data
  }

  get name(): string {
    return this._name
  }

  set name(value: string) {
    this._name = value
  }

  get extension(): string {
    return this._extension
  }

  set extension(value: string) {
    this._extension = value
  }

  get createdAt(): Date {
    return this._createdAt
  }

  set createdAt(value: Date) {
    this._createdAt = value
  }

  get basePath(): string {
    return this._basePath
  }

  set basePath(value: string) {
    this._basePath = value
  }

  get size(): number {
    return this._size
  }

  get mimeType(): string {
    return this._mimeType
  }

  get miniPreview(): string {
    return this._miniPreview
  }

  get order(): number {
    return this._order
  }

  set order(value: number) {
    this._order = value
  }

  get creator(): string {
    return this._creator
  }

  set creator(value: string) {
    this._creator = value
  }

  get userCircleID(): string {
    return this._userCircleID
  }

  get articleId(): string | null {
    return this._articleId
  }

  get messageId(): string | null {
    return this._messageId
  }

  get eventId(): string | null {
    return this._eventId
  }

  get isVisible(): boolean | true {
    return this._isVisible
  }

  get isSystem(): string {
    return this._isSystem
  }

  set isSystem(value: string) {
    this._isSystem = value
  }

  get parent(): FileProp {
    return this._parent
  }

  set parent(value: FileProp) {
    this._parent = value
  }

  get isDir(): boolean {
    return this._isDir
  }

  set isDir(value: boolean) {
    this._isDir = value
  }

  get sharedWith(): string[] {
    return this._sharedWith
  }

  get isUploaded(): boolean {
    return this._isUploaded
  }

  set isUploaded(value: boolean) {
    this._isUploaded = value
  }

  get checkBoxesSelected(): string | undefined {
    return this._checkBoxesSelected
  }

  set checkBoxesSelected(value: string | undefined) {
    this._checkBoxesSelected = value
  }

  get createdByDoctor(): boolean {
    return this._createdByDoctor
  }

  get circles(): string {
    return this._circles
  }

  set circles(value: string) {
    this._circles = value
  }

  get specialities(): string {
    return this._specialities
  }

  set specialities(value: string) {
    this._specialities = value
  }

  get clonedFrom(): string | undefined {
    return this._clonedFrom
  }

  set clonedFrom(value: string | undefined) {
    this._clonedFrom = value
  }

  get creationDate(): Date | undefined {
    return this._creationDate
  }

  get isCircleConfResource(): boolean | undefined {
    return this._isCircleConfResource
  }

  addSharedWith(value: string) {
    if (this._sharedWith) {
      this._sharedWith.push(value)
    } else {
      this._sharedWith = [value]
    }
  }

  removeSharedWith(id: string) {
    this._sharedWith = this._sharedWith.filter((u) => u !== id)
  }
}

export class FileQuery extends Query<File> {
  id?: string
  name?: string
  size?: number
  mimeType?: string
  miniPreview?: string
  order?: number
  creator?: string
  userCircleID: string | undefined
  parent?: string
  isDir?: boolean
  isSystem?: string
  articleId?: string
  messageId?: string
  eventId?: string
  isVisible?: boolean
  isUploaded?: boolean
  createdByDoctor?: boolean
  checkBoxesSelected?: string
  specialities?: string
  circles?: string
  clonedFrom?: string
  searchName?: string
  creationDate?: Date
}
