import React, { ChangeEvent, useEffect, useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import removeResouce from '../../../../../assets/newCircle/remove_resource_icon.svg'
import resourceIcon from '../../../../../assets/newCircle/resource_icon.svg'
import { FileDTO } from '../../../../../modules/files/models/FileDTO'
import resourceStyle from '../resourcesCircle.module.css'
import style from '../../view 4 Forms/Modal.module.css'
import { CheckboxItem } from '../modalAddDirectory/CheckboxItem'
import { CheckboxAssignType } from '../../../../../modules/forms/enums/CheckboxAssignType'

interface AddNewResourceProps {
  fileName: string
  fileParent: string
  setFileName: (fileName: string) => void
  directories: FileDTO[]
  setResourceDirectory: (directory: FileDTO) => void
  setRemoveDirectory: () => void
  handleRemoveResource: () => void
  newFile: FileDTO
  handleCheckBoxSelected: (type: CheckboxAssignType) => void
}

const AddNewResource: React.FC<AddNewResourceProps> = ({
  fileName,
  fileParent,
  setFileName,
  directories,
  setResourceDirectory,
  setRemoveDirectory,
  handleRemoveResource,
  newFile,
  handleCheckBoxSelected,
}) => {
  const [directory, setDirectory] = useState('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [arraySelectedDirectories, setArraySelectedDirectories] = useState<string[]>([''])

  useEffect(() => {
    if (!fileParent || !directories.length) return
    directories.forEach((directory) => {
      console.log(directory.id, fileParent)
      if (directory.id === fileParent) {
        setDirectory(directory.name)
      }
    })
  }, [])

  const changeResourceName = (event: ChangeEvent<HTMLInputElement>) => {
    setFileName(event.target.value)
  }

  const changeDirectory = (event: SelectChangeEvent) => {
    setDirectory(event.target.value)
    if (event.target.value === '') {
      setRemoveDirectory()
      return
    }
    setResourceDirectory(
      directories.find((directory) => directory.name === event.target.value) ?? directories[0]
    )
  }

  const { t } = useTranslation()
  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={10}>
          <TextField
            label={t('resourceName')}
            variant="outlined"
            size="small"
            onChange={changeResourceName}
            value={fileName}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={2}>
          <Button onClick={handleRemoveResource}>
            <img src={removeResouce} alt="Remove resource icon" />
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        alignItems="center"
        borderBottom={directory ? '3px solid #68b3e0' : ''}
        paddingBottom={directory ? '1rem' : ''}
      >
        <Grid item xs={7}>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-select-small-label">{t('selectDirectory')}</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={directory}
              label="Selecciona un directorio"
              onChange={changeDirectory}
            >
              {directories.map((directory) => (
                <MenuItem
                  disabled={arraySelectedDirectories.includes(directory.name)}
                  key={directory.id}
                  value={directory.name}
                >
                  {directory.name}
                </MenuItem>
              ))}
              <MenuItem value={''}>{t('Sin directorio')}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item marginTop="16px">
          <img src={resourceIcon} alt="Remove resource icon" />
        </Grid>
        <Grid item alignItems="center" marginTop="16px">
          <Typography>{fileName}</Typography>
        </Grid>
      </Grid>
      {!directory && (
        <Grid
          container
          spacing={2}
          alignItems="center"
          borderBottom=" 3px solid #68b3e0"
          paddingBottom="1rem"
        >
          <Grid item alignItems="center" marginTop="16px">
            <Box display="flex" className={resourceStyle.checkboxStyle}>
              <Box className={style.columnContainer} style={{ margin: '20px' }}>
                <CheckboxItem
                  name="allCircles"
                  isChecked={
                    newFile?.checkBoxesSelected?.includes(
                      CheckboxAssignType.AllMyCircles.toString()
                    ) ?? false
                  }
                  onCheckChange={() => handleCheckBoxSelected(CheckboxAssignType.AllMyCircles)}
                  label={t('allCircles')}
                />
                <CheckboxItem
                  name="allCarers"
                  isChecked={
                    newFile?.checkBoxesSelected?.includes(
                      CheckboxAssignType.AllCareers.toString()
                    ) ?? false
                  }
                  onCheckChange={() => handleCheckBoxSelected(CheckboxAssignType.AllCareers)}
                  label={t('allCarers')}
                />
                <CheckboxItem
                  name="allPatients"
                  isChecked={
                    newFile?.checkBoxesSelected?.includes(
                      CheckboxAssignType.AllMyPatients.toString()
                    ) ?? false
                  }
                  onCheckChange={() => handleCheckBoxSelected(CheckboxAssignType.AllMyPatients)}
                  label={t('allPatients')}
                />
              </Box>
              <Box className={style.columnContainer}>
                <CheckboxItem
                  name="allProfessionals"
                  isChecked={
                    newFile?.checkBoxesSelected?.includes(
                      CheckboxAssignType.AllExternal.toString()
                    ) ?? false
                  }
                  onCheckChange={() => handleCheckBoxSelected(CheckboxAssignType.AllExternal)}
                  label={t('allProfessionals')}
                />
                <CheckboxItem
                  name="allFamiliars"
                  isChecked={
                    newFile?.checkBoxesSelected?.includes(
                      CheckboxAssignType.AllFamiliars.toString()
                    ) ?? false
                  }
                  onCheckChange={() => handleCheckBoxSelected(CheckboxAssignType.AllFamiliars)}
                  label={t('allFamiliars')}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  )
}
export default AddNewResource
