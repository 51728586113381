import React, { useEffect, useRef, useState } from 'react'
import { Message } from '../../modules/messenger_consulta/models/Message'
import { Avatar, Box } from '@mui/material'
import { Link } from 'react-router-dom'
import style from './Messages.module.css'
import { useTranslation } from 'react-i18next'
import { getUserContainer } from '../../container/user-module'
import { UserService } from '../../modules/users/services/UserService'
import { LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { Query, QueryParam } from '../../common/api/Query'
import { User, UserQuery } from '../../modules/users/models/User'
import { getFileContainer } from '../../container/file-module'
import { FileService } from '../../modules/files/services/FileService'
import { FILE_SERVICE_KEY } from '../../modules/files'
import { viewFile } from '../../common/utils/file'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { Roles } from '../../modules/users/enums/Roles'
import { Description } from '@mui/icons-material'
import womanIcon from '../../assets/chatIcons/woman.svg'
import maleIcon from '../../assets/chatIcons/male.svg'
import { ConsultaUser, QueryUserGender } from '../../modules/messenger_consulta/models/ConsultaUser'

const userContainer = getUserContainer()
const usersService = userContainer.get<UserService>(USER_SERVICE_KEY)
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

const filesService = getFileContainer().get<FileService>(FILE_SERVICE_KEY)

type MessagesProps = {
  messages: Message[]
  ConsultaUsers: ConsultaUser[]
}

export function Messages(props: MessagesProps): JSX.Element {
  const loggedUser = loggedUserService.get()

  const { t } = useTranslation()

  const [messagesAuthor, setMessagesAuthor] = useState<User[]>()

  const divMessagesScroll = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (divMessagesScroll.current !== null) {
      divMessagesScroll.current.scrollTop = divMessagesScroll.current.scrollHeight
    }
    if (props.messages.length === 0) {
      return
    }
    usersService
      .getFilteredList(
        new Query({
          query: [
            new QueryParam<UserQuery>(
              'ids',
              props.messages.map((m) => m.author)
            ),
          ],
        })
      )
      .subscribe((res) => {
        setMessagesAuthor(res.items)
      })
  }, [props.messages])
  // Se elimina ya que no esta en el diseño
  // const formattedDate = (createdAt: Date): string => dateToDateTimeWithoutSecondsString(createdAt)

  const getCorrectMessage = (text: string) => {
    const result = text.split('*')
    return result.length === 1 ? (
      <div style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: text }} />
    ) : (
      <>
        <Description />
        <Link to={'.'} onClick={() => viewResource(result[1])}>
          {result[2]}
        </Link>
      </>
    )
  }

  const viewResource = (id: string) => {
    filesService.downloadByID(id).subscribe((res) => {
      if (res) {
        viewFile(res)
      }
    })
  }

  return (
    <div className={getDivMessagesScrollStyle(props.messages.length)} ref={divMessagesScroll}>
      {!props.messages.length ? (
        <Box component="h3">{t('noMessages')}</Box>
      ) : (
        props.messages.map((m) => (
          <Box
            key={m.id}
            className={style.messageContainer}
            display="flex"
            flexDirection="column"
            alignItems={m.author === loggedUser?.id ? 'flex-end' : 'flex-start'}
          >
            {m.isPinned ? (
              <Box component="span" style={{ marginBottom: '3px' }}>
                {t('autoReply')}
              </Box>
            ) : (
              <Box component="span" style={{ color: '#166bb2', marginBottom: '3px' }}>
                {m.author !== loggedUser?.id && !loggedUser?.roles.includes(Roles.Professional)
                  ? 'Enfermero/a'
                  : ''}
              </Box>
            )}

            <Box key={m.id} display={'flex'} flexDirection={'row'}>
              {loggedUser?.id !== m.author && (
                <Avatar
                  className={style.avatar}
                  src={
                    props.ConsultaUsers.find((u) => u.userID === m.author)?.userGender ===
                    QueryUserGender.female
                      ? womanIcon
                      : maleIcon
                  }
                />
              )}
              <Box className={getMessageContentStyle(loggedUser?.id ?? '', m.author)}>
                <p
                  className={style.author}
                  style={!loggedUser?.roles.includes(Roles.Professional) ? { display: 'none' } : {}}
                >
                  {messagesAuthor?.find((u) => u.id === m.author)?.name}
                </p>
                <p className={style.textStyle}>{getCorrectMessage(m.text)}</p>
              </Box>
              {loggedUser?.id === m.author && (
                <Avatar
                  className={style.avatar}
                  src={
                    props.ConsultaUsers.find((u) => u.userID === m.author)?.userGender ===
                    QueryUserGender.female
                      ? womanIcon
                      : maleIcon
                  }
                />
              )}
            </Box>
          </Box>
        ))
      )}
    </div>
  )
}

const getMessageContentStyle = (loggedUsername: string, author: string) =>
  `${style.messageContent} ${
    author === loggedUsername ? style.messageContentSent : style.messageContentReceived
  }`

const getDivMessagesScrollStyle = (messagesLength: number) =>
  `${style.divMessagesScroll} ${messagesLength === 0 ? style.divMessagesScrollEmpty : ''}`
