import { ReadingStatus } from '../enums/ReadingStatus'
import { Query } from '../../../common/api/Query'

export type Message2UserProps = {
  userID: string
  messageID: string
  readingStatus: ReadingStatus
}

export class MessageUser {
  private readonly _userID: string
  private readonly _messageID: string

  private _readingStatus: ReadingStatus

  constructor(p: Message2UserProps) {
    this._userID = p.userID
    this._messageID = p.messageID
    this._readingStatus = p.readingStatus
  }

  get userID(): string {
    return this._userID
  }

  get messageID(): string {
    return this._messageID
  }

  get readingStatus(): ReadingStatus {
    return this._readingStatus
  }

  set readingStatus(value: ReadingStatus) {
    this._readingStatus = value
  }
}

export class Message2UserQuery extends Query<MessageUser> {}
