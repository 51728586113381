import { RouteProps } from '../../routes/AppRouter'
import { Editor as UP } from '../../features/user-profile'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'

export function UserProfileEdit(props: RouteProps) {
  const title = props.title ?? ''

  return (
    <>
      <CurrentNavHeaderEsfera title={title} />
      <UP />
    </>
  )
}
