import { Box, Checkbox, FormControlLabel, Radio, RadioGroup, Alert } from '@mui/material'
import { useTranslation } from 'react-i18next'
import React, { FormEvent, useEffect, useRef, useState } from 'react'
import style from './ConversationEditor.module.css'
import { Conversation } from '../../modules/messenger/models/Conversation'
import close from '../../assets/table_icons/ico-eliminar.svg'
import { Header } from '../../components/header/Header'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import styles from '../patient-data/symptoms/epilepticalCrisis/ModalNewCrisis.module.css'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import TextField from '@mui/material/TextField'

type ModalEpilepticCrisisProps = {
  conversation?: Conversation
  handleCloseEpilepticCrisisModal: () => void
  saveConversation: (e: FormEvent<HTMLFormElement>) => void
  message: string
  handleMessage: (me: string) => void
  handleObject: (obj: ObjectForm) => void
  object: ObjectForm
  errorMessage: string
}

const symptom = [
  'no',
  'fever',
  'stress',
  'lackOfSleep',
  'forgettingTakeMedication',
  'changeMedication',
  'vomiting/Diarrhea',
]

const whatHappen = [
  'moreCrisisThanUsual',
  'crisesDifferentStronger',
  'haveCrisisNotHappenedLongTime',
]

interface ObjectForm {
  whatHappen: string[]
  currentTreatment: string
  timeWithoutCrisis: string
  symptom: string[]
  typeCrisis: string
  durationCrisis: string
  healthcare: string
  medicationAdministered: string
  timeWithoutAwareness: string
  nextAppointment: Date | null
  dontKnowAppointment: string
}

export const ModalEpilepticCrisis = (props: ModalEpilepticCrisisProps): JSX.Element => {
  const { t } = useTranslation()

  const [checkedState, setCheckedState] = useState(new Array(symptom.length).fill(false))
  const [checkedWhatHappen, setCheckedWhatHappen] = useState(
    new Array(whatHappen.length).fill(false)
  )
  const reason = useRef('')
  const [other, setOther] = useState<boolean>(false)
  const [otherSymptom, setOtherSymptom] = useState<boolean>(false)
  const otherSymptomField = useRef('')
  const [dontKnow, setDontKnow] = useState<boolean>(false)
  const [DateTimePickerError, setDateTimePickerError] = useState<string>('')

  const handlerAfterSymptom = (position: number) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    )
    const auxChecked = updatedCheckedState
    setCheckedState(() => auxChecked)

    const arrayOtherSymptoms: any[] = []
    updatedCheckedState.reduce((sum, currentState, index) => {
      if (currentState === true) {
        return sum + arrayOtherSymptoms.push(t(' ' + symptom[index]))
      }
      return sum
    }, 0)

    if (otherSymptom) {
      arrayOtherSymptoms.push(' ' + otherSymptomField.current)
    }

    props.handleObject(Object.assign({ ...props.object }, { symptom: arrayOtherSymptoms }))
  }

  useEffect(() => {}, [whatHappen])

  const handleWhatHappenChange = (position?: number) => {
    const updatedCheckedState = checkedWhatHappen.map((item, index) =>
      index === position ? !item : item
    )

    setCheckedWhatHappen(updatedCheckedState)

    const arrayWhatHappen: any[] = []
    updatedCheckedState.reduce((sum, currentState, index) => {
      if (currentState === true) {
        return sum + arrayWhatHappen.push(t(' ' + whatHappen[index]))
      }

      return sum
    }, 0)

    if (other) {
      arrayWhatHappen.push(' ' + reason.current)
    }

    props.handleObject(Object.assign({ ...props.object }, { whatHappen: arrayWhatHappen }))
  }

  return (
    <Box className={style.newConversationContainer}>
      <Box>
        <Header
          label={t(props.conversation ? 'editConversation' : 'newQuery')}
          icon={close}
          onClick={props.handleCloseEpilepticCrisisModal}
        />
      </Box>
      <form onSubmit={props.saveConversation}>
        <Box>
          <p className={style.title}>
            <strong> {t('subject')}: </strong> {t('epilepticCrisis')}
          </p>
          <Box>
            <h3 className={style.title}>{t('whatHappened')}*</h3>
            {whatHappen.map((os, index) => {
              return (
                <Box key={`${os}_${index}`}>
                  <Checkbox
                    title={t(os)}
                    value={t(os)}
                    checked={checkedWhatHappen[index]}
                    onChange={() => handleWhatHappenChange(index)}
                  />
                  <span className={style.title}>{t(os)}</span>
                </Box>
              )
            })}
            <Box>
              <FormControlLabel
                className={style.label}
                value={t('otherFreeText')}
                control={<Checkbox className={style.checkbox} />}
                label={t('otherFreeText')}
                onClick={() => setOther(!other)}
                checked={other}
              />
              <TextField
                defaultValue={undefined}
                type="text"
                value={reason.current}
                className={style.inputFormcontrol}
                InputProps={{
                  inputProps: { min: 1 },
                }}
                onChange={(e) => {
                  reason.current = e.target.value
                  handleWhatHappenChange(whatHappen.length)
                }}
                id="outlined-basic"
                variant="outlined"
                size={'small'}
                disabled={!other}
              />
            </Box>
          </Box>
          <Box>
            <h3 className={style.title}>{t('currentTreatment')}*</h3>
            <TextField
              defaultValue={undefined}
              type="text"
              value={props.object.currentTreatment}
              className={style.inputMarginLeft}
              InputProps={{
                inputProps: { min: 1 },
              }}
              onChange={(e) => {
                props.handleObject(
                  Object.assign({ ...props.object }, { currentTreatment: e.target.value })
                )
              }}
              id="outlined-basic"
              variant="outlined"
              size={'small'}
            />
          </Box>
          <Box>
            <h3 className={style.title}>{t('howLongWithoutCrisis')}</h3>
            <TextField
              defaultValue={undefined}
              type="text"
              value={props.object.timeWithoutCrisis}
              className={style.inputMarginLeft}
              InputProps={{
                inputProps: { min: 1 },
              }}
              onChange={(e) => {
                props.handleObject(
                  Object.assign({ ...props.object }, { timeWithoutCrisis: e.target.value })
                )
              }}
              id="outlined-basic"
              variant="outlined"
              size={'small'}
            />
          </Box>
          <Box>
            <h3 className={style.title}>{t('hasBeenTrigger')}*</h3>
            {symptom.map((os, index) => {
              return (
                <Box key={`${os}_${index}`}>
                  <Checkbox
                    title={t(os)}
                    value={t(os)}
                    checked={checkedState[index]}
                    onChange={() => handlerAfterSymptom(index)}
                  />
                  <span className={style.title}>{t(os)}</span>
                </Box>
              )
            })}
            <Box>
              <FormControlLabel
                className={style.label}
                value={t('others')}
                control={<Checkbox />}
                label={t('others')}
                onClick={() => setOtherSymptom(!otherSymptom)}
                checked={otherSymptom}
              />
              <TextField
                defaultValue={undefined}
                type="text"
                value={otherSymptomField.current}
                className={style.inputFormcontrol}
                InputProps={{
                  inputProps: { min: 1 },
                }}
                onChange={(e) => {
                  otherSymptomField.current = e.target.value
                  handlerAfterSymptom(symptom.length)
                }}
                id="outlined-basic"
                variant="outlined"
                size={'small'}
                disabled={!otherSymptom}
              />
            </Box>
          </Box>
        </Box>

        <Box>
          <h3 className={style.title}>{t('whatHasTheCrisisBeenLike')}</h3>
          <TextField
            defaultValue={undefined}
            type="text"
            value={props.object.typeCrisis}
            className={style.inputMarginLeft}
            InputProps={{
              inputProps: { min: 1 },
            }}
            onChange={(e) => {
              props.handleObject(Object.assign({ ...props.object }, { typeCrisis: e.target.value }))
            }}
            id="outlined-basic"
            variant="outlined"
            size={'small'}
          />
        </Box>
        <Box>
          <h3 className={style.title}>{t('howLongHasLasted')}</h3>
          <TextField
            defaultValue={undefined}
            type="text"
            value={props.object.durationCrisis}
            className={style.inputMarginLeft}
            InputProps={{
              inputProps: { min: 1 },
            }}
            onChange={(e) => {
              props.handleObject(
                Object.assign({ ...props.object }, { durationCrisis: e.target.value })
              )
            }}
            id="outlined-basic"
            variant="outlined"
            size={'small'}
          />
        </Box>
        <Box>
          <h3 className={style.title}>{t('requiredMedicalAssistance')}</h3>
          <RadioGroup
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
            className={styles.radioGroup}
            aria-label="gender"
            name="gender1"
            value={props.object?.healthcare}
            onChange={(e) => {
              props.handleObject(Object.assign({ ...props.object }, { healthcare: e.target.value }))
            }}
          >
            <FormControlLabel
              className={style.label}
              value={t('Sí')}
              control={<Radio />}
              label={t('Sí')}
            />
            <FormControlLabel
              className={style.label}
              value={t('No')}
              control={<Radio />}
              label={t('No')}
            />
          </RadioGroup>
        </Box>
        <Box>
          <h3 className={style.title}>{t('haveYouHadAdministerAnyMedication')}</h3>
          <RadioGroup
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
            className={styles.radioGroup}
            aria-label="gender"
            name="gender1"
            value={props.object?.medicationAdministered}
            onChange={(e) => {
              props.handleObject(
                Object.assign({ ...props.object }, { medicationAdministered: e.target.value })
              )
            }}
          >
            <FormControlLabel
              className={style.label}
              value={t('Sí')}
              control={<Radio />}
              label={t('Sí')}
            />
            <FormControlLabel
              className={style.label}
              value={t('No')}
              control={<Radio />}
              label={t('No')}
            />
          </RadioGroup>
        </Box>
        <Box>
          <h3 className={style.title}>{t('howLongTakeYouRegainSpeechAwareness')}</h3>
          <TextField
            defaultValue={undefined}
            type="text"
            className={style.inputMarginLeft}
            value={props.object.timeWithoutAwareness}
            InputProps={{
              inputProps: { min: 1 },
            }}
            onChange={(e) => {
              props.handleObject(
                Object.assign({ ...props.object }, { timeWithoutAwareness: e.target.value })
              )
            }}
            id="outlined-basic"
            variant="outlined"
            size={'small'}
          />
        </Box>
        <Box>
          <h3 className={style.title}>{t('whenIsYourNextAppointment')}*</h3>
          <Box display="flex" flexWrap="wrap">
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
              <DatePicker
                key={'startDate'}
                inputFormat="DD/MM/YYYY"
                onError={(reason) => {
                  switch (reason) {
                    case 'invalidDate':
                      setDateTimePickerError(t('invalidDateMessage'))
                      break
                    case 'minDate':
                      setDateTimePickerError(t('minDateMessage'))
                      break
                  }
                }}
                renderInput={(props) => (
                  <TextField
                    sx={{
                      '.css-1ptx2yq-MuiInputBase-root-MuiInput-root': { paddingRight: '17px' },
                    }}
                    helperText={props.error && DateTimePickerError}
                    {...props}
                    inputProps={{
                      ...props.inputProps,
                      placeholder: ' ',
                    }}
                  />
                )}
                onChange={(e) => {
                  setDontKnow(false)
                  props.object.dontKnowAppointment = ''
                  if (e)
                    props.handleObject(
                      Object.assign(
                        { ...props.object },
                        { nextAppointment: dayjs(e).toDate().toString() }
                      )
                    )
                }}
                value={props.object.nextAppointment}
              />
            </LocalizationProvider>
            <RadioGroup
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
              className={styles.radioGroup}
              aria-label="dontKnowAppointment"
              name="dontKnowAppointment"
              value={props.object?.dontKnowAppointment}
              onChange={() => {}}
            >
              <FormControlLabel
                className={style.label}
                value={t('No lo sé')}
                control={<Radio />}
                label={t('DontKnow')}
                onClick={() => {
                  props.object.nextAppointment = null
                  props.handleObject(
                    Object.assign({ ...props.object }, { dontKnowAppointment: t('DontKnow') })
                  )
                  setDontKnow(true)
                }}
                checked={dontKnow}
              />
            </RadioGroup>
          </Box>
        </Box>
        <Box className={style.actionBtns}>
          {props.errorMessage && (
            <Alert severity="warning" key="errorMessage" id="errorMessage">
              {t(props.errorMessage)}
            </Alert>
          )}
          <AppButton
            theme={ButtonTheme.NewSecondary}
            type={'button'}
            label={t('back')}
            handler={props.handleCloseEpilepticCrisisModal}
          />
          <AppButton
            theme={ButtonTheme.NewPrimary}
            type={'submit'}
            label={t('save')}
            handler={() => {}}
          />
        </Box>
      </form>
    </Box>
  )
}
