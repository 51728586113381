import { Container } from '../../common/container/Container'

export type FormContainerConfig = {
  moduleFullUrl: string
}

export type FormsProps = {
  parentContainer: Container
  config: FormContainerConfig
}

export const FORMS_MODULE = Symbol('FORMS_MODULE')

export const FORM_API_KEY = Symbol('FORM_API_KEY')
export const USER_FORM_API_KEY = Symbol('USER_FORM_API_KEY')

export const FORM_SERVICE_KEY = Symbol('FORM_SERVICE_KEY')
export const USER_FORM_SERVICE_KEY = Symbol('USER_FORM_SERVICE_KEY')
