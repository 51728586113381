import { Contact } from './Contact'

export interface ContactDTO {
  userID: string
  relatedUserID: string
}

export function fromModel(c: Contact): ContactDTO {
  return {
    userID: c.userID,
    relatedUserID: c.relatedUserID,
  }
}

export function toModel(d: ContactDTO): Contact {
  return new Contact(d)
}
