import { Box, Divider, Modal, SelectChangeEvent, Typography } from '@mui/material'
import styles from './AddSeeEditSportCenterModal.module.css'
import { useTranslation } from 'react-i18next'
import React, { ChangeEvent, useEffect, useState } from 'react'
import {
  emptySportCenterDTO,
  SportCenterDTO,
} from '../../modules/sport-center/models/SportCenterDTO'
import { SportCenterInfo } from './SportCenterInfo'
import { WeekCalendar } from './WeekCalendar'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { getSportCenterContainer } from '../../container/sport-center-module'
import { SPORT_CENTER_SERVICE_KEY } from '../../modules/sport-center'
import { SportCenterService } from '../../modules/sport-center/services/SportCenterService'
import { useSnackbar } from 'notistack'
import icoDelete from '../../assets/buttons/close.svg'
import icoAdd from '../../assets/left_menu/plusWhite.svg'
import { AddGroup } from './AddGroup'
import { getTrainingGroupContainer } from '../../container/training-group-module'
import { TrainingGroupService } from '../../modules/training-group/services/TrainingGroupService'
import { TRAINING_GROUP_SERVICE_KEY } from '../../modules/training-group'
import {
  emptyTrainingGroupArrDTO,
  emptyTrainingGroupDTO,
  TrainingGroupArrDTO,
  TrainingGroupDTO,
  trainingGroupFromModelList,
} from '../../modules/training-group/models/TrainingGroupDTO'
import { AddSeeEditCityHallModal } from '../cityHall-config/AddSeeEditCityHallModal'
import { CityHall } from '../../modules/cityHall/models/CityHall'
import { NewTrainerMessageModal } from './NewTrainerMessageModal'
import { DeleteGroupMessageModal } from './DeleteGroupMessageModal'
import { Query, QueryParam } from '../../common/api/Query'
import { TrainingGroupQuery } from '../../modules/training-group/models/TrainingGroup'
import { isValidZipCode } from '../../common/utils/strings'

type AddSeeEditSportCenterModalProps = {
  id?: string
  open: boolean
  handleClose: () => void
  sportCenterEdit?: SportCenterDTO
}
const sportCenterService =
  getSportCenterContainer().get<SportCenterService>(SPORT_CENTER_SERVICE_KEY)
const trainingGroupService = getTrainingGroupContainer().get<TrainingGroupService>(
  TRAINING_GROUP_SERVICE_KEY
)

export function AddSeeEditSportCenterModal({
  open,
  handleClose,
  id,
  sportCenterEdit,
}: AddSeeEditSportCenterModalProps) {
  const [sportCenter, setSportCenter] = useState<SportCenterDTO>(
    sportCenterEdit ?? emptySportCenterDTO()
  )
  const [trainingGroup, setTrainingGroup] = useState<TrainingGroupDTO>(emptyTrainingGroupDTO())
  const [viewAddGroup, setViewAddGroup] = useState<boolean>(false)
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [openCityHall, setOpenCityHall] = useState<boolean>(false)
  const [cityHallData, setCityHallData] = useState<CityHall>()
  const [errorMessageCenter, setErrorMessageCenter] = useState<string>('')
  const [errorMessageGroup, setErrorMessageGroup] = useState<string>('')
  const [openNewTrainer, setOpenNewTrainer] = useState<boolean>(false)
  const [trainerPending, setTrainerPending] = useState<boolean>(false)
  const [numberTimeTable, setNumberTimeTable] = useState<number>(1)
  const [trainingGroupArr, setTrainingGroupArr] = useState<TrainingGroupArrDTO>(
    emptyTrainingGroupArrDTO()
  )
  const [openDeleteGroup, setOpenDeleteGroup] = useState<boolean>(false)
  const [groupIDDeleting, setGroupIDDeleting] = useState<string>('')

  enum FieldsError {
    EMPTY = 'fieldNoEmpty',
    NOTCERO = 'noZeroCapacity',
    ERRORTIME = 'incorrectTime',
    ERRORZIPCODE = 'incorrectZipCode',
  }

  if (trainingGroup.centerID === '') {
    setTrainingGroup(Object.assign({ ...trainingGroup }, { centerID: sportCenter.id }))
  }

  useEffect(() => {
    if (!id) return
    sportCenterService.getByID(id).subscribe((res) => {
      if (!res) return
      setSportCenter(res)
    })
  }, [])

  useEffect(() => {
    if (!sportCenter.id || trainingGroupArr.groups.length !== 0) return

    trainingGroupService
      .getFilteredList(
        new Query<TrainingGroupQuery>({
          query: [new QueryParam<TrainingGroupQuery>('centerID', sportCenter.id)],
        })
      )
      .subscribe((res) => {
        if (!res) return
        setTrainingGroupArr({ groups: trainingGroupFromModelList(res.items) })
      })
  }, [sportCenter.id])

  useEffect(() => {
    box()
  }, [numberTimeTable])

  const validateCentreField = () => {
    if (Object.values(sportCenter).some((value) => value === '')) {
      setErrorMessageCenter(FieldsError.EMPTY)
      return false
    }

    if (!isValidZipCode(sportCenter.postalCode)) {
      setErrorMessageCenter(FieldsError.ERRORZIPCODE)
      return false
    }

    return true
  }

  const validateGroupField = () => {
    if (
      trainingGroup.timeTable.some((timeTable) =>
        Object.values(timeTable).some(
          (value) =>
            value === null ||
            value === undefined ||
            (typeof value === 'string' && value.trim() === '')
        )
      ) ||
      trainingGroup.trainerName === '' ||
      trainingGroup.name === ''
    ) {
      setErrorMessageGroup(FieldsError.EMPTY)
      return false
    }

    if (trainingGroup.capacity === 0) {
      setErrorMessageGroup(FieldsError.NOTCERO)
      return false
    }

    if (
      trainingGroup.timeTable.length === 0 ||
      trainingGroup.timeTable.every((value) => {
        const start = new Date(value.startTime)
        const finish = new Date(value.finishTime)
        const sHours = start.getHours()
        const sMinutes = start.getMinutes()

        const fHours = finish.getHours()
        const fMinutes = finish.getMinutes()
        // validate start hour/minutes is lower than finish hour/minutes
        // validate start hour is lower than finish hour
        // validate start hour and finish hour cant be out of range 09.00 - 21.00
        const isValid =
          (sHours === fHours && sMinutes >= fMinutes) ||
          sHours > fHours ||
          sHours < 9 ||
          fHours > 21
        return isValid
      })
    ) {
      setErrorMessageGroup(FieldsError.ERRORTIME)
      return false
    }
    return true
  }

  const handleInput = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent<string>
  ) => {
    setSportCenter(Object.assign({ ...sportCenter }, { [event.target.name]: event.target.value }))
  }

  const handleInputTrainingGroup = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.target.name === 'capacity' && +event.target.value < 0) {
      return
    }
    setTrainingGroup(
      Object.assign(
        { ...trainingGroup },
        {
          [event.target.name]:
            event.target.name !== 'capacity' ? event.target.value : +event.target.value,
        }
      )
    )
  }

  const handleInputTrainer = (userName: string, userTypeExternProfID: string, pending: boolean) => {
    setTrainingGroup(
      Object.assign({ ...trainingGroup }, { trainerName: userName, userTypeExternProfID })
    )
    setTrainerPending(pending)
  }

  const handleAddGroup = () => {
    if (!viewAddGroup) {
      setTrainingGroup(emptyTrainingGroupDTO())
    }
    setViewAddGroup(!viewAddGroup)
    setNumberTimeTable(1)
    setErrorMessageGroup('')
  }

  const saveSportCenter = () => {
    if (!validateCentreField()) return
    if (sportCenterEdit) {
      sportCenterService.update(sportCenter).subscribe((res) => {
        enqueueSnackbar(t('changesWereSaved'), { variant: 'success' })
      })
    } else {
      sportCenterService.add(sportCenter).subscribe((res) => {
        enqueueSnackbar(t('changesWereSaved'), { variant: 'success' })
        trainingGroupService.addArr(trainingGroupArr, sportCenter.id).subscribe((res) => {
          enqueueSnackbar(t('changesWereSaved'), { variant: 'success' })
        })
      })
      handleClose()
      return
    }
    trainingGroupService.addArr(trainingGroupArr, sportCenter.id).subscribe((res) => {
      enqueueSnackbar(t('changesWereSaved'), { variant: 'success' })
    })
    handleClose()
  }

  const addUpdateGroupToArr = () => {
    const auxArr = [...trainingGroupArr.groups]
    const groupInArr = auxArr.find((value) => value.id === trainingGroup.id)
    if (groupInArr) {
      auxArr.forEach((value, index) => {
        if (value.id === trainingGroup.id) {
          auxArr[index] = trainingGroup
        }
      })
    } else {
      auxArr.push(trainingGroup)
    }

    setTrainingGroupArr(Object.assign({ ...trainingGroupArr }, { groups: auxArr }))
    setTrainingGroup(emptyTrainingGroupDTO())
  }

  const handlerOpenModalCityHall = () => {
    setOpenCityHall(true)
  }

  const handlerCloseModalCityHall = () => {
    setOpenCityHall(false)
  }
  const handlerSendCityHall = (cityHall: CityHall) => {
    setCityHallData(cityHall)
  }

  const handlerCloseNewTrainer = () => {
    setOpenNewTrainer(false)
  }

  const handlerAcceptNewTrainer = () => {
    addUpdateGroupToArr()
    setViewAddGroup(!viewAddGroup)
    setOpenNewTrainer(false)
    setErrorMessageGroup('')
  }

  const handlerNumberTime = (value: number) => {
    setNumberTimeTable(value)
  }

  const addGroup = () => {
    const isValid = validateGroupField()
    if (isValid) {
      if (
        (trainerPending && trainingGroup.trainerName !== '') ||
        (!trainerPending && trainingGroup.userTypeExternProfID === '')
      ) {
        setOpenNewTrainer(true)
      } else {
        addUpdateGroupToArr()
        setViewAddGroup(!viewAddGroup)
        setErrorMessageGroup('')
      }
    }
  }

  const handlerEditGroup = (group: TrainingGroupDTO) => {
    setTrainingGroup(group)
    setViewAddGroup(!viewAddGroup)
    setNumberTimeTable(group.timeTable.length)
  }

  const handlerDeleteGroupQuery = (id: string) => {
    setOpenDeleteGroup(true)
    setGroupIDDeleting(id)
  }

  const handlerCloseDeleteGroup = () => {
    setOpenDeleteGroup(false)
  }

  const handlerDeleteGroup = () => {
    if (groupIDDeleting === '') return

    let foundID = false
    const auxArr: TrainingGroupDTO[] = []
    trainingGroupArr.groups.forEach((value) => {
      if (value.id !== groupIDDeleting) {
        foundID = true
        auxArr.push(value)
      }
    })
    if (trainingGroupArr.groups.length === 1) {
      foundID = true
    }
    if (foundID) {
      setTrainingGroupArr({ groups: auxArr })
    }

    setGroupIDDeleting('')
    handlerCloseDeleteGroup()
  }

  const box = () => {
    return (
      <Box style={{ height: 'fit-content' }} className={styles.containerGroup}>
        <Box className={styles.contentHeader}>
          <img style={{ cursor: 'pointer' }} onClick={handleClose} src={icoDelete} />
        </Box>
        <Box>
          <Typography className={styles.headerTitle}>
            {sportCenterEdit ? t('seeEditSportCenter') : t('addSportCenterExtend')}
          </Typography>
          <Divider className={styles.dividerStyle} />
        </Box>
        <Box className={styles.content}>
          <SportCenterInfo
            sportCenter={sportCenter}
            handleInput={handleInput}
            handlerModalCityHall={handlerOpenModalCityHall}
            handlerSendCityHall={handlerSendCityHall}
            errorMessage={errorMessageCenter}
            blocked={false}
          />
          {viewAddGroup && (
            <>
              <Box className={styles.addButton}>
                <AppButton
                  theme={ButtonTheme.BackToCalenar}
                  type={'button'}
                  label={t('backtoCalendar')}
                  handler={handleAddGroup}
                />
              </Box>

              <AddGroup
                trainingGroup={trainingGroup}
                handleInputTrainingGroup={handleInputTrainingGroup}
                handlerInputTrainer={handleInputTrainer}
                errorMessage={errorMessageGroup}
                handlerNumberTime={handlerNumberTime}
              />
              <Box m={3} display="flex" justifyContent="flex-end">
                <AppButton
                  theme={ButtonTheme.NewPrimary}
                  type={'button'}
                  label={t('saveGroup')}
                  handler={addGroup}
                />
              </Box>
            </>
          )}
          {!viewAddGroup && (
            <>
              <Box className={styles.addButton}>
                <AppButton
                  theme={ButtonTheme.Addgroup}
                  startIcon={icoAdd}
                  type={'button'}
                  label={t('Añadir grupo')}
                  handler={handleAddGroup}
                />
              </Box>
              <WeekCalendar
                startDate={new Date()}
                sportCenterID={sportCenter.id}
                trainingGroupArr={trainingGroupArr}
                handlerEditGroup={handlerEditGroup}
                handlerDeleteGroup={handlerDeleteGroupQuery}
              />
              <Box m={3} display="flex" justifyContent="flex-end">
                <AppButton
                  theme={ButtonTheme.NewPrimary}
                  type={'button'}
                  label={t('save')}
                  handler={saveSportCenter}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
    )
  }

  return (
    <>
      <Modal open={open} onClose={handleClose} className={styles.modalOverFlow}>
        {box()}
      </Modal>
      <AddSeeEditCityHallModal
        open={openCityHall}
        handleClose={handlerCloseModalCityHall}
        cityHallData={cityHallData}
      />
      <NewTrainerMessageModal
        open={openNewTrainer}
        handlerClose={handlerCloseNewTrainer}
        handlerAccept={handlerAcceptNewTrainer}
      />
      <DeleteGroupMessageModal
        open={openDeleteGroup}
        handlerClose={handlerCloseDeleteGroup}
        handlerAccept={handlerDeleteGroup}
      />
    </>
  )
}
