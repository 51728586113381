import { getUserContainer } from '../../container/user-module'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import {
  IUserService,
  LOGGED_USER_SERVICE_KEY,
  ROLES_SERVICE_KEY,
  USER_SERVICE_KEY,
  POSITIONTYPE_SERVICE_KEY,
  PROFESSIONALTYPE_SERVICE_KEY,
} from '../../modules/users'
import { emptyUserDTO, UserDTO } from '../../modules/users/models/User'
import { useEffect, useState } from 'react'
import { Alert, Box, Skeleton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import styles from './Editor.module.css'
import { ROUTE_USER_PROFILE_EDIT } from '../../routes/routes-constants'
import { useNavigate } from 'react-router-dom'
import { Header } from '../../components/header/Header'
import { Query } from '../../common/api/Query'
import { RolesService } from '../../modules/users/services/RolesServices'
import { ProfessionalTypesService } from '../../modules/users/services/ProfessionalTypeService'
import {
  emptyProfessionalTypeUserDTO,
  ProfessionalTypeUserDTO,
} from '../../modules/users/models/ProfessionalTypeUserDTO'
import { useGlobalContext } from '../../common/utils/GlobalRoleContext'
import { PositionType } from '../../modules/users/models/PositionType'
import { PositionTypesService } from '../../modules/users/services/PositionTypeService'
import {
  emptyProfessionalQueryDTO,
  fromModel,
  ProfessionalQueryDTO,
} from '../../modules/messenger_consulta/models/ProfessionalQueryDTO'
import { getMessengerConsultaContainer } from '../../container/messenger-consulta-module'
import { PROFESSIONAL_QUERY_SERVICE_KEY } from '../../modules/messenger_consulta'
import { ProfessionalQueryService } from 'modules/messenger_consulta/services/ProfessionalQueryService'
import { emptyPositionTypeDTO, PositionTypeDTO } from '../../modules/users/models/PositionTypeDTO'
import icoProfile from '../../assets/esfera/profile/profile.svg'
import {
  emptyProfessionalTypeDTO,
  ProfessionalTypeDTO,
} from '../../modules/users/models/ProfessionalTypeDTO'
import { useGetProfilePhoto } from '../../hooks/profilePhoto/useGetProfilePhoto'
import { Roles } from 'modules/users/enums/Roles'

const userContainer = getUserContainer()
const userService = userContainer.get<IUserService>(USER_SERVICE_KEY)
const loggedUserService = userContainer.get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const roleService = userContainer.get<RolesService>(ROLES_SERVICE_KEY)
const professionalTypeService = userContainer.get<ProfessionalTypesService>(
  PROFESSIONALTYPE_SERVICE_KEY
)
const positionTypeService = userContainer.get<PositionTypesService>(POSITIONTYPE_SERVICE_KEY)
const professionalQueryService = getMessengerConsultaContainer().get<ProfessionalQueryService>(
  PROFESSIONAL_QUERY_SERVICE_KEY
)

export function Profile() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const loggedUser = loggedUserService.get()

  const [userToEdit, setUserToEdit] = useState<UserDTO>(emptyUserDTO())
  // const [role, setRole] = useState<string>('')
  const [message, setMessage] = useState<string>('')
  const [roles, setRoles] = useState<Map<string, string>>(new Map())
  const [professionalPosition, setProfessionalPosition] = useState<PositionType[]>([])
  const [professionalTypeUser, setProfessionalTypeUser] = useState<ProfessionalTypeUserDTO>(
    emptyProfessionalTypeUserDTO()
  )
  const [professionalType, setProfessionalType] = useState<ProfessionalTypeDTO>(
    emptyProfessionalTypeDTO()
  )
  const [positionTypeUser, setPositionTypeUser] = useState<PositionTypeDTO>(emptyPositionTypeDTO())
  const [professionalQuery, setProfessionalQuery] = useState<ProfessionalQueryDTO>(
    emptyProfessionalQueryDTO()
  )
  const { isLoading, profilePhotoData } = useGetProfilePhoto()

  const { role } = useGlobalContext()

  useEffect(() => {
    // Set Mapped roles
    roleService
      .getAll(
        new Query({
          sort: [{ field: 'name' }],
          pager: { offset: 0, limit: -1 },
        })
      )
      .subscribe((res) => {
        const map = new Map(res.map((item) => [item.id, item.name]))
        setRoles(map)
      })

    // Set Professional Query On/Off
    if (loggedUser) {
      professionalQueryService.getByUserID(loggedUser?.id).subscribe((res) => {
        if (res) {
          setProfessionalQuery(fromModel(res))
        }
      })
    }

    // Professional Type options
    positionTypeService
      .getAll(
        new Query({
          sort: [{ field: 'name' }],
          pager: { offset: 0, limit: -1 },
        })
      )
      .subscribe((res) => {
        setProfessionalPosition(res)
      })

    // Profesional Rol
    professionalTypeService
      .getUserProfessionalTypeByUserID(loggedUser?.id ?? '')
      .subscribe((res) => {
        if (res) {
          setProfessionalTypeUser(res)
        }
      })

    // Professional Type Charge
    positionTypeService.getPositionTypeByUserID(loggedUser?.id ?? '').subscribe((res) => {
      if (res) {
        setPositionTypeUser(res)
      }
    })
  }, [])

  useEffect(() => {
    userService.getByID(loggedUser?.id ?? '').subscribe((res) => {
      if (!res) {
        return
      }
      setUserToEdit(res.toDTO())
    })
  }, [roles])

  useEffect(() => {
    professionalTypeService
      .getByID(professionalTypeUser?.professionalTypeID || '')
      .subscribe((res) => {
        if (res) {
          setProfessionalType(res)
        }
      })
  }, [professionalTypeUser])

  useEffect(() => {
    setMessage('')
  }, [userToEdit])

  function formatDate(dateString: Date): string {
    const date = new Date(dateString)
    let month = '' + (date.getMonth() + 1)
    let day = '' + date.getDate()
    const year = date.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [day, month, year].join('-')
  }

  function calculateAge(dateString: Date): number {
    const birthDate = new Date(dateString)
    const today = new Date()
    let age = today.getFullYear() - birthDate.getFullYear()
    const m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--
    }
    return age
  }

  const goEdit = () => navigate(ROUTE_USER_PROFILE_EDIT)

  const hasRelevantRole = [
    'professionalSMS',
    'managerHealthChild',
    'managerActivePlan',
    'managerEpileptic',
  ].some((role) => loggedUser?.roles?.includes(role))
  const onlyCaseManager = ['managerHealthChild', 'managerActivePlan', 'managerEpileptic'].some(
    (role) => loggedUser?.roles?.includes(role)
  )

  const onlyCaseManagerText = () => {
    if (loggedUser?.roles?.includes('managerActivePlan')) return t('activePlan')
    if (loggedUser?.roles?.includes('managerHealthChild')) return t('healthykid')
    if (loggedUser?.roles?.includes('managerEpileptic')) return t('pediatricEpilepsy')
    return ''
  }

  return (
    <Box className={styles.editorContainer}>
      <Box className={styles.editorForm}>
        <Header
          label={
            loggedUser?.roles?.includes('patient') ? t('patientProfile') : t('professionalProfile')
          }
        />
        <Box className={styles.editorRow}>
          {isLoading && (
            <Skeleton
              style={{ marginRight: '40px', marginLeft: '8px' }}
              variant="circular"
              width={100}
              height={100}
            />
          )}
          {!isLoading && (
            <Box className={styles.editorImageColumn}>
              <Box className={styles.editorImageBG}>
                {profilePhotoData !== '' ? (
                  <>
                    <img
                      style={{
                        height: '110px',
                        width: '110px',
                        background: '#FFF',
                        borderRadius: 100,
                      }}
                      src={`data:image/jpeg;base64,${profilePhotoData}`}
                      alt={'selectedProfilePhoto'}
                    />
                  </>
                ) : (
                  <img src={icoProfile} alt={icoProfile} />
                )}
              </Box>
            </Box>
          )}
          <Box className={styles.editorFields}>
            <Box className={styles.editorFieldBox}>
              <p className={styles.editorFieldLabel}>{t('fullName')}</p>
              <p className={styles.editorTextResponsive}>{userToEdit.name}</p>
            </Box>

            {loggedUser?.roles?.includes('patient') && (
              <>
                <Box className={styles.editorFieldBox}>
                  <p className={styles.editorFieldLabel}>{t('birthdate')}</p>
                  <p className={styles.editorTextResponsive}>{formatDate(userToEdit.birthDate)}</p>
                </Box>
                <Box className={styles.editorFieldBox}>
                  <p className={styles.editorFieldLabel}>{t('age')}</p>
                  <p className={styles.editorTextResponsive}>
                    {calculateAge(userToEdit.birthDate)}
                  </p>
                </Box>

                <Box className={styles.editorFieldBox}>
                  <p className={styles.editorFieldLabel}>{t('dni')}</p>
                  <p className={styles.editorTextResponsive}>{userToEdit.dni}</p>
                </Box>

                <Box className={styles.editorFieldBox}>
                  <p className={styles.editorFieldLabel}>{t('cip')}</p>
                  <p className={styles.editorTextResponsive}> {userToEdit.cip}</p>
                </Box>
              </>
            )}
            <Box className={styles.editorFieldBox}>
              <p className={styles.editorFieldLabel}>{t('email')}</p>
              <p className={styles.editorTextResponsive}>{userToEdit.email}</p>
            </Box>

            <Box className={styles.editorFieldBox}>
              <p className={styles.editorFieldLabel}>{t('phone')}</p>
              <p className={styles.editorTextResponsive}>{userToEdit.phone}</p>
            </Box>

            {loggedUser?.roles?.includes('patient') && (
              <Box className={styles.editorFieldBox}>
                <p className={styles.editorFieldLabel}>{t('role') + '*'}</p>
                <p className={styles.editorTextResponsive}>
                  {t(role && role.length > 1 ? role[1].name : role[0]?.name || '')}
                </p>
              </Box>
            )}

            {hasRelevantRole && (
              <Box className={styles.editorFieldBox}>
                <p className={styles.editorFieldLabel}>{t('role') + '*'}</p>
                <p className={styles.editorTextResponsive}>
                  {role.length && role.find((r) => r.name === 'professionalSMS')
                    ? t(role && role.length > 1 ? role[1].name : role[0]?.name || '')
                    : t('caseManager')}
                </p>
              </Box>
            )}

            {onlyCaseManager && (
              <Box className={styles.editorFieldBox}>
                <p className={styles.editorFieldLabel}>{t('circle') + '*'}</p>
                <p className={styles.editorTextResponsive}>{onlyCaseManagerText()}</p>
              </Box>
            )}

            {role.length && role.find((r) => r.name === 'professionalSMS') && (
              <Box className={styles.editorFieldBox}>
                <p className={styles.editorFieldLabel}>{t('professionalType') + '*'}</p>
                <p className={styles.editorTextResponsive}>
                  {(professionalType && professionalType.name) || ''}
                </p>
              </Box>
            )}

            {loggedUser?.roles?.includes(Roles.Professional) && (
              <>
                <Box className={styles.editorFieldBox}>
                  <p className={styles.editorFieldLabel}>{t('positionType') + '*'}</p>
                  <p className={styles.editorTextResponsive}>
                    {
                      professionalPosition.find((position) => position.id === positionTypeUser.id)
                        ?.name
                    }
                  </p>
                </Box>
                {!loggedUser?.roles?.find((rol) => rol.includes('manager')) && (
                  <Box className={styles.editorCheckbox}>
                    <p className={styles.editorFieldLabel}>{t('acceptChats')}</p>
                    <div className={styles.radioContainer}>
                      {userToEdit.acceptChat ? 'Sí' : 'No'}
                    </div>
                  </Box>
                )}

                <Box className={styles.editorCheckbox}>
                  <p className={styles.editorFieldLabel}>{t('acceptQuerys')}</p>
                  <div className={styles.radioContainer}>
                    {professionalQuery.acceptConsulta !== 0 ? 'Sí' : 'No'}
                  </div>
                </Box>

                <Box className={styles.editorCheckbox}>
                  <p className={styles.editorFieldLabel}>{t('acceptVideoCalls')}</p>
                  <div className={styles.radioContainer}>
                    {userToEdit.acceptVideoCalls ? 'Sí' : 'No'}
                  </div>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
      {message && (
        <Box>
          <Alert severity="success" key="message" id="message">
            {t(message)}
          </Alert>
        </Box>
      )}
      <Box className={styles.buttonContainer}>
        <AppButton
          theme={ButtonTheme.NewPrimary}
          type={'button'}
          label={t('edit')}
          handler={goEdit}
        />
      </Box>
    </Box>
  )
}
