import { useEffect, useMemo, useState } from 'react'
import './App.css'
import { Box, CssBaseline } from '@mui/material'
import { HeaderEsfera, Main } from './pages/layout'
import { dateToDateString } from './common/utils/date'
import { ROUTE_HOME } from './routes/routes-constants'
import Login from 'Login'
import { CSRF_KEY, ILoggedUserService } from './modules/users/services/LoggedUserService'
import { getUserContainer } from './container/user-module'
import {
  IUserService,
  LOGGED_USER_SERVICE_KEY,
  ROLES_SERVICE_KEY,
  USER_SERVICE_KEY,
} from './modules/users'
import { LoggedUser } from './modules/users/models/LoggedUser'
import { IStatusService } from './common/status/StatusService'
import { getAppContainer, STATUS_SERVICE_KEY } from './container/app'
import { ChooseRolLogin } from './ChooseRolLogin'
import { GlobalRoleContext } from './common/utils/GlobalRoleContext'
import { Query } from './common/api/Query'
import { RolesService } from './modules/users/services/RolesServices'
import { Role } from './modules/users/models/Role'
import { User } from './modules/users/models/User'
import { GlobalRenderHeaderContext } from './common/utils/GlobalRenderHeader'
import { BrowserRouter as Router } from 'react-router-dom'
import { ActiveUserContextProvider } from './common/utils/active-user-context/ActiveUserContext'
import { EditorContextProvider } from './common/utils/context/EditorContext'
import { ProfilePhotoContextProvider } from './common/utils/profile-photo-context/ProfilePhotoContext'
import { PatientSetAssistance } from './PatientSetAssistance'
import { CalendarEventsContextProvider } from 'common/utils/calendar-events/CalendarEventsContext'
import useMediaQuery from '@mui/material/useMediaQuery'
import NavbarResponsive from './components/navbar-responsive/navbarResponsive'
import { ConfidentialityTerm } from './features/confidentialityTerm/ConfidentialityTerm'
import { getConfidentialityTermContainer } from './container/confidentialityTerm-module'
import {
  CONFIDENTIALITY_TERM_SERVICE_KEY,
  IConfidentialityTermService,
} from './modules/confidentialityTerm'
import { NewCircleContextProvider } from 'common/utils/circle-config-context/CircleConfigContext'

import { SelectedMenuProvider } from 'common/utils/menu-selected-context/MenuSelectedContext'

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const statusService = getAppContainer().get<IStatusService>(STATUS_SERVICE_KEY)
const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)
const roleContainer = getUserContainer()
const roleService = roleContainer.get<RolesService>(ROLES_SERVICE_KEY)
const confTermService = getConfidentialityTermContainer().get<IConfidentialityTermService>(
  CONFIDENTIALITY_TERM_SERVICE_KEY
)

function App() {
  const loggedUser = loggedUserService.get()
  const [user, setUser] = useState<User | undefined>()
  const [lu, setLU] = useState<LoggedUser | undefined>(loggedUser)
  const [menuSelected, setMenuSelected] = useState<string>(ROUTE_HOME)
  const [chooseRole, setChooseRole] = useState<boolean>(false)
  const [role, setRole] = useState<Role[]>([])
  const roleValue = useMemo(() => ({ role, setRole }), [role])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [viewChooseRole, setViewChooseRole] = useState<boolean>(true)
  const [render, setRender] = useState<boolean>(false)
  const renderValue = useMemo(() => ({ render, setRender }), [render])
  const [redirectToSesamo, setRedirectToSesamo] = useState<boolean>(false)
  const isSmallScreen = useMediaQuery('(max-width: 1000px)')
  const [confTermAccepted, setConfTermAccepted] = useState<boolean>(false)
  const [showVerifyUser, setShowVerifyUser] = useState<boolean>(false)

  // Realiza la entrada desde el correo que pide al paciente confirmar la assistencia
  PatientSetAssistance()

  // Set monitorization
  // useInteraccionPaginaWeb()

  useEffect(() => {
    if (!loggedUser) return
    userService.getByID(loggedUser?.id).subscribe((u) => {
      setUser(u)
    })
  }, [lu])

  useEffect(() => {
    sessionStorage.removeItem(CSRF_KEY)

    if (lu) {
      loggedUserService.getCSRFToken().subscribe((res) => {
        if (res) {
          sessionStorage.setItem(CSRF_KEY, res)
        }
      })
    }
    loggedUserService.getObservable().subscribe((lu) => {
      if (!lu) {
        setViewChooseRole(true)
      }
      setLU(lu)
    })
    statusService.statusObservable().subscribe((s) => {})
  }, [lu])

  useEffect(() => {
    if (!loggedUser) return

    loggedUserService.getCSRFToken().subscribe((res) => {
      if (res) {
        sessionStorage.setItem(CSRF_KEY, res)
        userService.getByID(loggedUser?.id).subscribe((u) => {
          setUser(u)
        })
      }
    })
  }, [lu])

  useEffect(() => {
    if (!loggedUser) return
    const role = roleService
      .getAll(
        new Query({
          sort: [{ field: 'name' }],
          pager: { offset: 0, limit: -1 },
        })
      )
      .subscribe((resRole) => {
        const filterRole: Role[] = []
        user?.roles.forEach((r) => {
          const filter = resRole.filter((item) => item.id === r)
          filterRole.push(...filter)
        })
        setRole(filterRole)
      })
    return () => {
      role.unsubscribe()
      setRole([])
    }
  }, [user, lu])

  useEffect(() => {
    if (role.length < 1) return
    const permissions: string[] = []
    role.forEach((r) => {
      r.permissions.forEach((p) => {
        if (!permissions.includes(p)) {
          permissions.push(p)
        }
      })
    })

    loggedUserService.setRoles(
      role.map((r) => r.name),
      permissions
    )
    setIsLoading(false)
  }, [user, role])

  useEffect(() => {
    if (!sessionStorage.getItem(CSRF_KEY)) return
    if (!loggedUser) return
    setShowVerifyUser(true)
    if (loggedUser && loggedUser?.id) {
      confTermService.getByID(loggedUser.id).subscribe((res) => {
        if (!res) {
          setShowVerifyUser(false)
        }
        if (res) {
          setConfTermAccepted(res.accepted)
        }
      })
    }
  }, [lu, user, role])

  function handleSetMenuSelected(menuSelected: string) {
    setMenuSelected(menuSelected)
  }

  const handlerChooseRole = (choose: boolean) => {
    setChooseRole(choose)
  }

  const handlerViewChooseRole = () => {
    setViewChooseRole(false)
  }

  const handleRedirectToSesamo = () => {
    setRedirectToSesamo(true)
  }

  const handlerAcceptedTerm = (value: boolean) => {
    setIsLoading(true)
    setConfTermAccepted(value)
  }

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    <EditorContextProvider>
      <ActiveUserContextProvider>
        <NewCircleContextProvider>
          <ProfilePhotoContextProvider>
            <CalendarEventsContextProvider>
              <GlobalRoleContext.Provider value={roleValue}>
                <GlobalRenderHeaderContext.Provider value={renderValue}>
                  <CssBaseline />
                  {!lu && !user && !redirectToSesamo && (
                    <Router>
                      <Login handlerViewChooseRole={handlerViewChooseRole} />
                    </Router>
                  )}
                  {lu && user && !chooseRole && !viewChooseRole && (
                    <ChooseRolLogin isChooseRole={handlerChooseRole} />
                  )}
                  {lu &&
                    user &&
                    !(!chooseRole && !viewChooseRole) &&
                    !confTermAccepted &&
                    !showVerifyUser && (
                      <ConfidentialityTerm handlerAcceptedTerm={handlerAcceptedTerm} />
                    )}
                  {lu &&
                    user &&
                    !(!chooseRole && !viewChooseRole) &&
                    !(!confTermAccepted && !showVerifyUser) &&
                    !isLoading && (
                      <Router>
                        <SelectedMenuProvider handleSetMenuSelected={handleSetMenuSelected}>
                          <HeaderEsfera
                            login={user.username}
                            username={user.name}
                            gender={user.gender}
                            dateOfBirth={
                              user && user.birthDate
                                ? dateToDateString(new Date(user.birthDate))
                                : ''
                            }
                            dni={user.dni}
                            cip={user.cip}
                            setSelectedMenu={handleSetMenuSelected}
                            isChooseRole={handlerChooseRole}
                            selectedMenu={menuSelected}
                            handleRedirectToSesamo={handleRedirectToSesamo}
                          />
                          <Box display="flex" flexBasis="100%" overflow="hidden">
                            <Main selectedMenu={menuSelected} />
                          </Box>
                          {isSmallScreen && <NavbarResponsive />}
                        </SelectedMenuProvider>
                      </Router>
                    )}
                </GlobalRenderHeaderContext.Provider>
              </GlobalRoleContext.Provider>
            </CalendarEventsContextProvider>
          </ProfilePhotoContextProvider>
        </NewCircleContextProvider>
      </ActiveUserContextProvider>
    </EditorContextProvider>
  )
}

export default App
