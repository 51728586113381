export class Container {
  private readonly _items: Map<symbol, ContainerItem>
  private readonly _parent: Container | undefined
  private readonly _config: any

  constructor(items: Map<symbol, ContainerItem>, parent: Container | undefined, config: any) {
    this._parent = parent
    this._config = config

    this._items = items
    this.init()
  }

  get<T extends IInit>(key: symbol): T {
    const v1 = this._items.get(key)
    if (v1) {
      return v1.getInstance<T>(this)
    }

    const v2 = this._parent?.get<T>(key)
    if (v2) {
      return v2
    }

    throw new Error(`Bad config. There is no a container item with key: ${key.description}`)
  }

  get config(): any {
    return { ...this._config }
  }

  init() {
    this._items.forEach((c) => (c.scope === 'singleton' ? c.getInstance<any>(this).init(this) : ''))
  }
}

export interface IInit {
  init: (c: Container) => void
}

type ContainerItemProps = {
  scope?: 'singleton' | 'prototype'
  instance?: IInit
  creatingFunc?: () => any
}

export class ContainerItem {
  private readonly _type: 'singleton' | 'prototype' // e.g. https://docs.spring.io/spring-framework/docs/3.0.0.M3/reference/html/ch04s04.html?
  private readonly _instance?: IInit
  private readonly _creatingFunc?: () => IInit

  constructor(p: ContainerItemProps) {
    if (p.scope === 'singleton' && !p.instance) {
      throw new Error(
        `Bad config. For the Singleton Scope you have to pass an Instance. Params: ${p}`
      )
    }
    if (p.scope === 'prototype' && !p.creatingFunc) {
      throw new Error(
        `Bad config. For the Prototype Scope you have to pass a Creating Function. Params: ${p}`
      )
    }

    this._type = p.scope ?? 'singleton'
    this._instance = p.instance
    this._creatingFunc = p.creatingFunc
  }

  getInstance<T extends IInit>(c: Container): T {
    switch (this._type) {
      case 'singleton':
        return this._instance as T
      case 'prototype':
        if (this._creatingFunc) {
          const instance = this._creatingFunc()
          instance.init(c)
          return instance as unknown as T
        }
        throw new Error(
          `Bad config. For the Prototype Scope you have to pass a Creating Function. ${this}`
        )
    }
  }

  get scope(): 'singleton' | 'prototype' {
    return this._type
  }
}
